<template>
  <div
    :class="[
      'form-field',
      'form-field_type_range-date !w-full',
      {
        'form-field_label-position_top': labelPositionIsTop,
        'form-field_state_focus': fieldIsActive,
        'form-field_state_invalid': fieldIsInvalid,
        'form-field_force-mode_single': singleMode,
      },
    ]"
  >
    <div class="form-field__inner datepicker-trigger">
      <div class="form-field__input-wrapper">
        <!--                <label :for="fieldId" class="form-field__label">{{ label }}</label>-->
        <input
          ref="fieldInput"
          :id="fieldId"
          :value="formatDates(dates)"
          class="form-field__input pad-calculate !pl-2 placeholder:!text-[#A5ABB0] max-md:!pl-5"
          type="text"
          :placeholder="label"
          readonly
        />
        <!--                <div class="form-field__icon"/>-->
        <div class="form-field__message form-field__message_type_error">
          {{ errorMessage }}
        </div>
      </div>
      <range-date-picker
        :mode="mode"
        :single-mode="singleMode"
        :trigger-element-id="fieldId"
        :date-one="dates.one"
        :date-two="dates.two"
        :min-date="minDate"
        :end-date="maxDate"
        @mode-changed="onChangeMode"
        @date-one-selected="selectDateOne"
        @date-two-selected="selectDateTwo"
        @date-selected="onSelecteDate"
        @opened="onOpenDatePicker"
        @closed="onCloseDatePicker"
        class="form-field__datepicker datepicker_theme_form-date-field mt-2"
      />
    </div>
  </div>
</template>

<script>
import { randomString, formatDate } from '@/utils';
import RangeDatePicker from '@/components/Form/Fields/RangeDatePicker.vue';

export default {
  name: 'DateField',
  components: { RangeDatePicker },
  emits: ['onFocus', 'outFocus'],
  props: {
    label: {
      type: String,
      default: null,
    },
    minDate: {
      type: [String, Date],
      default: '',
    },
    maxDate: {
      type: [String, Date],
      default: '',
    },
    opts: {
      type: Object,
      default: () => ({}),
    },
    mode: {
      type: String,
      default: 'range',
    },
    singleMode: {
      type: Boolean,
      default: false,
    },
    valueExternal: {
      type: [Object, null],
      default: null,
    },
    valueDefault: {
      type: [Object, null],
      default: null,
    },
    autofocus: {
      type: [Boolean, String],
      default: false,
    },
    validation: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    fieldId: randomString(5),
    fieldIsActive: false,
    dates: {
      one: '',
      two: '',
    },
  }),

  computed: {
    labelPositionIsTop() {
      return (this.fieldIsActive && this.dateIsSelected()) || this.dateIsSelected();
    },
    fieldIsInvalid() {
      return this.validation && !this.fieldIsActive && !this.datesIsSelected();
    },
  },

  watch: {
    autofocus(value) {
      if (!value) return;

      this.$refs.fieldInput.focus();
      this.$refs.fieldInput.click();

      this.$emit('focused');
    },
    valueExternal(value) {
      this.dates = value;
    },
    fieldIsActive(newValue) {
      if (newValue) {
        this.$emit('onFocus', this.name);
      } else {
        this.$emit('outFocus', this.name);
      }
    },
  },

  created() {
    if (this.valueDefault !== null) {
      this.dates = this.valueDefault;
    }

    if (this.singleMode) {
      this.$emit('mode-changed', 'single');
    }
  },

  methods: {
    onChangeMode(mode) {
      this.$emit('mode-changed', mode);
    },
    onOpenDatePicker() {
      this.fieldIsActive = true;

      this.$emit('opened');
    },
    onCloseDatePicker() {
      this.fieldIsActive = false;

      this.$emit('closed');
    },
    onSelecteDate() {
      this.$emit('date-selected');
    },
    selectDateOne(value) {
      this.dates.one = value;
    },
    selectDateTwo(value) {
      this.dates.two = value;
    },
    dateIsSelected() {
      return (
        (this.mode === 'single' && this.dates.one !== '') ||
        (this.mode === 'range' && (this.dates.one !== '' || this.dates.two !== ''))
      );
    },
    datesIsSelected() {
      return (
        (this.mode === 'single' && this.dates.one !== '') ||
        (this.mode === 'range' && this.dates.one !== '' && this.dates.two !== '')
      );
    },
    formatDates(dates) {
      let formattedDates = '';

      if (dates.one) {
        formattedDates = formatDate(dates.one, this.opts.format);
      }

      if (dates.two) {
        formattedDates += ' - ' + formatDate(dates.two, this.opts.format);
      }

      this.$emit('input', dates);

      return formattedDates;
    },
  },
};
</script>
<style scoped>
.pad-calculate {
  @media only screen and (min-width: 1280px) {
    padding-right: calc(100% - 72px) !important;
  }
  @media only screen and (max-width: 1280px) and (min-width: 1024px) {
    padding-right: calc(100% - 66px) !important;
  }
  @media only screen and (max-width: 1024px) and (min-width: 768px) {
    padding-right: calc(100% - 64px) !important;
  }
  @media only screen and (max-width: 768px) {
    padding-right: calc(100% - 78px) !important;
  }
}
</style>
