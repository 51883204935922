import ApiUtil from '@/utils/api';

export default {
  state: {
    blog: [],
  },

  getters: {
    blog: (state) => state.blog,
  },

  actions: {
    getBlog: ({ commit }, limit) => {
      return new Promise((resolve, reject) => {
        ApiUtil.get(`https://alltrip.uz/blog/wp-json/api/v1/posts?limit=${limit}`)
          .then((blog) => {
            if (blog.status === 200 && blog.data !== undefined) {
              commit('setBlog', blog.data);
              resolve(blog.data);
            } else {
              reject([]);
            }
          })
          .catch((error) => reject(error));
      });
    },
    resetBlog: ({ commit }) => commit('resetBlog'),
  },
  mutations: {
    setBlog: (state, blog) => (state.blog = blog),
    resetBlog: (state) => (state.blog = []),
  },
};
