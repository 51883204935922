import ApiUtil from '@/utils/api';

export default {
  getProviders() {
    return new Promise((resolve, reject) => {
      ApiUtil.get('/payments/providers')
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => reject(error));
    });
  },

  decodeHash(hash) {
    return new Promise((resolve, reject) => {
      ApiUtil.get('/payments/hash', { params: { hash } })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => reject(error));
    });
  },

  getPayUrl({ provider, orderId }) {
    return new Promise((resolve, reject) => {
      ApiUtil.post(`/payments/${provider}`, { order_id: orderId })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => reject(error));
    });
  },

  getCommission({ provider, amount }) {
    return new Promise((resolve, reject) => {
      ApiUtil.post(`/payments/${provider}/commission`, { amount })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => reject(error));
    });
  },
};
