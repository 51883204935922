<template>
  <div>
    <UserAuthPopup v-if="userAuthPopup.status" />
    <UserPasswordRecoveryPopup v-if="userPasswordRecoveryPopup.status" />
    <UserPasswordResetPopup v-if="userPasswordResetPopup.status" />
    <TicketDetailsPopup v-if="ticketDetailsPopup.status" />
    <TicketRulesPopup v-if="ticketRulesPopup.status" />
    <TicketTariffsPopup v-if="ticketTariffsPopup.status" />
    <TicketNotAvailablePopup v-if="ticketNotAvailablePopup.status" />
    <OrderNotAvailablePopup v-if="orderNotAvailablePopup.status" />
    <OrderStepsPopup v-if="orderStepsPopup.status" />
    <OrderPaymentPopup v-if="orderPaymentPopup.status" />
    <OrderPaymentConfirmPopup v-if="orderPaymentConfirmPopup.status" />
    <OrderVoidPopup v-if="orderVoidPopup.status" />
    <OrderOfertaPopup v-if="orderOfertaPopup.status" />
    <OrderPersonalDataPopup v-if="orderPersonalDataPopup.status" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SitePopups',

  components: {
    UserAuthPopup: () => import('@/components/UserAuth/AuthPopup'),
    UserPasswordRecoveryPopup: () => import('@/components/UserAuth/PasswordRecoveryPopup'),
    UserPasswordResetPopup: () => import('@/components/UserAuth/PasswordResetPopup'),
    TicketDetailsPopup: () => import('@/components/Tickets/TicketPopups/DetailsPopup'),
    TicketRulesPopup: () => import('@/components/Tickets/TicketPopups/RulesPopup'),
    TicketTariffsPopup: () => import('@/components/Tickets/TicketPopups/TariffsPopup'),
    TicketNotAvailablePopup: () => import('@/components/Tickets/TicketPopups/NotAvailablePopup'),
    OrderStepsPopup: () => import('@/components/Order/StepsPopup'),
    OrderPaymentPopup: () => import('@/components/UserCabinet/Order/PaymentPopup'),
    OrderPaymentConfirmPopup: () => import('@/components/Order/PaymentConfirmPopup'),
    OrderNotAvailablePopup: () => import('@/components/Order/NotAvailablePopup'),
    OrderVoidPopup: () => import('@/components/UserCabinet/Order/VoidPopup'),
    OrderOfertaPopup: () => import('@/components/Order/OfertaPopup'),
    OrderPersonalDataPopup: () => import('@/components/Order/PersonalDataPopup'),
  },

  computed: {
    ...mapGetters([
      'userAuthPopup',
      'userPasswordRecoveryPopup',
      'userPasswordResetPopup',
      'ticketDetailsPopup',
      'ticketRulesPopup',
      'ticketTariffsPopup',
      'ticketNotAvailablePopup',
      'orderStatus',
      'orderError',
      'orderStepsPopup',
      'orderCurrentStep',
      'orderPaymentPopup',
      'orderPaymentConfirmPopup',
      'orderNotAvailablePopup',
      'orderVoidPopup',
      'orderOfertaPopup',
      'orderPersonalDataPopup',
    ]),
  },
};
</script>
