import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import UserUtil from '@/utils/user';
import LanguageUtil from '@/utils/language';
import { parseTicketsSearchId } from '@/utils/tickets/search-id';
import Home from '@/pages/Home';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: `/${LanguageUtil.getCurrent()}`,
    },
    {
      path: '/:lang',
      component: {
        render: (c) => c('router-view'),
      },
      children: [
        {
          path: '',
          name: 'home',
          component: Home,
          meta: {
            layoutTheme: 6,
          },
        },
        {
          path: 'about',
          name: 'about',
          component: () => import('@/newPages/AboutNew'),
          meta: {
            requiresGoogleRecaptcha: true,
          },
        },
        {
          path: 'travel-agent',
          name: 'travelAgent',
          component: () => import('@/newPages/TravelAgentNew'),
          meta: {
            requiresGoogleRecaptcha: true,
          },
        },
        {
          path: 'corporate',
          name: 'corporate',
          component: () => import('@/newPages/CorporateNew'),
          meta: {
            requiresGoogleRecaptcha: true,
          },
        },
        {
          path: 'advertising',
          name: 'advertising',
          component: () => import('@/pages/Advertising'),
        },
        {
          path: 'help',
          name: 'help',
          component: () => import('@/newPages/HelpNew'),
          meta: {
            requiresGoogleRecaptcha: true,
          },
        },
        {
          path: 'rules',
          name: 'rules',
          component: () => import('@/newPages/RulesNew'),
          meta: {
            requiresGoogleRecaptcha: true,
          },
        },
        {
          path: 'contacts',
          name: 'contacts',
          component: () => import('@/newPages/ContactsNew'),
          meta: {
            requiresGoogleRecaptcha: true,
          },
        },
        {
          path: 'our-app',
          name: 'ourApp',
          component: () => import('@/newPages/MobileApp.vue'),
          meta: {
            requiresGoogleRecaptcha: true,
          },
        },
        {
          path: 'business-profile',
          name: 'businessProfile',
          component: () => import('@/newPages/BusinessProfileNew'),
        },
        {
          path: 'privacy-policy',
          name: 'privacyPolicy',
          component: () => import('@/pages/PrivacyPolicy'),
          meta: {
            requiresGoogleRecaptcha: true,
          },
        },
        {
          path: 'tourism',
          name: 'tourism',
          component: () => import('@/pages/Tourism'),
          meta: {
            requiresGoogleRecaptcha: true,
            layoutTheme: 6,
          },
        },
        {
          path: 'umrah',
          name: 'umrah',
          component: () => import('@/pages/Umrah'),
        },
        {
          path: 'contact-center',
          name: 'contactCenter',
          component: () => import('@/pages/ContactCenter'),
          meta: {
            requiresGoogleRecaptcha: true,
          },
        },
        {
          path: 'charters',
          name: 'charters',
          component: () => import('@/pages/Charters'),
        },
        {
          path: 'insurance',
          component: () => import('@/newPages/Insurance/index.vue'),
          children: [
            {
              path: '',
              name: 'insurance',
              component: () => import('@/newPages/Insurance/InsuranceForm.vue'),
            },
            {
              path: 'payment/:id',
              name: 'insurancePayment',
              component: () => import('@/newPages/Insurance/InsurancePayment.vue'),
            },
            {
              path: ':details',
              name: 'insuranceDetails',
              component: () => import('@/newPages/Insurance/InsuranceDetails.vue'),
            },
          ],
        },
        {
          path: 'business-aviation',
          name: 'businessAviation',
          component: () => import('@/pages/BusinessAviation'),
          meta: {
            requiresGoogleRecaptcha: true,
          },
        },
        {
          path: 'charter-flights',
          name: 'charterFlights',
          component: {
            render: (c) => c('router-view'),
          },
          redirect: {
            name: 'charterFlightKievTashkentKiev',
          },
          children: [
            {
              path: 'kiev-tashkent-kiev',
              name: 'charterFlightKievTashkentKiev',
              component: () => import('@/pages/CharterFlights/KievTashkentKiev'),
            },
            {
              path: 'moskva-tashkent',
              name: 'charterFlightMoskvaTashkent',
              component: () => import('@/pages/CharterFlights/MoskvaTashkent'),
            },
            {
              path: 'moskva-samarkand',
              name: 'charterFlightMoskvaSamarkand',
              component: () => import('@/pages/CharterFlights/MoskvaSamarkand'),
            },
            {
              path: 'moskva-namangan',
              name: 'charterFlightMoskvaNamangan',
              component: () => import('@/pages/CharterFlights/MoskvaNamangan'),
            },
            {
              path: 'moskva-navoi',
              name: 'charterFlightMoskvaNavoi',
              component: () => import('@/pages/CharterFlights/MoskvaNavoi'),
            },
            {
              path: '*',
              redirect: {
                name: 'charterFlightKievTashkentKiev',
              },
            },
          ],
          meta: {
            requiresGoogleRecaptcha: true,
          },
        },
        {
          path: 'search-no-results',
          name: 'searchNoResults',
          component: () => import('@/pages/TicketsNotFound'),
          meta: {
            layoutTheme: 'none',
          },
        },
        {
          path: 'search/:id',
          name: 'search',
          component: () => import('@/pages/Tickets'),
          meta: {
            layoutTheme: 5,
          },
          beforeEnter: (to, from, next) => {
            if (to.params.id === undefined) {
              next({
                name: 'notFound',
                params: { 0: to.path },
              });
            } else if (parseTicketsSearchId(to.params.id) === null) {
              next({
                name: 'notFound',
                params: { 0: to.path },
              });
            } else {
              next();
            }
          },
        },
        {
          path: 'order/:id',
          name: 'order',
          component: () => import('@/pages/Order'),
        },
        {
          path: 'order-result',
          name: 'orderResult',
          component: () => import('@/pages/OrderResult'),
          meta: {
            layoutTheme: 4,
          },
        },
        {
          path: 'cabinet',
          name: 'cabinet',
          component: () => import('@/pages/Cabinet'),
          meta: {
            layoutTheme: 4,
            requiresAuth: true,
          },
          children: [
            {
              path: 'profile',
              name: 'cabinetProfile',
              component: () => import('@/pages/CabinetProfile'),
              meta: {
                layoutTheme: 4,
              },
            },
            {
              path: 'orders',
              name: 'cabinetOrders',
              component: () => import('@/pages/CabinetOrders'),
              meta: {
                layoutTheme: 4,
              },
            },
            {
              path: 'orders/:id',
              name: 'cabinetOrder',
              component: () => import('@/pages/CabinetOrder'),
              meta: {
                layoutTheme: 4,
              },
            },
            {
              path: 'notebook',
              name: 'cabinetNotebook',
              component: () => import('@/pages/CabinetNotebook'),
              meta: {
                layoutTheme: 4,
              },
            },
            {
              path: 'insurance-orders',
              name: 'cabinetInsuranceOrders',
              component: () => import('@/pages/CabinetInsurance/Orders'),
              meta: {
                layoutTheme: 4,
              },
            },
            {
              path: '*',
              redirect: {
                name: 'cabinetProfile',
              },
            },
          ],
        },
        {
          path: 'password',
          name: 'password',
          component: () => import('@/pages/Home'),
        },
        {
          path: 'vacancies',
          name: 'vacancies',
          component: () => import('@/newPages/VacanciesNew'),
          meta: {
            layoutTheme: 5,
          },
        },
        {
          path: 'vacancies/:id',
          name: 'vacancy',
          component: () => import('@/pages/Vacancy'),
        },
        {
          path: 'order-ticketing',
          name: 'orderTicketing',
          component: () => import('@/pages/OrderTicketing'),
          meta: {
            layoutTheme: 4,
          },
        },
        {
          path: 'business-alltrip',
          name: 'Business',
          component: () => import('@/pages/BusinessAllTrip'),
          meta: {
            layoutTheme: 6,
          },
        },
        {
          path: 'under-construction',
          name: 'underConstruction',
          component: () => import('@/newPages/UnderConstruction'),
        },
        {
          path: '404',
          name: 'notFound',
          component: () => import('@/newPages/NotFoundNew'),
          meta: {
            layoutTheme: '404',
            title: '404 - AllTrip',
          },
        },
        {
          path: '*',
          redirect: {
            name: 'notFound',
          },
        },
      ],
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // Auth
  if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.userIsAuthenticated) {
    return next({ path: '/' });
  }

  // Recovery Password
  if (to.query.action === 'recovery' && to.query.token) {
    if (!store.getters.userIsAuthenticated) {
      UserUtil.showPasswordRecoveryPopup(to.query.token);
    }

    const lang = LanguageUtil.isAllowed(to.params.lang) ? to.params.lang : LanguageUtil.getCurrent();

    return next({ path: `/${lang}`, query: {} });
  }

  // Language
  const lang = to.params.lang;
  const currentLang = LanguageUtil.getCurrent();

  if (lang && lang !== currentLang) {
    if (LanguageUtil.isAllowed(lang)) {
      store.dispatch('setCurrentLanguage', lang);
    } else {
      let path = `/${currentLang}`;

      if (lang.length > 2) {
        path += `${to.redirectedFrom !== undefined ? to.redirectedFrom : to.fullPath}`;
      }

      return next(path);
    }
  }

  next();
});

router.afterEach((to) => {
  // Google Recaptcha
  if (to.meta && to.meta.requiresGoogleRecaptcha) {
    document.querySelector('html').classList.add('g-show-grecaptcha-badge');
  } else {
    document.querySelector('html').classList.remove('g-show-grecaptcha-badge');
  }
});

export default router;
