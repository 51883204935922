<template>
  <div
    v-click-outside="hideDropdown"
    :class="[
      'language-select flex',
      {
        'language-select_dropdown-visibility_visible': dropdownIsVisible,
      },
    ]"
  >
    <button
      :class="['language-select__button my-auto inline-flex items-center', `language-select__button_theme_${2}`]"
      type="button"
      @click="toggleDropdown"
    >
      <span class="text-white" v-html="svgIcons.get('burger')" />
    </button>
    <div class="language-select__dropdown">
      <ul class="language-select__list">
        <li class="pl-2">
          <router-link
            class="language-select__list-item list-item_theme_language-select list-item"
            :to="{ name: 'tourism', params: { lang: $i18n.locale } }"
          >
            {{ getLocaleMsg('tourism') }}
          </router-link>
        </li>
        <li class="pl-2">
          <a
            class="language-select__list-item list-item_theme_language-select list-item"
            href="https://realavia.alltrip.uz"
          >
            {{ getLocaleMsg('businessAviation') }}
          </a>
        </li>
        <li class="pl-2">
          <router-link
            class="language-select__list-item list-item_theme_language-select list-item flex-col flex-wrap"
            :to="{ name: 'umrah', params: { lang: $i18n.locale } }"
            style="display: inline-flex"
          >
            <span>{{ getLocaleMsg('umrah') }}</span>
          </router-link>
        </li>
        <li class="pl-2">
          <router-link
            class="language-select__list-item list-item_theme_language-select list-item flex-col flex-wrap"
            :to="{ name: 'help', params: { lang: $i18n.locale } }"
            style="display: inline-flex"
          >
            <span>{{ getLocaleMsg('help') }}</span>
          </router-link>
        </li>
        <li class="pl-2">
          <router-link
            class="language-select__list-item list-item_theme_language-select list-item flex-col flex-wrap"
            :to="{ name: 'rules', params: { lang: $i18n.locale } }"
            style="display: inline-flex"
          >
            <span>{{ getLocaleMsg('rules') }}</span>
          </router-link>
        </li>
        <li class="pl-2">
          <router-link
            class="language-select__list-item list-item_theme_language-select list-item flex-col flex-wrap"
            :to="{ name: 'privacyPolicy', params: { lang: $i18n.locale } }"
            style="display: inline-flex"
          >
            <span>{{ getLocaleMsg('privacyPolicy') }}</span>
          </router-link>
        </li>
        <li class="pl-2">
          <router-link
            class="language-select__list-item list-item_theme_language-select list-item flex-col flex-wrap"
            :to="{ name: 'tourism', params: { lang: $i18n.locale } }"
            style="display: inline-flex"
          >
            <span>{{ getLocaleMsg('tourism') }}</span>
          </router-link>
        </li>
        <li class="pl-2">
          <router-link
            class="language-select__list-item list-item_theme_language-select list-item flex-col flex-wrap"
            :to="{ name: 'contactCenter', params: { lang: $i18n.locale } }"
            style="display: inline-flex"
          >
            <span>{{ getLocaleMsg('contactCenter') }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LanguageSelect',

  data: () => ({
    dropdownIsVisible: false,
  }),

  computed: {
    ...mapGetters(['layoutTheme']),
  },

  methods: {
    toggleDropdown() {
      this.dropdownIsVisible = !this.dropdownIsVisible;
    },
    hideDropdown() {
      this.dropdownIsVisible = false;
    },
    getLocaleMsg(key) {
      return this.$getLocaleMsg(`components.SiteFooter.nav.user.links.${key}`);
    },
  },
};
</script>
