var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "layout__footer",
        _vm.layoutTheme === 2 || _vm.layoutTheme === 6
          ? "footer"
          : _vm.layoutTheme === 6 || _vm.layoutTheme === 7
          ? "footer-color px-[15px] pt-[59px] sm:px-[50px] md:px-[100px]"
          : "footer",
      ],
    },
    [
      _c("div", { staticClass: "grid-container" }, [
        _c("div", { staticClass: "footer__main" }, [
          _c(
            "div",
            { staticClass: "footer__row max-sm:!grid max-sm:!grid-cols-2" },
            [
              _vm._l(_vm.nav, function (navItem, navItemKey) {
                return _c(
                  "div",
                  {
                    key: navItemKey,
                    staticClass: "footer__col footer__col_type_nav",
                  },
                  [
                    _c("div", { staticClass: "footer-nav" }, [
                      _c("div", { staticClass: "footer-nav__title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.getLocaleMsg(`nav.${navItemKey}.title`)
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "footer-nav__list" },
                        _vm._l(navItem, function (linkName) {
                          return _c(
                            "li",
                            { key: linkName, staticClass: "footer-nav__item" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "footer-nav__link",
                                  attrs: {
                                    to: {
                                      name: linkName,
                                      params: { lang: _vm.$i18n.locale },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.getLocaleMsg(
                                          `nav.${navItemKey}.links.${linkName}`
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "footer__col footer__col_type_contacts" },
                [
                  _c("div", { staticClass: "footer-contacts" }, [
                    _c("div", { staticClass: "footer-contacts__title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.getLocaleMsg("contacts.title")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticClass: "footer-contacts__list" },
                      [
                        _vm._l(_vm.contacts, function (contact, key) {
                          return _c(
                            "li",
                            {
                              key: key,
                              class: [
                                "footer-contacts__contact",
                                "footer-contact",
                                "footer-contact_type_contact",
                                "underline",
                              ],
                            },
                            [
                              _c("span", {
                                staticClass: "footer-contact__icon",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.svgIcons.get(contact.icon)
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "footer-contact__link",
                                  attrs: { href: contact.url },
                                },
                                [_vm._v(_vm._s(contact.title))]
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            staticClass:
                              "footer-contacts__contact footer-contact footer-contact_type_link",
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "footer-contact__link",
                                attrs: {
                                  to: {
                                    name: "contacts",
                                    params: { lang: _vm.$i18n.locale },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.getLocaleMsg("contacts.offices")
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "footer-contact__icon",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.svgIcons.get("arrow-back")
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            staticClass:
                              "footer-contacts__contact footer-contact footer-contact_type_link",
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "footer-contact__link",
                                attrs: {
                                  to: {
                                    name: "businessProfile",
                                    params: { lang: _vm.$i18n.locale },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.getLocaleMsg("contacts.business")
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "footer-contact__icon",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.svgIcons.get("arrow-back")
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "footer__col footer__col_type_nav" }, [
                _c("div", { staticClass: "footer-nav" }, [
                  _c("div", { staticClass: "footer-nav__title" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.getLocaleMsg("details.title")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "footer-nav__list" }, [
                    _c("li", { staticClass: "footer-nav__item" }, [
                      _c("span", { staticClass: "text-[#36454F]" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.getLocaleMsg("details.paymentMethods")) +
                            "\n                "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "footer-nav__item" }, [
                      _c(
                        "ul",
                        { staticClass: "flex" },
                        _vm._l(
                          _vm.paymentMethods,
                          function (paymentMethod, key) {
                            return _c(
                              "li",
                              {
                                key: key,
                                staticClass: "footer-payment-methods__item",
                              },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "lazy",
                                      rawName: "v-lazy",
                                      value: paymentMethod.img.src,
                                      expression: "paymentMethod.img.src",
                                    },
                                  ],
                                  class: [
                                    "footer-payment-methods__img",
                                    `footer-payment-methods__img_type_${paymentMethod.type}`,
                                  ],
                                  attrs: {
                                    width: paymentMethod.img.width,
                                    alt: paymentMethod.title,
                                  },
                                }),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "footer-nav__item" }, [
                      _c("span", { staticClass: "text-[#36454F]" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.getLocaleMsg("details.socialNetworks")) +
                            "\n                "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "footer-nav__item" }, [
                      _c(
                        "ul",
                        { staticClass: "flex" },
                        _vm._l(
                          _vm.socialNetworks,
                          function (socialNetwork, key) {
                            return _c(
                              "li",
                              {
                                key: key,
                                staticClass: "footer-social-networks__item",
                              },
                              [
                                _c("a", {
                                  staticClass: "footer-social-networks__link",
                                  attrs: {
                                    href: socialNetwork.url,
                                    target: "_blank",
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.svgIcons.get(socialNetwork.icon, {
                                        className:
                                          "footer-social-networks__icon",
                                      })
                                    ),
                                  },
                                }),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "footer__secondary" }, [
          _c("div", { staticClass: "footer__iata" }, [
            _c("a", {
              staticClass: "footer__iata-link",
              attrs: {
                href: "/static/files/iata_certificate.pdf",
                title: "IATA - International Air Transport Association",
                target: "_blank",
              },
              domProps: { innerHTML: _vm._s(_vm.svgIcons.get("iata")) },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "footer__copyright" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.getLocaleMsg("copyright", {
                    values: { year: _vm.getCurrentYear() },
                  })
                ) +
                "\n      "
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }