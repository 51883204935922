import ApiUtil from '@/utils/api';

export default {
  actions: {
    async getInsuranceCountries() {
      try {
        const res = await ApiUtil.get('/insurance/inson/references/abroad-countries');

        if (res.status === 200 && res.data) {
          return res.data.data;
        }
      } catch (err) {
        console.error(err);
      }
    },

    async getInsurancePrograms(_, ids) {
      try {
        const res = await ApiUtil.post('/insurance/inson/references/abroad-programs', ids);

        if (res.status === 200 && res.data) {
          return res.data.data;
        }
      } catch (err) {
        console.error(err);
      }
    },

    async getInsuranceActivities() {
      try {
        const res = await ApiUtil.get('/insurance/inson/references/abroad-activities');

        if (res.status === 200 && res.data) {
          return res.data.data;
        }
      } catch (err) {
        console.error(err);
      }
    },

    async getInsuranceGroups() {
      try {
        const res = await ApiUtil.get('/insurance/inson/references/abroad-groups');

        if (res.status === 200 && res.data) {
          return res.data.data;
        }
      } catch (err) {
        console.error(err);
      }
    },

    async getInsuranceTypes() {
      try {
        const res = await ApiUtil.get('/insurance/inson/references/abroad-types');

        if (res.status === 200 && res.data) {
          return res.data.data;
        }
      } catch (err) {
        console.error(err);
      }
    },

    async getInsuranceMultiDays() {
      try {
        const res = await ApiUtil.get('/insurance/inson/references/multi-days');

        if (res.status === 200 && res.data) {
          return res.data.data;
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
