var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "h2",
        { staticClass: "destinations__title", class: _vm.notFoundHide },
        [_vm._v(_vm._s(_vm.getLocaleMsg("title")))]
      ),
      _vm._v(" "),
      _c(
        "swiper",
        {
          staticClass: "destinations__block",
          attrs: { options: _vm.swiperOptions },
        },
        [
          _c(
            "swiper-slide",
            {
              staticClass:
                "flex cursor-pointer flex-col items-center text-[#1B2226]",
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("IST")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "destinations__block-img",
                      style: { background: _vm.notFoundBackground },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rounded-[50%] border-4 bg-white",
                          class: _vm.notFoundBorder,
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: _vm.istanbulImage,
                                expression: "istanbulImage",
                              },
                            ],
                            attrs: { alt: "Istanbul image" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "destinations__block-title",
                      class: _vm.notFound,
                    },
                    [_vm._v(_vm._s(_vm.getLocaleMsg("cities.istanbul")))]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "swiper-slide",
            {
              staticClass:
                "flex cursor-pointer flex-col items-center text-[#1B2226]",
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("DXB")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "destinations__block-img",
                      style: { background: _vm.notFoundBackground },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rounded-[50%] border-4 bg-white",
                          class: _vm.notFoundBorder,
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: _vm.dubaiImage,
                                expression: "dubaiImage",
                              },
                            ],
                            attrs: { alt: "Dubai image" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "destinations__block-title",
                      class: _vm.notFound,
                    },
                    [_vm._v(_vm._s(_vm.getLocaleMsg("cities.dubai")))]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "swiper-slide",
            {
              staticClass:
                "flex cursor-pointer flex-col items-center text-[#1B2226]",
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("BER")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "destinations__block-img",
                      style: { background: _vm.notFoundBackground },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rounded-[50%] border-4 bg-white",
                          class: _vm.notFoundBorder,
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: _vm.berlinImage,
                                expression: "berlinImage",
                              },
                            ],
                            attrs: { alt: "Berlin Image" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "destinations__block-title",
                      class: _vm.notFound,
                    },
                    [_vm._v(_vm._s(_vm.getLocaleMsg("cities.berlin")))]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "swiper-slide",
            {
              staticClass:
                "flex cursor-pointer flex-col items-center text-[#1B2226]",
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("MOW")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "destinations__block-img",
                      style: { background: _vm.notFoundBackground },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rounded-[50%] border-4 bg-white",
                          class: _vm.notFoundBorder,
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: _vm.mowImage,
                                expression: "mowImage",
                              },
                            ],
                            attrs: { alt: "Moscow image" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "destinations__block-title",
                      class: _vm.notFound,
                    },
                    [_vm._v(_vm._s(_vm.getLocaleMsg("cities.moscow")))]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "swiper-slide",
            {
              staticClass:
                "flex cursor-pointer flex-col items-center text-[#1B2226]",
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("WAW")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "destinations__block-img",
                      style: { background: _vm.notFoundBackground },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rounded-[50%] border-4 bg-white",
                          class: _vm.notFoundBorder,
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: _vm.warsawImage,
                                expression: "warsawImage",
                              },
                            ],
                            attrs: { alt: "Warsaw image" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "destinations__block-title",
                      class: _vm.notFound,
                    },
                    [_vm._v(_vm._s(_vm.getLocaleMsg("cities.warsaw")))]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "swiper-slide",
            {
              staticClass:
                "flex cursor-pointer flex-col items-center text-[#1B2226]",
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("SFO")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "destinations__block-img",
                      style: { background: _vm.notFoundBackground },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rounded-[50%] border-4 bg-white",
                          class: _vm.notFoundBorder,
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: _vm.franciscoImage,
                                expression: "franciscoImage",
                              },
                            ],
                            attrs: { alt: "Francisco image" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "destinations__block-title",
                      class: _vm.notFound,
                    },
                    [_vm._v(_vm._s(_vm.getLocaleMsg("cities.sanFrancisco")))]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "swiper-slide",
            {
              staticClass:
                "flex cursor-pointer flex-col items-center text-[#1B2226]",
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("IST")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "destinations__block-img",
                      style: { background: _vm.notFoundBackground },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rounded-[50%] border-4 bg-white",
                          class: _vm.notFoundBorder,
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: _vm.istanbulImage,
                                expression: "istanbulImage",
                              },
                            ],
                            attrs: { alt: "Istanbul image" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "destinations__block-title",
                      class: _vm.notFound,
                    },
                    [_vm._v(_vm._s(_vm.getLocaleMsg("cities.istanbul")))]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "swiper-slide",
            {
              staticClass:
                "flex cursor-pointer flex-col items-center text-[#1B2226]",
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("DXB")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "destinations__block-img",
                      style: { background: _vm.notFoundBackground },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rounded-[50%] border-4 bg-white",
                          class: _vm.notFoundBorder,
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: _vm.dubaiImage,
                                expression: "dubaiImage",
                              },
                            ],
                            attrs: { alt: "Dubai image" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "destinations__block-title",
                      class: _vm.notFound,
                    },
                    [_vm._v(_vm._s(_vm.getLocaleMsg("cities.dubai")))]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "swiper-slide",
            {
              staticClass:
                "flex cursor-pointer flex-col items-center text-[#1B2226]",
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("BER")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "destinations__block-img",
                      style: { background: _vm.notFoundBackground },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rounded-[50%] border-4 bg-white",
                          class: _vm.notFoundBorder,
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: _vm.berlinImage,
                                expression: "berlinImage",
                              },
                            ],
                            attrs: { alt: "Berlin image" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "destinations__block-title",
                      class: _vm.notFound,
                    },
                    [_vm._v(_vm._s(_vm.getLocaleMsg("cities.berlin")))]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "swiper-slide",
            {
              staticClass:
                "flex cursor-pointer flex-col items-center text-[#1B2226]",
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("MOW")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "destinations__block-img",
                      style: { background: _vm.notFoundBackground },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rounded-[50%] border-4 bg-white",
                          class: _vm.notFoundBorder,
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: _vm.mowImage,
                                expression: "mowImage",
                              },
                            ],
                            attrs: { alt: "Moscow image" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "destinations__block-title",
                      class: _vm.notFound,
                    },
                    [_vm._v(_vm._s(_vm.getLocaleMsg("cities.moscow")))]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "swiper-slide",
            {
              staticClass:
                "flex cursor-pointer flex-col items-center text-[#1B2226]",
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("WAW")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "destinations__block-img",
                      style: { background: _vm.notFoundBackground },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rounded-[50%] border-4 bg-white",
                          class: _vm.notFoundBorder,
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: _vm.warsawImage,
                                expression: "warsawImage",
                              },
                            ],
                            attrs: { alt: "Moscow image" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "destinations__block-title",
                      class: _vm.notFound,
                    },
                    [_vm._v(_vm._s(_vm.getLocaleMsg("cities.warsaw")))]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "swiper-slide",
            {
              staticClass:
                "flex cursor-pointer flex-col items-center text-[#1B2226]",
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("SFO")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "destinations__block-img",
                      style: { background: _vm.notFoundBackground },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rounded-[50%] border-4 bg-white",
                          class: _vm.notFoundBorder,
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: _vm.franciscoImage,
                                expression: "franciscoImage",
                              },
                            ],
                            attrs: { alt: "Francisco image" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "destinations__block-title",
                      class: _vm.notFound,
                    },
                    [_vm._v(_vm._s(_vm.getLocaleMsg("cities.sanFrancisco")))]
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }