import Vue from 'vue';
import i18n from '@/i18n';
import * as VueGoogleMaps from 'vue2-google-maps';

function initVueGoogleMaps() {
  if (window.__PRERENDERING !== undefined) return;

  Vue.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      language: i18n.locale,
      installComponents: true,
    },
  });
}

initVueGoogleMaps();
