import ApiUtil from '@/utils/api';

export default {
  state: {
    ticketRules: null,
    ticketRulesCancelTokenSource: null,
    ticketRulesPopup: { status: null },
    ticketTariffs: null,
    ticketTariffsPopup: {
      status: null,
      cancelToken: null,
      ticketData: null,
    },
    ticketDetailsPopup: {
      status: null,
      ticketData: null,
    },
    ticketNotAvailablePopup: { status: null },
  },

  getters: {
    ticketRules: (state) => state.ticketRules,
    ticketRulesPopup: (state) => state.ticketRulesPopup,
    ticketTariffs: (state) => state.ticketTariffs,
    ticketTariffsPopup: (state) => state.ticketTariffsPopup,
    ticketDetailsPopup: (state) => state.ticketDetailsPopup,
    ticketNotAvailablePopup: (state) => state.ticketNotAvailablePopup,
  },

  actions: {
    getTicketRules({ commit }, buyId) {
      const cancelTokenSource = ApiUtil.getCancelTokenSource();

      commit('setTicketRulesPopupData', { status: 'open' });

      commit('setTicketRulesCancelTokenSource', cancelTokenSource);

      ApiUtil.get(`/offers/${buyId}/rules`, { cancelToken: cancelTokenSource.token })
        .then((response) => {
          commit('setTicketRules', response.data);
          commit('setTicketRulesCancelTokenSource', null);
        })
        .catch((error) => {
          if (ApiUtil.isCancel(error)) return;

          commit('setTicketRules', {
            flights: [
              {
                isActive: true,
                rules: [],
              },
            ],
          });
        });
    },
    setTicketRulesPopupData: ({ commit, state }, data) => {
      if (data.status === null) {
        if (state.ticketRulesCancelTokenSource !== null) {
          state.ticketRulesCancelTokenSource.cancel();

          commit('setTicketRulesCancelTokenSource', null);
        }

        commit('setTicketRules', null);
      }

      commit('setTicketRulesPopupData', data);
    },
    getTicketTariffs({ commit }, ticket) {
      commit('setTicketTariffsPopupData', { status: 'open' });

      const cancelTokenSource = ApiUtil.getCancelTokenSource();

      commit('setTicketTariffsPopupData', { cancelTokenSource: cancelTokenSource });

      const offerId = ticket.tariffs_id === undefined ? ticket.buy_id : ticket.tariffs_id;

      ApiUtil.get(`/offers/${offerId}/smart_ff`, { cancelToken: cancelTokenSource.token })
        .then((response) => {
          if (response.status === 200 && response.data) {
            commit('setTicketTariffs', response.data.data);

            commit('setTicketTariffsPopupData', {
              ticketData: ticket,
              cancelTokenSource: null,
            });
          } else {
            commit('setTicketTariffs', []);
          }
        })
        .catch((error) => {
          if (ApiUtil.isCancel(error)) return;

          commit('setTicketTariffs', []);
        });
    },
    setTicketTariffsPopupData: ({ state, commit }, data) => {
      if (data.status === null) {
        if (state.ticketTariffsPopup.cancelTokenSource !== null) {
          state.ticketTariffsPopup.cancelTokenSource.cancel();

          commit('setTicketTariffsPopupData', { cancelToken: null });
        }

        commit('setTicketTariffs', null);
      }

      commit('setTicketTariffsPopupData', data);
    },
    setTicketDetailsPopupData: ({ commit }, data) => commit('setTicketDetailsPopupData', data),
    setTicketNotAvailablePopupData: ({ commit }, data) => commit('setTicketNotAvailablePopupData', data),
  },

  mutations: {
    setTicketRules: (state, rules) => (state.ticketRules = rules),
    setTicketRulesPopupData: (state, data) =>
      (state.ticketRulesPopup = Object.assign({}, state.ticketRulesPopup, data)),
    setTicketRulesCancelTokenSource: (state, source) => (state.ticketRulesCancelTokenSource = source),
    setTicketTariffs: (state, tariffs) => (state.ticketTariffs = tariffs),
    setTicketTariffsPopupData: (state, data) =>
      (state.ticketTariffsPopup = Object.assign({}, state.ticketTariffsPopup, data)),
    setTicketDetailsPopupData: (state, data) =>
      (state.ticketDetailsPopup = Object.assign({}, state.ticketDetailsPopup, data)),
    setTicketNotAvailablePopupData: (state, data) =>
      (state.ticketNotAvailablePopup = Object.assign({}, state.ticketNotAvailablePopup, data)),
  },
};
