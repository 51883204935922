import axios from 'axios';
import TokenUtil from '@/utils/token';
import CurrencyUtil from '@/utils/currency';
import i18n from '@/i18n';

export default {
  init(baseUrl) {
    axios.defaults.baseURL = baseUrl;
    axios.defaults.headers.common['Accept-Language'] = i18n.locale;
    this.setHeaderAuthToken();
  },
  setHeaderAuthToken(token) {
    if (!TokenUtil.getToken() && !token) return;
    axios.defaults.headers.common['Authorization'] = `Bearer ${TokenUtil.getToken() || token}`;
  },
  removeHeaderAuthToken() {
    delete axios.defaults.headers.common['Authorization'];
  },
  setHeaderClientCurrency() {
    axios.defaults.headers.common['X-Currency'] = CurrencyUtil.getCurrent();
  },
  removeHeaderClientCurrency() {
    delete axios.defaults.headers.common['X-Currency'];
  },
  get(url, config) {
    return axios.get(url, config);
  },
  post(url, data, config) {
    return axios.post(url, data, config);
  },
  put(url, data, config) {
    return axios.put(url, data, config);
  },
  delete(url, config) {
    return axios.delete(url, config);
  },
  customRequest(config) {
    return axios(config);
  },
  isCancel(error) {
    return axios.isCancel(error);
  },
  getCancelTokenSource() {
    return axios.CancelToken.source();
  },
};
