<template>
  <div
    :class="[
      'form-field',
      'form-field_type_airports',
      {
        'form-field_state_focus': fieldIsFocus,
        'form-field_state_invalid': fieldIsInvalid,
        'form-field_label-position_top': labelPositionIsTop,
        'form-field_dropdown-visibility_visible': airportsListIsVisible,
      },
    ]"
    v-click-outside="onClickOutsideField"
  >
    <div class="form-field__input-wrapper">
      <!--            <label :for="name" class="form-field__label">{{ label }}</label>-->
      <!--            <span v-if="type === 'departure'" v-html="svgIcons.get('flight_takeoff')" class="form-field__icon"/>-->
      <!--            <span v-if="type === 'arrival'" v-html="svgIcons.get('flight_land')" class="form-field__icon"/>-->
      <input
        ref="fieldInput"
        :id="name"
        :placeholder="label"
        :value="fieldValue.name"
        :title="fieldValue.name"
        @input="onInputField($event.target.value)"
        @focus="onFocusField()"
        @blur="onBlurField()"
        @keydown.enter="onKeydownEnter()"
        @keydown.up="onKeydownUp()"
        @keydown.down="onKeydownDown()"
        class="form-field__input placeholder:!text-[#A5ABB0]"
        type="text"
        autocomplete="off"
        style="padding-left: 20px !important"
      />
      <span class="form-field__code">{{ fieldValue.code }}</span>
      <div class="form-field__message form-field__message_type_error">
        {{ errorMessage }}
      </div>
    </div>
    <div v-if="airports && airports.length" class="form-field__dropdown mt-0.5 w-[304px]">
      <ul class="form-field__list overflow-hidden">
        <li
          v-for="(airport, airportKey) of airports"
          :key="airportKey"
          :class="[
            'form-field__list-item',
            'list-item',
            'list-item_theme_form-airports-field',
            `list-item_type_${airport.type}`,
            {
              'list-item_state_hover': airportsListItemId === airportKey,
            },
          ]"
          @click="selectAirport(airportKey)"
        >
          <div class="list-item__inner">
            <span class="list-item__title-container">
              <span v-if="airport.type === 'avia'" v-html="svgIcons.get('airplane')" class="list-item__icon" />
              <span
                v-html="airport.type === 'city' ? `${airport.name}, ${airport.country_name}` : airport.name"
                class="list-item__title"
              />
            </span>
            <span class="list-item__code">{{ airport.code }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AirportsField',

  model: {
    prop: 'fieldValue',
    event: 'input',
  },
  emits: ['onFocus', 'outFocus', 'focused'],

  props: {
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'departure',
    },
    fieldValue: {
      type: Object,
      default: () => Object.assign({}, this.fieldValueDefault),
    },
    autofocus: {
      type: [Boolean, String],
      default: false,
    },
    validation: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      fieldValueDefault: {
        name: null,
        code: null,
      },
      fieldValueAutoSelectAllowed: true,
      fieldIsFocus: false,
      fieldIsEdited: false,
      airportsSearchTimeout: null,
      airportsListItemId: -1,
    };
  },

  computed: {
    ...mapGetters(['airports']),
    fieldIsInvalid() {
      return this.validation && this.fieldValue.code === null && !this.fieldIsFocus;
    },
    labelPositionIsTop() {
      return this.fieldIsFocus || this.fieldValue.name !== null;
    },
    airportsListIsVisible() {
      return this.airports && this.airports.length && this.fieldIsFocus && this.fieldValue.name !== null;
    },
  },

  watch: {
    airports(value) {
      if (this.fieldValueAutoSelectAllowed && this.fieldIsFocus && value && value.length === 1) {
        setTimeout(() => this.selectAirport(0), 250);
      }
    },
    autofocus(value) {
      if (value) {
        this.$refs.fieldInput.focus();

        this.$emit('focused');
      }
    },
    fieldIsFocus(newValue) {
      if (newValue) {
        this.$emit('onFocus', this.name);
      } else {
        this.$emit('outFocus', this.name);
      }
    },
  },

  created() {
    if (this.fieldValue.code !== null) this.fieldValueAutoSelectAllowed = false;
  },

  methods: {
    ...mapActions(['getAirports', 'resetAirports']),
    onInputField(value) {
      this.fieldIsEdited = true;

      this.$emit('input', Object.assign(this.fieldValue, { name: value }));

      this.searchAirports(value);
    },
    onFocusField() {
      this.fieldIsFocus = true;
    },
    onBlurField() {
      this.fieldIsFocus = false;
    },
    onClickOutsideField() {
      if ((this.fieldIsEdited && !this.fieldIsFocus) || (this.fieldIsEdited && this.fieldValue.code === null)) {
        this.autoSelectAirport();
      }
    },
    onKeydownEnter() {
      if (!this.airportsListIsVisible || this.airportsListItemId === -1) return;

      this.selectAirport(this.airportsListItemId);

      this.airportsListItemId = -1;
    },
    onKeydownUp() {
      if (!this.airportsListIsVisible) return;

      if (this.airportsListItemId > 0) {
        this.airportsListItemId -= 1;
      } else {
        this.airportsListItemId = this.airports.length - 1;
      }
    },
    onKeydownDown() {
      if (!this.airportsListIsVisible) return;

      if (this.airportsListItemId < this.airports.length - 1) {
        this.airportsListItemId += 1;
      } else {
        this.airportsListItemId = 0;
      }
    },
    searchAirports(query) {
      if (this.airportsSearchTimeout) clearTimeout(this.airportsSearchTimeout);

      if (!query) {
        this.resetAirports();

        return;
      }

      this.airportsSearchTimeout = setTimeout(() => this.getAirports(query), 250);
    },
    selectAirport(id) {
      id !== -1
        ? this.$emit('input', this.airports[id])
        : this.$emit('input', Object.assign({}, this.fieldValueDefault));

      if (id !== -1) {
        this.resetAirports();

        this.$emit('selected');
      }

      this.fieldIsFocus = false;
      this.fieldIsEdited = false;
      this.fieldValueAutoSelectAllowed = false;
    },
    autoSelectAirport() {
      this.airports.length ? this.selectAirport(0) : this.selectAirport(-1);

      this.airportsListItemId = -1;
    },
  },
};
</script>
