var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropdown,
          expression: "closeDropdown",
        },
      ],
      class: [
        "form-field",
        "form-field_type_passengers",
        {
          "form-field_dropdown-visibility_visible": _vm.fieldDropdownIsVisible,
          "form-field_label-position_top": _vm.labelPositionIsTop,
        },
      ],
    },
    [
      _c("div", { staticClass: "form-field__inner" }, [
        _c(
          "div",
          {
            staticClass: "form-field__input-wrapper inline-flex items-center",
            on: { click: _vm.toggleDropdown },
          },
          [
            _c("input", {
              staticClass: "form-field__input",
              attrs: { type: "text", readonly: "", hidden: "" },
              domProps: { value: _vm.value },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "text-sm font-medium text-[#A5ABB0]" }, [
              _vm._v(_vm._s(_vm.value)),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-field__dropdown" }, [
          _c(
            "div",
            {
              staticClass:
                "form-field__section form-field__section_type_trip-class",
            },
            [
              _c(
                "div",
                { staticClass: "form-field__select" },
                _vm._l(_vm.tripClasses, function (tripClassCode) {
                  return _c(
                    "button",
                    {
                      key: tripClassCode,
                      class: [
                        "form-field__select-option",
                        {
                          "form-field__select-option_state_selected":
                            _vm.fieldValue.tripClass === tripClassCode,
                        },
                      ],
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.changeTripClass(tripClassCode)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "form-field__select-option-text" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("common.tripClasses")[tripClassCode])
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", {
                        class: [
                          "form-field__select-option-icon",
                          {
                            "form-field__select-option-icon_state_selected":
                              _vm.fieldValue.tripClass === tripClassCode,
                          },
                        ],
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getTripClassIcon(tripClassCode)
                          ),
                        },
                      }),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }