<template>
  <div id="nemo-widget"></div>
</template>

<script>
export default {
  name: 'NemoWidget',

  data: () => ({
    nemoWidgetScript: document.getElementById('nemo-widget-script'),
  }),

  mounted() {
    if (window.FlightsSearchWidget) {
      this.initNemoWidget();
    } else {
      this.nemoWidgetScript.onload = () => {
        this.initNemoWidget();
      };
    }
  },

  methods: {
    initNemoWidget() {
      window.FlightsSearchWidget.init({
        webskyURL: 'http://demo.websky.aero/gru',
        nemoURL: 'https://charter.alltrip.uz/',
        rootElement: document.getElementById('nemo-widget'),
        locale: 'ru',
      });
    },
  },
};
</script>
