import ApiUtil from '@/utils/api';
import CacheUtil from '@/utils/cache';

export default {
  state: {
    countries: CacheUtil.getDataByKey('countries') || [],
  },

  getters: {
    countries: (state) => state.countries,
    countriesList: (state) => {
      return state.countries.map((country) => {
        return {
          text: country.name_ru,
          value: country.code,
          icon: country.flag_url,
        };
      });
    },
  },

  actions: {
    initCountries: ({ state, dispatch }) => {
      state.countries.length ||
        dispatch('getCountries')
          .then(() => {})
          .catch(() => {});
    },
    getCountries({ commit }, query) {
      return new Promise((resolve, reject) => {
        const requestConfig = {
          params: {
            query: query ? query : null,
            limit: query ? 7 : null,
          },
        };

        ApiUtil.get('/dictionaries/search-citizenship', requestConfig, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
          .then((response) => {
            if (response.status === 200 && response.data && response.data.length) {
              if (query === undefined) {
                commit('setCountries', response.data);

                CacheUtil.setDataByKey('countries', response.data);
              }

              resolve(response.data);
            } else {
              reject([]);
            }
          })
          .catch(() => reject([]));
      });
    },
  },

  mutations: {
    setCountries: (state, countries) => (state.countries = countries),
  },
};
