var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative lg:pb-[20px]", attrs: { id: "flight" } },
    [
      _c(
        "div",
        {
          staticClass:
            "relative flex flex-col items-start space-y-4 md:flex-row md:items-center md:justify-between md:space-y-0",
        },
        [
          _c(
            "div",
            {
              staticClass: "inline-flex flex-col space-y-[9px] text-[#36454F]",
            },
            [
              _c("div", { staticClass: "flights__title" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.getLocaleMsg("title")) + "\n      "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "relative z-[0] flex w-[108px] space-x-4 self-center max-md:self-start max-sm:w-full",
            },
            [
              _c("button", {
                staticClass: "swiper-button-prev",
                attrs: { slot: "button-prev" },
                slot: "button-prev",
              }),
              _vm._v(" "),
              _c("button", {
                staticClass: "swiper-button-next",
                attrs: { slot: "button-next" },
                slot: "button-next",
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-[20px] md:mt-[40px]" },
        [
          _c(
            "swiper",
            {
              staticClass: "flights-section",
              attrs: { options: _vm.swiperOptions },
            },
            [
              _c(
                "swiper-slide",
                {
                  staticClass:
                    "flights__card relative max-sm:flex max-sm:justify-center",
                },
                [
                  _c("div", { staticClass: "relative max-sm:max-w-[400px]" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: _vm.maldivImage,
                          expression: "maldivImage",
                        },
                      ],
                      staticClass: "w-full max-sm:max-w-[400px]",
                      attrs: { alt: "Maldiv image" },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "absolute flex flex-col items-start",
                        staticStyle: { bottom: "39px", left: "24px" },
                      },
                      [
                        _c("p", { staticClass: "flights__card-heading" }, [
                          _vm._v(_vm._s(_vm.getLocaleMsg("places.country1"))),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "flights__card-title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getLocaleMsg("places.place1")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "rounded-lg bg-white py-1",
                            staticStyle: {
                              "padding-left": "16px",
                              "padding-right": "16px",
                            },
                            attrs: {
                              href: "https://t.me/AllTrip_uz",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.getLocaleMsg("detail")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "swiper-slide",
                {
                  staticClass:
                    "flights__card relative max-sm:flex max-sm:justify-center",
                },
                [
                  _c("div", { staticClass: "relative max-sm:max-w-[400px]" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: _vm.antalyaImage,
                          expression: "antalyaImage",
                        },
                      ],
                      staticClass: "w-full max-sm:max-w-[400px]",
                      attrs: { alt: "Antalya image" },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "absolute flex flex-col items-start",
                        staticStyle: { bottom: "39px", left: "24px" },
                      },
                      [
                        _c("p", { staticClass: "flights__card-heading" }, [
                          _vm._v(_vm._s(_vm.getLocaleMsg("places.country2"))),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "flights__card-title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getLocaleMsg("places.place2")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "rounded-lg bg-white py-1",
                            staticStyle: {
                              "padding-left": "16px",
                              "padding-right": "16px",
                            },
                            attrs: {
                              href: "https://t.me/AllTrip_uz",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.getLocaleMsg("detail")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "swiper-slide",
                {
                  staticClass:
                    "flights__card relative max-sm:flex max-sm:justify-center",
                },
                [
                  _c("div", { staticClass: "relative max-sm:max-w-[400px]" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: _vm.tajmahalImage,
                          expression: "tajmahalImage",
                        },
                      ],
                      staticClass: "w-full max-sm:max-w-[400px]",
                      attrs: { alt: "Tajmahal image" },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "absolute flex flex-col items-start",
                        staticStyle: { bottom: "39px", left: "24px" },
                      },
                      [
                        _c("p", { staticClass: "flights__card-heading" }, [
                          _vm._v(_vm._s(_vm.getLocaleMsg("places.country3"))),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "flights__card-title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getLocaleMsg("places.place3")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "rounded-lg bg-white py-1",
                            staticStyle: {
                              "padding-left": "16px",
                              "padding-right": "16px",
                            },
                            attrs: {
                              href: "https://t.me/AllTrip_uz",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.getLocaleMsg("detail")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "swiper-slide",
                {
                  staticClass:
                    "flights__card relative max-sm:flex max-sm:justify-center",
                },
                [
                  _c("div", { staticClass: "relative max-sm:max-w-[400px]" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: _vm.maldivImage,
                          expression: "maldivImage",
                        },
                      ],
                      staticClass: "w-full max-sm:max-w-[400px]",
                      attrs: { alt: "Maldiv image" },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "absolute flex flex-col items-start",
                        staticStyle: { bottom: "39px", left: "24px" },
                      },
                      [
                        _c("p", { staticClass: "flights__card-heading" }, [
                          _vm._v(_vm._s(_vm.getLocaleMsg("places.country1"))),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "flights__card-title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getLocaleMsg("places.place1")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "rounded-lg bg-white py-1",
                            staticStyle: {
                              "padding-left": "16px",
                              "padding-right": "16px",
                            },
                            attrs: {
                              href: "https://t.me/AllTrip_uz",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.getLocaleMsg("detail")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex justify-center" },
            [
              _c(
                "router-link",
                {
                  staticClass:
                    "mt-2 rounded-lg bg-[#E8EEF2] px-24 py-4 text-sm font-semibold text-[#1B2226]",
                  attrs: { to: "/tourism" },
                },
                [_vm._v(_vm._s(_vm.$t("pages.Home.button.info")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }