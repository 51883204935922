<template>
  <div :class="['layout__header', `header_theme_${layoutTheme}`, layoutTheme !== 2 && 'bg-[#1D6BC0]']">
    <div
      :class="[
        layoutTheme === 2
          ? 'grid-container'
          : layoutTheme === 7 || layoutTheme === 6
          ? routeName === 'Business'
            ? 'section__container grid-container mx-auto px-[15px]'
            : 'section__container grid-container mx-auto px-[15px] md:px-[50px] lg:px-[100px]'
          : 'section__container grid-container mx-auto px-[15px]',
      ]"
    >
      <div :class="['header__inner', layoutTheme === 2 && 'border-b-[2px] border-dashed border-[#fff]']">
        <div class="flex">
          <div class="header__logo">
            <router-link :to="{ name: 'home', params: { lang: $i18n.locale } }">
              <img
                v-if="layoutTheme === 2"
                class="header__logo-img"
                v-lazy="logoWhite"
                width="130"
                alt="AllTrip"
              />
              <img v-else class="header__logo-img" v-lazy="logoWhite" width="130" alt="AllTrip" />
            </router-link>
          </div>
          <ul class="ml-4 hidden items-center text-white lg:ml-[64px] lg:flex lg:space-x-[24px]">
            <li>
              <div class="nav__link flex items-center space-x-[10px]">
                <router-link :to="{ name: 'tourism', params: { lang: $i18n.locale } }">
                  <span>{{ getLocaleMsg('tourism') }}</span>
                </router-link>
              </div>
            </li>
            <li>
              <a class="nav__link" href="https://realavia.alltrip.uz" target="_blank">
                {{ getLocaleMsg('businessAviation') }}
              </a>
            </li>
            <li>
              <div class="nav__link flex items-center space-x-[10px]">
                <router-link :to="{ name: 'umrah', params: { lang: $i18n.locale } }">
                  <span>{{ getLocaleMsg('umrah') }}</span>
                </router-link>
                <router-link v-show="false" :to="{ name: 'Business', params: { lang: $i18n.locale } }">
                  <span :class="routeName === 'Business' ? 'nav__business' : 'nav__business'">
                    {{ getLocaleMsg('special') }}
                  </span>
                </router-link>
              </div>
            </li>
          </ul>
        </div>
        <div class="header__controls">
          <div v-if="true" class="header__controls-item">
            <LanguageSelect />
          </div>
          <div class="header__controls-item">
            <CurrencySelect />
          </div>
          <div class="header__controls-item">
            <UserAccountMenu />
          </div>
          <div class="header__controls-item lg:hidden">
            <Menubar />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CurrencySelect from './CurrencySelect';
import LanguageSelect from './LanguageSelect';
import UserAccountMenu from './UserAccountMenu';
import Menubar from './Menubar';
import { computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import logoWhite from '@/assets/svg/logo_white.svg';

export default {
  name: 'SiteHeader',

  components: {
    CurrencySelect,
    LanguageSelect,
    Menubar,
    UserAccountMenu,
  },

  computed: {
    ...mapGetters(['layoutTheme']),
  },
  methods: {
    getLocaleMsg(key) {
      return this.$getLocaleMsg(`components.SiteFooter.nav.user.links.${key}`);
    },
  },
  setup() {
    const routeName = computed(() => {
      return useRoute().name;
    });
    return { routeName, logoWhite};
  },
};
</script>
