import ApiUtil from '@/utils/api';

export default {
  state: {
    orders: [],
    ordersCount: 0,
  },

  getters: {
    orders: (state) => state.orders,
    ordersCount: (state) => state.ordersCount,
  },

  mutations: {
    SET_ORDERS: (state, payload) => {
      state.orders = payload.list;
      state.ordersCount = payload.count;
    },
  },

  actions: {
    getOrders: ({ commit }, payload) => {
      const params = new URLSearchParams(payload).toString();
      const apiUrl = `/air/order${params ? `?${params}` : ''}`;
      ApiUtil.get(apiUrl)
        .then((res) => commit('SET_ORDERS', res.data.data))
        .catch((err) => console.error(err));
    },
  },
};
