import ApiUtil from '@/utils/api';
import ToastUtil from '@/utils/toast';

export default {
  state: {
    notebookItems: [],
  },

  getters: {
    notebookItems: (state) => state.notebookItems,
  },

  actions: {
    initNotebookItems: ({ dispatch, rootState }) => {
      if (!rootState.user.userAccessToken) return;

      dispatch('getNotebookItems');
    },
    getNotebookItems: ({ commit }) => {
      ApiUtil.get('/passengers?page=1&per_page=12')
        .then((response) => {
          if (response.status === 200) {
            commit('setNotebookItems', response.data.list);
          } else {
            commit('setNotebookItems', []);
          }
        })
        .catch(() => commit('setNotebookItems', []));
    },
    addNotebookItem: ({ commit }, data) => {
      return new Promise((resolve, reject) => {
        ApiUtil.post('/passengers', data)
          .then((response) => {
            if (response.status === 200) {
              commit('addNotebookItem', response.data);

              resolve('added');
            } else {
              reject('failed');
            }
          })
          .catch(() => reject('failed'));
      });
    },
    updateNotebookItem: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        ApiUtil.put(`/passengers/${data.id}`, data)
          .then((response) => {
            if (response.status === 200) {
              commit('updateNotebookItem', response.data);
              resolve('updated');
              ToastUtil.show({
                message: 'Данные успешно изменены!',
                type: 'success',
              });
            } else {
              reject('failed');
            }
            dispatch('getNotebookItems');
          })
          .catch(() => reject('failed'));
      });
    },
    removeNotebookItem: ({ commit }, id) => {
      ApiUtil.delete(`/passengers/${id}`)
        .then((response) => {
          if (response.status === 204) {
            commit('removeNotebookItem', id);
          }
        })
        .catch((error) => {
          ToastUtil.show({
            message: error.message,
            type: 'error',
          });
        });
    },
  },

  mutations: {
    setNotebookItems: (state, items) => (state.notebookItems = items),
    addNotebookItem: (state, item) => state.notebookItems.unshift(item),
    updateNotebookItem: (state, updatedItem) =>
      (state.notebookItems = state.notebookItems.map((item) => (item.id !== updatedItem.id ? item : updatedItem))),
    removeNotebookItem: (state, id) => (state.notebookItems = state.notebookItems.filter((item) => item.id !== id)),
  },
};
