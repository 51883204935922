<script>
export default {
  name: 'MobileModal',
  emits: ['closeEvent'],
  data: () => ({
    isModal: true,
  }),
  methods: {
    closeModal() {
      this.isModal = false;
      this.$emit('closeEvent');
    },
    navigatePage() {
      this.$router.push('/our-app');
    },
    getLocalMsgPath(key) {
      return `components.${this.$options.name}.${key}`;
    },
    getLocaleMsg(key) {
      return this.$getLocaleMsg(this.getLocalMsgPath(key));
    },
  },
};
</script>

<template>
  <div
    class="fixed bottom-0 z-50 flex h-screen w-full flex-col justify-end"
    :class="isModal ? 'bg-[#011043] bg-opacity-50' : ''"
  >
    <div class="h-2/3 w-full">
      <div class="fixed bottom-0 w-full rounded-t-2xl bg-white">
        <div class="relative flex min-w-full flex-col space-y-6 pt-8">
          <div class="flex justify-center">
            <h3 class="w-[219px] text-center text-xl font-bold text-[#1D6BC0]">
              {{ getLocaleMsg('title') }}
            </h3>
          </div>
          <div class="flex justify-center">
            <p class="max-w-[297px] text-center text-sm font-medium text-[#1B2226]">
              {{ getLocaleMsg('text') }}
            </p>
          </div>
          <div class="flex justify-center">
            <p class="max-w-[253px] text-center text-sm font-medium text-[#3395FF]">
              {{ getLocaleMsg('link') }}
            </p>
          </div>
          <div class="absolute right-2 top-0 cursor-pointer" @click="closeModal">
            <img src="@/assets/svg/close.svg" alt="close button" />
          </div>
          <div class="relative flex w-full justify-center">
            <img class="object-cover" src="@/assets/images/mobile-modal.png" alt="mobile" />
            <div class="absolute bottom-4 left-1/2 w-full max-w-[343px] -translate-x-1/2 transform">
              <button
                class="w-full rounded-2xl bg-[#EFA543] px-11 py-5 text-center text-base font-semibold text-white"
                @click="navigatePage"
              >
                {{ getLocaleMsg('button') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.slide-bottom-enter-active {
  transition: transform 0.5s ease;
}

.slide-bottom-leave-active {
  transition: transform 0.5s ease;
}

.slide-bottom-enter {
  transform: translateY(100%);
}

.slide-bottom-leave-to {
  transform: translateY(100%);
}
</style>
