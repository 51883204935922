<template>
  <div>
    <div :class="['layout', `layout_theme_${layoutTheme}`]">
      <SiteHeader />
      <div :class="stylePages">
        <keep-alive>
          <router-view class="layout__main-content" />
        </keep-alive>
      </div>
      <SiteFooter :class="$route.name === 'notFound' ? 'hidden' : ''" />
      <SitePreloaders />
      <SitePopups />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SiteHeader from '@/components/SiteHeader/SiteHeader';
import SiteFooter from '@/components/SiteFooter';
import SitePreloaders from '@/components/SitePreloaders';
import SitePopups from '@/components/SitePopups';

export default {
  name: 'App',

  components: {
    SiteHeader,
    SiteFooter,
    SitePreloaders,
    SitePopups,
  },

  data: () => ({
    currentScreen: window.innerWidth <= 768,
  }),

  metaInfo() {
    return {
      title: this.siteTitle,
      titleTemplate: '%s - AllTrip',
      meta: [
        {
          property: 'og:title',
          content: `${this.siteTitle} - AllTrip`,
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['layoutTheme']),
    siteTitle() {
      return this.$t(`common.siteTitle.${this.$route.name || 'home'}`);
    },
    stylePages() {
      if (this.currentScreen) {
        return this.$route.name === 'notFound' ||
          this.$route.name === 'order' ||
          this.$route.name === 'umrah' ||
          this.$route.name === 'search'
          ? ''
          : 'layout__main relative';
      }
      return this.$route.name === 'notFound' || this.$route.name === 'umrah' || this.$route.name === 'search'
        ? ''
        : 'layout__main relative';
    },
  },

  created() {
    this.initUserData();
    this.handleSource();

    const routeQuery = { ...this.$route.query };
    if (routeQuery.token) {
      this.saveUserTokens(routeQuery.token);
      this.initUserData();

      delete routeQuery.token;
      this.$router.replace({ query: routeQuery });
    }
  },
  mounted() {
    window.onresize = () => {
      this.currentScreen = window.innerWidth <= 768;
    };
  },

  methods: {
    ...mapActions(['saveUserTokens', 'initUserData', 'getOrderConversionId']),
    handleSource() {
      if (this.$route.query.source === undefined) {
        return;
      }

      this.getOrderConversionId(this.$route.query.source)
        .then(() => {
          this.$router.replace({});
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.link__icon {
  margin-right: 4px;
}
</style>
