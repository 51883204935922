<template>
  <div
    :class="[
      'form-field',
      'form-field_type_checkbox',
      `form-field_type_checkbox_theme_${layoutTheme}`,
      `form-field_theme_${theme}`,
      {
        'form-field_state_invalid': !isValid,
      },
    ]"
  >
    <label class="form-field__input-wrapper">
      <input :checked="isChecked" :value="value" class="form-field__input" type="checkbox" @change="onChange" />
      <span class="form-field__box" />
      <div class="form-field__label" v-html="label" />
    </label>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CheckboxField',

  model: {
    prop: 'modelValue',
    event: 'input',
  },

  props: {
    theme: {
      type: [String, Number],
      default: 1,
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [Boolean, String, Number, Object, Array],
      default: null,
    },
    value: {
      type: [Boolean, String, Number, Object, Array],
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    isChecked: false,
  }),

  computed: {
    ...mapGetters(['layoutTheme']),
    isValid() {
      return this.error === null;
    },
  },

  watch: {
    modelValue() {
      this.setState();
    },
  },

  created() {
    this.setState();
  },

  methods: {
    setState() {
      if (Array.isArray(this.modelValue)) {
        this.isChecked = this.modelValue.indexOf(this.value) !== -1;
      } else if (typeof this.value === 'number' && typeof this.modelValue === 'string') {
        this.isChecked = this.value === parseInt(this.modelValue);
      } else {
        this.isChecked = this.value === this.modelValue;
      }
    },
    onChange(e) {
      let newValue = e.target.checked ? this.value : null;
      let outputValue = newValue;

      if (Array.isArray(this.modelValue)) {
        outputValue = [...this.modelValue];

        if (!e.target.checked) {
          outputValue.splice(this.modelValue.indexOf(this.value), 1);
        } else {
          outputValue.push(this.value);
        }
      }

      this.$emit('input', outputValue);

      this.$emit('change', newValue);
    },
  },
};
</script>
