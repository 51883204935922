import Vue from 'vue';
import VueI18n from 'vue-i18n';
import LanguageUtil from '@/utils/language';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};

  locales.keys().forEach((key) => {
    const matchedDir = key.match(/\.\/([a-z]+)/i);
    const matchedGroup = key.match(/\/json\/([a-z]+)/i);
    const matchedFile = key.match(/([A-Za-z0-9-_]+)\./i);

    if (matchedDir && matchedDir.length > 1 && matchedFile && matchedFile.length > 1) {
      if (messages[matchedDir[1]] === undefined) {
        messages[matchedDir[1]] = {};
      }

      if (messages[matchedDir[1]][matchedGroup[1]] === undefined) {
        messages[matchedDir[1]][matchedGroup[1]] = {};
      }

      messages[matchedDir[1]][matchedGroup[1]][matchedFile[1]] = locales(key);
    }
  });

  return messages;
}

// const defaultImpl = VueI18n.prototype.getChoiceIndex

VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
  // if (this.locale !== 'ru') {
  //     return defaultImpl.apply(this, arguments)
  // }

  if (choice === 0) {
    return 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;

  if (!teen && endsWithOne) {
    return 1;
  }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return choicesLength < 4 ? 2 : 3;
};

export default new VueI18n({
  locale: LanguageUtil.getCurrent(),
  fallbackLocale: 'ru',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
});
