<template>
  <div id="flight" class="relative lg:pb-[20px]">
    <div
      class="relative flex flex-col items-start space-y-4 md:flex-row md:items-center md:justify-between md:space-y-0"
    >
      <div class="inline-flex flex-col space-y-[9px] text-[#36454F]">
        <div class="flights__title">
          {{ getLocaleMsg('title') }}
        </div>
        <!-- <div class="inline-block text-sm md:text-base">
                    {{ getLocaleMsg('bestFlightSubtitle') }}
                </div> -->
      </div>
      <div class="relative z-[0] flex w-[108px] space-x-4 self-center max-md:self-start max-sm:w-full">
        <button slot="button-prev" class="swiper-button-prev"></button>
        <button slot="button-next" class="swiper-button-next"></button>
      </div>
    </div>
    <div class="mt-[20px] md:mt-[40px]">
      <swiper :options="swiperOptions" class="flights-section">
        <swiper-slide class="flights__card relative max-sm:flex max-sm:justify-center">
          <div class="relative max-sm:max-w-[400px]">
            <img class="w-full max-sm:max-w-[400px]" v-lazy="maldivImage" alt="Maldiv image" />
            <div class="absolute flex flex-col items-start" style="bottom: 39px; left: 24px">
              <p class="flights__card-heading">{{ getLocaleMsg('places.country1') }}</p>
              <p class="flights__card-title">
                {{ getLocaleMsg('places.place1') }}
              </p>
              <a
                href="https://t.me/AllTrip_uz"
                target="_blank"
                class="rounded-lg bg-white py-1"
                style="padding-left: 16px; padding-right: 16px"
              >
                {{ getLocaleMsg('detail') }}
              </a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="flights__card relative max-sm:flex max-sm:justify-center">
          <div class="relative max-sm:max-w-[400px]">
            <img class="w-full max-sm:max-w-[400px]" v-lazy="antalyaImage" alt="Antalya image" />
            <div class="absolute flex flex-col items-start" style="bottom: 39px; left: 24px">
              <p class="flights__card-heading">{{ getLocaleMsg('places.country2') }}</p>
              <p class="flights__card-title">
                {{ getLocaleMsg('places.place2') }}
              </p>
              <a
                href="https://t.me/AllTrip_uz"
                target="_blank"
                class="rounded-lg bg-white py-1"
                style="padding-left: 16px; padding-right: 16px"
              >
                {{ getLocaleMsg('detail') }}
              </a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="flights__card relative max-sm:flex max-sm:justify-center">
          <div class="relative max-sm:max-w-[400px]">
            <img class="w-full max-sm:max-w-[400px]" v-lazy="tajmahalImage" alt="Tajmahal image" />
            <div class="absolute flex flex-col items-start" style="bottom: 39px; left: 24px">
              <p class="flights__card-heading">{{ getLocaleMsg('places.country3') }}</p>
              <p class="flights__card-title">
                {{ getLocaleMsg('places.place3') }}
              </p>
              <a
                href="https://t.me/AllTrip_uz"
                target="_blank"
                class="rounded-lg bg-white py-1"
                style="padding-left: 16px; padding-right: 16px"
              >
                {{ getLocaleMsg('detail') }}
              </a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="flights__card relative max-sm:flex max-sm:justify-center">
          <div class="relative max-sm:max-w-[400px]">
            <img class="w-full max-sm:max-w-[400px]" v-lazy="maldivImage" alt="Maldiv image" />
            <div class="absolute flex flex-col items-start" style="bottom: 39px; left: 24px">
              <p class="flights__card-heading">{{ getLocaleMsg('places.country1') }}</p>
              <p class="flights__card-title">
                {{ getLocaleMsg('places.place1') }}
              </p>
              <a
                href="https://t.me/AllTrip_uz"
                target="_blank"
                class="rounded-lg bg-white py-1"
                style="padding-left: 16px; padding-right: 16px"
              >
                {{ getLocaleMsg('detail') }}
              </a>
            </div>
          </div>
        </swiper-slide>
        <!-- <div slot="pagination" class="swiper-pagination"></div>-->
      </swiper>
      <div class="flex justify-center">
        <router-link
          :to="'/tourism'"
          class="mt-2 rounded-lg bg-[#E8EEF2] px-24 py-4 text-sm font-semibold text-[#1B2226]"
          >{{ $t('pages.Home.button.info') }}</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
// import Card from "@/components/Home/Flights/Card";
import maldivImage from '@/assets/images/maldiv.png'
import antalyaImage from '@/assets/images/antalya.png'
import tajmahalImage from '@/assets/images/tajimahal.png'

export default {
  name: 'Flights',
  // components: { Card },
  props: {
    flights: {
      type: Array,
      default: [],
    },
  },
  data: () => ({
    swiperOptions: {
      slidesPerView: 3,
      spaceBetween: 32,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.flights .swiper-button-next',
        prevEl: '.flights .swiper-button-prev',
      },
      preloadImages: true,
      // Enable lazy loading
      lazy: true,
      breakpoints: {
        1279: {
          spaceBetween: 32,
        },
        639: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        230: {
          spaceBetween: 15,
          slidesPerView: 1,
        },
      },
    },
    maldivImage: maldivImage,
    antalyaImage: antalyaImage,
    tajmahalImage: tajmahalImage,
    
    // formData: {
    //     fields: [
    //         {
    //             departure: {
    //                 name: 'Стамбул',
    //                 code: 'IST',
    //                 country_name: 'Турция',
    //                 country_code: 'TR',
    //                 type: 'city'
    //             },
    //             arrival: {
    //                 name: 'Ташкент',
    //                 code: 'TAS',
    //                 country_name: 'Узбекистан',
    //                 country_code: 'UZ',
    //                 type: 'city'
    //             },
    //             dates: { 'one': '2022-10-10', 'two': '' }
    //         }
    //     ],
    //     passengers: {
    //         adultsQty: 1,
    //         childrensQty: 0,
    //         infantsQty: 0,
    //         tripClass: 'E'
    //     },
    //     flightOneWay: true,
    //     flightIsExtended: false,
    //     flightDatesFlexible: false,
    //     flightDirectOnly: false
    // },
  }),
  methods: {
    getLocaleMsg(key) {
      return this.$getLocaleMsg(`components.${this.$options.name}.${key}`);
    },
    // getFormFormattedData() {
    //     const formattedData = {
    //         directions: []
    //     }
    //
    //     if (this.formData.flightIsExtended) {
    //         this.formData.fields.forEach(field => {
    //             const direction = {
    //                 departure_code: field.departure.code,
    //                 arrival_code: field.arrival.code,
    //                 date: field.dates.one
    //             }
    //
    //             formattedData.directions.push(direction)
    //         })
    //     } else {
    //         const field = this.formData.fields[0]
    //
    //         for (let fieldName in field.dates) {
    //             if (fieldName === 'one' || !formattedData.flightOneWay && fieldName === 'two' && field.dates[fieldName] !== '') {
    //                 const direction = {
    //                     departure_code: fieldName === 'one' ? field.departure.code : field.arrival.code,
    //                     arrival_code: fieldName === 'one' ? field.arrival.code : field.departure.code,
    //                     date: field.dates[fieldName]
    //                 }
    //
    //                 formattedData.directions.push(direction)
    //             }
    //         }
    //     }
    //
    //     formattedData.direct = !!this.formData.flightDirectOnly
    //     formattedData.flexible = !!this.formData.flightDatesFlexible
    //     formattedData.count_adult = this.formData.passengers.adultsQty
    //     formattedData.count_child = this.formData.passengers.childrensQty
    //     formattedData.count_infant = this.formData.passengers.infantsQty
    //
    //     formattedData.class_type = this.formData.passengers.tripClass
    //
    //     return formattedData
    // },
  },
};
</script>
