import {
  format,
  addMinutes,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  subHours,
  subDays,
  parseISO,
} from 'date-fns';
import dateRuLocale from 'date-fns/locale/ru';
import i18n from '@/i18n';

export const debounce = (func, wait, immediate) => {
  let timeout;

  return function () {
    let context = this;
    let args = arguments;

    let later = function () {
      timeout = null;

      if (!immediate) func.apply(context, args);
    };

    let callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

export const findAncestor = (element, selector) => {
  if (!element) return null;

  if (typeof element.closest === 'function') {
    return element.closest(selector) || null;
  }

  while (element) {
    if (element.matches(selector)) {
      return element;
    }

    element = element.parentElement;
  }

  return null;
};

export const randomString = (length) => {
  let str = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    str += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  if (!isNaN(parseInt(str[0]))) str = `a${str}`;

  return str;
};

export const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const formatPrice = (price) =>
  Math.ceil(price)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const formatCurrencyPrice = (value, currency) => {
  let price = 'USD' === currency ? Math.ceil(100 * value) / 100 : Math.ceil(value);

  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const formatDate = (date, dateFormat) => {
  if (!date) return '';

  dateFormat = dateFormat === undefined ? 'yyyy-MM-dd' : dateFormat;

  return format(typeof date === 'string' ? parseISO(date) : date, dateFormat, { locale: dateRuLocale });
};

export const parseDateToUTC = (dateStr) => {
  const date = new Date(dateStr);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

export const convertDateToISO = (date) => date.split('.').reverse().join('-');

export const utcToLocalDate = (date) => {
  const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  localDate.setHours(hours - offset);

  return localDate;
};

export const timeToMinutes = (time) => {
  const timeParts = time.split(':');

  return timeParts.length ? parseInt(timeParts[0]) * 60 + parseInt(timeParts[1]) : null;
};

export const humanizeMinutes = (value) => {
  const result = [];

  const now = new Date();

  let future = addMinutes(now, value);

  const days = differenceInDays(future, now);

  if (days > 0) {
    result.push(i18n.t('common.date.shortDays', { days }));

    future = subDays(future, days);
  }

  const hours = differenceInHours(future, now);

  if (hours > 0) {
    result.push(i18n.t('common.date.shortHours', { hours }));

    future = subHours(future, hours);
  }

  const minutes = differenceInMinutes(future, now);

  if (minutes > 0) {
    result.push(i18n.t('common.date.shortMinutes', { minutes }));
  }

  return result.join(' ');
};

export const isObject = (value) => {
  return value !== null && typeof value === 'object' && Array.isArray(value) === false;
};
