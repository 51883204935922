import Vue from 'vue';
import router from '@/router';
import i18n from '@/i18n';
import cloneDeep from 'lodash.clonedeep';

import ApiUtil from '@/utils/api';
import TicketsUtil from '@/utils/tickets';
import AirportsUtil from '@/utils/airports';
import SearchWidgetUtil from '@/utils/tickets/search-widget';
import { createTicketsSearchId } from '@/utils/tickets/search-id';

function setTicketsSearchRoute(searchParams) {
  const searchId = createTicketsSearchId(searchParams);
  const lang = i18n.locale;
  const currentPath = `/${lang}/search/${searchId}`;

  if (currentPath === router.currentRoute.path) {
    return;
  }

  router.push({
    name: 'search',
    params: {
      lang,
      id: createTicketsSearchId(searchParams),
    },
  });
}

function getTicketsSearchFormDataByParams(searchParams) {
  if (searchParams === null) {
    return searchParams;
  }

  const formData = {
    fields: [],
    passengers: {
      adultsQty: parseInt(searchParams.count_adult),
      childrensQty: parseInt(searchParams.count_child),
      infantsQty: parseInt(searchParams.count_infant),
      tripClass: searchParams.class_type,
    },
    flightDirectOnly: searchParams.direct,
    flightOneWay: searchParams.directions.length === 1,
    flightIsExtended: false,
    flightDatesFlexible: false,
  };

  if (searchParams.directions.length > 2) {
    searchParams.directions.forEach((direction) => {
      const field = {
        departure: {
          code: direction.departure_code,
        },
        arrival: {
          code: direction.arrival_code,
        },
        dates: {
          one: direction.date,
          two: '',
        },
      };

      formData.fields.push(field);
    });

    return formData;
  }

  const firstDirection = searchParams.directions[0];

  const firstField = {
    departure: {
      code: firstDirection.departure_code,
    },
    arrival: {
      code: firstDirection.arrival_code,
    },
    dates: {
      one: firstDirection.date,
      two: '',
    },
  };

  if (formData.flightOneWay) {
    formData.fields.push(firstField);

    return formData;
  }

  const secondDirection = searchParams.directions[1];

  if (
    firstDirection.departure_code === secondDirection.arrival_code &&
    firstDirection.arrival_code === secondDirection.departure_code
  ) {
    firstField.dates.two = secondDirection.date;

    formData.fields.push(firstField);

    return formData;
  }

  formData.flightIsExtended = true;

  formData.fields.push(firstField);

  const secondField = {
    departure: {
      code: secondDirection.departure_code,
    },
    arrival: {
      code: secondDirection.arrival_code,
    },
    dates: {
      one: secondDirection.date,
      two: '',
    },
  };

  formData.fields.push(secondField);

  return formData;
}

function setTicketsSearchFormDataAirportsData(formData) {
  if (!formData.fields.length) {
    return formData;
  }

  const airports = {};

  formData.fields.forEach((field, fieldIndex) => {
    if (airports[field.departure.code] === undefined) {
      AirportsUtil.getAirportByCode(field.departure.code).then((airport) => {
        if (!airport) {
          return;
        }

        airports[airport.code] = airport;

        formData.fields[fieldIndex]['departure'] = airport;
      });
    } else {
      formData.fields[fieldIndex]['departure'] = airports[field.departure.code];
    }

    if (airports[field.arrival.code] === undefined) {
      AirportsUtil.getAirportByCode(field.arrival.code).then((airport) => {
        if (!airport) {
          return;
        }

        airports[airport.code] = airport;

        formData.fields[fieldIndex]['arrival'] = airport;
      });
    } else {
      formData.fields[fieldIndex]['arrival'] = airports[field.arrival.code];
    }
  });

  return formData;
}

function openTicketsNotFoundPage({ commit }) {
  commit('setTicketsSearchStatus', 'not-found');

  router.push({ name: 'searchNoResults' }).catch(() => {});
}

export default {
  state: {
    ticketsSearchStatus: null,
    ticketsSearchProgress: {
      timer: null,
      value: 0,
    },
    setTicketSearchProgressTimer: null,
    ticketsSearchFormData: SearchWidgetUtil.getFormData(),
    ticketsSearchParams: SearchWidgetUtil.getSearchParams(),
    tickets: [],
    filteredTickets: [],
    ticketsPerPage: 5,
    ticketsSorterButtons: [],
    ticketsFiltersParams: {},
    ticketsActiveFilters: [],
  },

  getters: {
    ticketsSearchStatus: (state) => state.ticketsSearchStatus,
    isTicketsSearchStart: (state) => state.ticketsSearchStatus === 'start',
    isTicketsSearchInProcess: (state) => state.ticketsSearchStatus === 'in-process',
    ticketsSearchProgress: (state) => state.ticketsSearchProgress,
    ticketsSearchFormData: (state) => state.ticketsSearchFormData,
    ticketsSearchParams: (state) => state.ticketsSearchParams,
    tickets: (state) => state.tickets,
    filteredTickets: (state) => state.filteredTickets,
    ticketsPerPage: (state) => state.ticketsPerPage,
    ticketsSorterButtons: (state) => state.ticketsSorterButtons,
    ticketsFiltersParams: (state) => state.ticketsFiltersParams,
    ticketsActiveFilters: (state) => state.ticketsActiveFilters,
    hasTicketsActiveFilters: (state) => state.ticketsActiveFilters.length,
  },

  actions: {
    setTicketsSearchStatus: ({ commit }, status) => commit('setTicketsSearchStatus', status),
    setTicketsSearchFormData: ({ commit }, data) => commit('setTicketsSearchFormData', data),
    setTicketsSearchParams: ({ commit }, params) => commit('setTicketsSearchParams', params),
    initTicketsSearchFormDataByParams: ({ commit, state }) => {
      let formData = getTicketsSearchFormDataByParams(state.ticketsSearchParams);

      if (formData === null) {
        return;
      }

      formData = setTicketsSearchFormDataAirportsData(formData);

      if (Object.keys(formData).length) {
        commit('setTicketsSearchFormData', formData);
      }
    },
    startTicketsSearchProgress: ({ dispatch, commit }) => {
      commit('setTicketsSearchProgressValue', 0);
      commit(
        'setTicketsSearchProgressTimer',
        setInterval(() => dispatch('increaseTicketsSearchProgress', 0.5), 200),
      );
    },
    stopTicketsSearchProgress: ({ commit, state }) => {
      commit('setTicketsSearchProgressTimer', clearInterval(state.ticketsSearchProgress.timer));
      commit('setTicketsSearchProgressValue', 100);
    },
    increaseTicketsSearchProgress: ({ commit, state }, value) => {
      commit('setTicketsSearchProgressValue', state.ticketsSearchProgress.value + value);
    },
    fetchTickets: ({ dispatch, commit, state }, searchParams) => {
      TicketsUtil.getTickets(localStorage.getItem('request_guid'))
        .then((response) => {
          if (response.status !== 200) {
            openTicketsNotFoundPage({ commit });
            return;
          }

          const { etm_status, ttbooking_status, request_id, tickets, actions, filters } = response.data;
          setTicketsSearchRoute(searchParams);

          commit('setTickets', cloneDeep(tickets));
          commit('setFilteredTickets', cloneDeep(tickets));

          commit('setTicketsSorterButtons', actions);
          commit('setTicketsFiltersParams', filters);
          if (etm_status === 'InProcess' || ttbooking_status === 'InProcess') {
            if (tickets.length > state.tickets.length) {
              dispatch('increaseTicketsSearchProgress', (100 - state.ticketsSearchProgress.value) / 10);
            }

            setTimeout(() => commit('setTicketsSearchStatus', 'in-process'), 500);

            dispatch('fetchTickets', Object.assign({}, searchParams, { request_id }));
          } else {
            dispatch('stopTicketsSearchProgress');

            setTimeout(() => commit('setTicketsSearchStatus', 'done'), 500);
          }
        })
        .catch((error) => {
          if (ApiUtil.isCancel(error)) return;

          openTicketsNotFoundPage({ commit });
        });
    },
    saveTicketsSearchData: ({ commit, dispatch }, { searchParams, formData }) => {
      if (formData && Object.keys(formData).length) {
        SearchWidgetUtil.saveFormData(formData);

        commit('setTicketsSearchFormData', formData);

        SearchWidgetUtil.saveSearchParams(searchParams);

        commit('setTicketsSearchParams', searchParams);
      } else {
        commit('setTicketsSearchParams', searchParams);

        dispatch('initTicketsSearchFormDataByParams');
      }
    },
    getTickets: ({ dispatch }, { searchParams, formData }) => {
      dispatch('startTicketsSearchProgress');

      dispatch('saveTicketsSearchData', { searchParams, formData });

      dispatch('fetchTickets', searchParams);
    },
    setTickets: ({ commit }, tickets) => commit('setTickets', tickets),
    setFilteredTickets: ({ commit }, tickets) => commit('setFilteredTickets', tickets),
    setTicketsFiltersParams: ({ commit }, params) => commit('setTicketsFiltersParams', params),
    setTicketsActiveFilters: ({ commit, state }, newFilter) => {
      let filters = state.ticketsActiveFilters;

      let filterIndex = filters.findIndex((filter) => filter.name === newFilter.name);

      filterIndex = filterIndex !== -1 ? filterIndex : filters.length;

      if (newFilter.value !== null) {
        Vue.set(filters, filterIndex, newFilter);
      } else {
        Vue.delete(filters, filterIndex);
      }

      commit('setTicketsActiveFilters', filters);
    },
    clearTicketsActiveFilters: ({ commit }) => commit('setTicketsActiveFilters', []),
  },

  mutations: {
    setTicketsSearchStatus: (state, status) => (state.ticketsSearchStatus = status),
    setTicketsSearchProgressTimer: (state, timer) => (state.ticketsSearchProgress.timer = timer),
    setTicketsSearchProgressValue: (state, value) => (state.ticketsSearchProgress.value = value),
    setTicketsSearchParams: (state, params) => (state.ticketsSearchParams = params),
    setTicketsSearchFormData: (state, data) => (state.ticketsSearchFormData = data),
    setTickets: (state, tickets) => (state.tickets = tickets),
    setFilteredTickets: (state, tickets) => (state.filteredTickets = tickets),
    setTicketsSorterButtons: (state, buttons) => (state.ticketsSorterButtons = buttons),
    setTicketsActiveFilters: (state, filters) => (state.ticketsActiveFilters = filters),
    setTicketsFiltersParams: (state, params) => (state.ticketsFiltersParams = params),
  },
};
