<template>
  <div>
    <h2 class="our-advantage__title max-sm:mt-8">
      {{ getLocaleMsg('advantages.title') }}
    </h2>
    <div class="our-advantage__grid">
      <div class="our-advantage__card">
        <img v-lazy="cheapFlightImage" alt="Cheap Flight Image" />
        <p class="our-advantage__card-text">
          {{ getLocaleMsg('advantages.text1') }}
        </p>
      </div>
      <div class="our-advantage__card">
        <img v-lazy="earPhoneImage" alt="Earphone image" />
        <p class="our-advantage__card-text">
          {{ getLocaleMsg('advantages.text2') }}
        </p>
      </div>
      <div class="our-advantage__card">
        <img v-lazy="lovingPeopleImage" alt="Loving People Image" />
        <p class="our-advantage__card-text">
          {{ getLocaleMsg('advantages.text3') }}
        </p>
      </div>
      <div class="our-advantage__card">
        <img v-lazy="handshakeImage" alt="Handshake Image" />
        <p class="our-advantage__card-text">
          {{ getLocaleMsg('advantages.text4') }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import cheapFlightImage from '@/assets/svg/cheap-flight-1.svg';
import earPhoneImage from '@/assets/svg/earphone.svg';
import lovingPeopleImage from '@/assets/svg/loving-people.svg';
import handshakeImage from '@/assets/svg/handshake.svg';
export default {
  name: 'OurAdvanteage',
  data: () => ({
    cheapFlightImage: cheapFlightImage,
    earPhoneImage: earPhoneImage,
    lovingPeopleImage: lovingPeopleImage,
    handshakeImage: handshakeImage,
  }),
  methods: {
    getLocaleMsg(key) {
      return this.$getLocaleMsg(`pages.Home.${key}`);
    },
  },
};
</script>
<style></style>
