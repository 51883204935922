<template>
  <div
    :class="[
      'layout__footer',
      layoutTheme === 2 || layoutTheme === 6
        ? 'footer'
        : layoutTheme === 6 || layoutTheme === 7
        ? 'footer-color px-[15px] pt-[59px] sm:px-[50px] md:px-[100px]'
        : 'footer',
    ]"
  >
    <div class="grid-container">
      <div class="footer__main">
        <div class="footer__row max-sm:!grid max-sm:!grid-cols-2">
          <div v-for="(navItem, navItemKey) in nav" :key="navItemKey" class="footer__col footer__col_type_nav">
            <div class="footer-nav">
              <div class="footer-nav__title">
                {{ getLocaleMsg(`nav.${navItemKey}.title`) }}
              </div>
              <ul class="footer-nav__list">
                <li v-for="linkName in navItem" :key="linkName" class="footer-nav__item">
                  <router-link
                    :to="{
                      name: linkName,
                      params: { lang: $i18n.locale },
                    }"
                    class="footer-nav__link"
                  >
                    {{ getLocaleMsg(`nav.${navItemKey}.links.${linkName}`) }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="footer__col footer__col_type_contacts">
            <div class="footer-contacts">
              <div class="footer-contacts__title">
                {{ getLocaleMsg('contacts.title') }}
              </div>
              <ul class="footer-contacts__list">
                <li
                  v-for="(contact, key) in contacts"
                  :key="key"
                  :class="['footer-contacts__contact', 'footer-contact', 'footer-contact_type_contact', 'underline']"
                >
                  <span class="footer-contact__icon" v-html="svgIcons.get(contact.icon)" />
                  <a :href="contact.url" class="footer-contact__link">{{ contact.title }}</a>
                </li>
                <li class="footer-contacts__contact footer-contact footer-contact_type_link">
                  <router-link
                    :to="{
                      name: 'contacts',
                      params: { lang: $i18n.locale },
                    }"
                    class="footer-contact__link"
                  >
                    {{ getLocaleMsg('contacts.offices') }}
                  </router-link>
                  <span class="footer-contact__icon" v-html="svgIcons.get('arrow-back')" />
                </li>
                <li class="footer-contacts__contact footer-contact footer-contact_type_link">
                  <router-link
                    :to="{
                      name: 'businessProfile',
                      params: { lang: $i18n.locale },
                    }"
                    class="footer-contact__link"
                  >
                    {{ getLocaleMsg('contacts.business') }}
                  </router-link>
                  <span class="footer-contact__icon" v-html="svgIcons.get('arrow-back')" />
                </li>
              </ul>
            </div>
          </div>
          <div class="footer__col footer__col_type_nav">
            <div class="footer-nav">
              <div class="footer-nav__title">
                {{ getLocaleMsg('details.title') }}
              </div>
              <ul class="footer-nav__list">
                <li class="footer-nav__item">
                  <span class="text-[#36454F]">
                    {{ getLocaleMsg('details.paymentMethods') }}
                  </span>
                </li>
                <li class="footer-nav__item">
                  <ul class="flex">
                    <li v-for="(paymentMethod, key) in paymentMethods" :key="key" class="footer-payment-methods__item">
                      <img
                        v-lazy="paymentMethod.img.src"
                        :width="paymentMethod.img.width"
                        :alt="paymentMethod.title"
                        :class="[
                          'footer-payment-methods__img',
                          `footer-payment-methods__img_type_${paymentMethod.type}`,
                        ]"
                      />
                    </li>
                  </ul>
                </li>
                <li class="footer-nav__item">
                  <span class="text-[#36454F]">
                    {{ getLocaleMsg('details.socialNetworks') }}
                  </span>
                </li>
                <li class="footer-nav__item">
                  <ul class="flex">
                    <li v-for="(socialNetwork, key) in socialNetworks" :key="key" class="footer-social-networks__item">
                      <a
                        :href="socialNetwork.url"
                        class="footer-social-networks__link"
                        target="_blank"
                        v-html="svgIcons.get(socialNetwork.icon, { className: 'footer-social-networks__icon' })"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="footer__secondary">
        <div class="footer__iata">
          <a
            href="/static/files/iata_certificate.pdf"
            class="footer__iata-link"
            title="IATA - International Air Transport Association"
            target="_blank"
            v-html="svgIcons.get('iata')"
          />
        </div>
        <div class="footer__copyright">
          {{ getLocaleMsg('copyright', { values: { year: getCurrentYear() } }) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uzcard from '@/assets/svg/uzcard.svg';
import humo from '@/assets/images/humo.png';
import visa from '@/assets/svg/card-visa.svg';
import mastercard from '@/assets/svg/card-mastercard.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'SiteFooter',

  data: () => ({
    nav: {
      company: ['about', 'corporate', 'travelAgent', 'vacancies', 'contacts'],
      user: ['help', 'rules', 'privacyPolicy', 'tourism', 'contactCenter'],
    },
    contacts: [
      {
        icon: 'phone',
        url: 'tel:+998781404444',
        title: '+998 78 140 44 44',
      },
      {
        icon: 'envelope',
        url: 'mailto:info@alltrip.uz',
        title: 'info@alltrip.uz',
      },
    ],
    socialNetworks: [
      {
        icon: 'telegram',
        url: 'https://t.me/AllTrip',
      },
      {
        icon: 'facebook',
        url: 'https://www.facebook.com/alltrip.uz',
      },
      {
        icon: 'instagram',
        url: 'https://www.instagram.com/alltrip.uz/',
      },
    ],
    paymentMethods: [
      {
        title: 'HUMO',
        type: 'humo',
        img: {
          src: humo,
          width: 64,
        },
      },
      {
        title: 'Mastercard',
        type: 'mastercard',
        img: {
          src: mastercard,
          width: 36,
        },
      },
      {
        title: 'Visa',
        type: 'visa',
        img: {
          src: visa,
          width: 48,
        },
      },
      {
        title: 'UzCard',
        type: 'uzcard',
        img: {
          src: uzcard,
          width: 97,
        },
      },
    ],
  }),
  computed: {
    ...mapGetters(['layoutTheme']),
  },
  methods: {
    getLocaleMsg(key, data) {
      return this.$getLocaleMsg(`components.${this.$options.name}.${key}`, data);
    },
    getCurrentYear() {
      const currentDate = new Date();

      return currentDate.getFullYear();
    },
  },
};
</script>
