var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h2", { staticClass: "our-advantage__title max-sm:mt-8" }, [
      _vm._v("\n    " + _vm._s(_vm.getLocaleMsg("advantages.title")) + "\n  "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "our-advantage__grid" }, [
      _c("div", { staticClass: "our-advantage__card" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value: _vm.cheapFlightImage,
              expression: "cheapFlightImage",
            },
          ],
          attrs: { alt: "Cheap Flight Image" },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "our-advantage__card-text" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.getLocaleMsg("advantages.text1")) +
              "\n      "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "our-advantage__card" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value: _vm.earPhoneImage,
              expression: "earPhoneImage",
            },
          ],
          attrs: { alt: "Earphone image" },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "our-advantage__card-text" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.getLocaleMsg("advantages.text2")) +
              "\n      "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "our-advantage__card" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value: _vm.lovingPeopleImage,
              expression: "lovingPeopleImage",
            },
          ],
          attrs: { alt: "Loving People Image" },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "our-advantage__card-text" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.getLocaleMsg("advantages.text3")) +
              "\n      "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "our-advantage__card" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value: _vm.handshakeImage,
              expression: "handshakeImage",
            },
          ],
          attrs: { alt: "Handshake Image" },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "our-advantage__card-text" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.getLocaleMsg("advantages.text4")) +
              "\n      "
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }