<template>
  <transition name="asd__fade">
    <div
      :id="wrapperId"
      :ref="wrapperId"
      v-click-outside="handleClickOutside"
      :class="[
        'datepicker',
        `datepicker_mode_${mode}`,
        'asd__wrapper',
        `asd__wrapper--datepicker-${mode}`,
        wrapperClasses,
      ]"
      :style="wrapperStyles"
    >
      <div class="asd__datepicker-header">
        <div v-if="!singleMode" class="datepicker__section datepicker__section_type_select-mode">
          <div class="flex w-full space-x-2 bg-white px-6 py-4">
            <div class="flex max-w-[366px] space-x-2">
              <button
                v-for="(text, type) in modeSwitches"
                :key="type"
                :class="[
                  'datepicker-button rounded-xl',
                  'datepicker-button_type_select-mode',
                  {
                    'datepicker-button_state_active': mode === type,
                  },
                ]"
                type="button"
                @click="changeMode(type)"
                v-html="getModeSwitchHtml(type)"
              />
            </div>
          </div>
        </div>
        <div class="asd__datepicker-header-section asd__datepicker-header-section--months-nav relative max-w-[336px]">
          <button
            class="asd__change-month-button asd__change-month-button_type_previous"
            type="button"
            @click="previousMonth"
          >
            <span class="asd__change-month-button-icon asd__change-month-button-icon_prev">Prev</span>
          </button>
          <button class="asd__change-month-button asd__change-month-button_type_next" type="button" @click="nextMonth">
            <span class="asd__change-month-button-icon asd__change-month-button-icon_next">Next</span>
          </button>

          <div
            v-for="(month, index) in showMonths"
            :key="month"
            :style="[
              monthWidthStyles,
              {
                left: `${width * index}px`,
              },
            ]"
            class="asd__days-legend"
          >
            <div
              v-for="(day, dayIndex) in daysShort"
              :key="day"
              :class="[
                'asd__day-title',
                {
                  'asd__day-title_type_weekend': dayIndex > 4,
                },
              ]"
            >
              {{ day }}
            </div>
          </div>
        </div>
      </div>

      <div :style="innerStyles" class="asd__inner-wrapper">
        <transition-group name="asd__list-complete" tag="div">
          <div
            v-for="(month, monthIndex) in months"
            :key="month.firstDateOfMonth"
            :class="[
              'asd__month',
              {
                hidden: monthIndex === 0 || monthIndex > showMonths,
              },
              singleMode ? '!pt-[30px]' : '',
            ]"
            :style="monthWidthStyles"
          >
            <div class="asd__month-name">{{ monthNames[month.monthNameKey] }} {{ month.year }}</div>

            <table class="asd__month-table" role="presentation">
              <tbody>
                <tr v-for="(week, weekIndex) in month.weeks" :key="weekIndex" class="asd__week">
                  <td
                    v-for="({ fullDate, dayNumber }, dayIndex) in week"
                    :key="`day-${dayIndex}-${dayNumber}`"
                    :data-date="fullDate"
                    :class="[
                      'datepicker__day',
                      'datepicker-day',
                      {
                        'datepicker-day_is_weekend': dayNumber !== 0 && dayIndex > 4,
                        'datepicker-day_is_today': isToday(parseISO(fullDate)),
                        'datepicker-day_is_empty': dayNumber === 0,
                        'datepicker-day_range_start': isRangeStart(fullDate),
                        'datepicker-day_range_part': isInRange(fullDate),
                        'datepicker-day_range_end': isRangeEnd(fullDate),
                        'datepicker-day_state_enabled': dayNumber !== 0,
                        'datepicker-day_state_disabled': isDisabled(fullDate),
                        'datepicker-day_state_selected': selectedDate1 === fullDate || selectedDate2 === fullDate,
                      },
                    ]"
                    :style="getDayStyles()"
                    @mouseover="
                      () => {
                        if ((selectedDate1 && !selectedDate2) || (!selectedDate1 && selectedDate2)) {
                          setHoverDate(fullDate);
                        }
                      }
                    "
                    @mouseout="
                      () => {
                        setHoverDate(null);
                      }
                    "
                  >
                    <button
                      v-if="dayNumber"
                      :date="fullDate"
                      :disabled="isDisabled(fullDate)"
                      class="datepicker-day__button"
                      type="button"
                      @click="
                        () => {
                          selectDate(fullDate);
                        }
                      "
                    >
                      {{ dayNumber }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </transition-group>
      </div>
    </div>
  </transition>
</template>

<script>
import { subMonths, addMonths, getDaysInMonth, isBefore, isAfter, isValid, isToday, parseISO } from 'date-fns';

import { debounce, findAncestor, randomString, formatDate } from '@/utils';

export default {
  name: 'RangeDatePicker',

  props: {
    triggerElementId: {
      type: String,
      default: null,
    },
    dateOne: {
      type: [String, Date],
      default: formatDate(new Date(), 'yyyy-MM-dd'),
    },
    dateTwo: {
      type: [String, Date],
      default: '',
    },
    minDate: {
      type: [String, Date],
      default: '',
    },
    endDate: {
      type: [String, Date],
      default: '',
    },
    offsetY: {
      type: Number,
      default: 0,
    },
    offsetX: {
      type: Number,
      default: 0,
    },
    startOpen: {
      type: Boolean,
    },
    disabledDates: {
      type: Array,
      default: () => [],
    },
    trigger: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'range',
    },
    singleMode: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isToday,
    parseISO,
    wrapperId: `range-datepicker-${randomString(5)}`,
    monthsToShow: 2,
    dateFormat: 'yyyy-MM-dd',
    showDatepicker: false,
    showMonths: 2,
    startingDate: '',
    months: [],
    width: 366,
    selectedDate1: '',
    selectedDate2: '',
    isSelectingDate1: true,
    hoverDate: '',
    alignRight: false,
    triggerPosition: {},
    triggerWrapperPosition: {},
    viewportWidth: `${window.innerWidth}px`,
    isMobile: window.innerWidth < 1024,
    triggerElement: undefined,
  }),

  computed: {
    monthNames() {
      return this.getLocaleMsg('monthNames');
    },
    daysShort() {
      return this.getLocaleMsg('daysShort');
    },
    modeSwitches() {
      return this.getLocaleMsg('modeSwitches');
    },
    wrapperClasses() {
      return {
        'asd__wrapper--datepicker-open': this.showDatepicker,
      };
    },
    wrapperStyles() {
      return {
        position: 'absolute',
        top: `${this.triggerPosition.height + this.offsetY}px`,
        left: !this.alignRight
          ? `${this.triggerPosition.left - this.triggerWrapperPosition.left + this.offsetX}px`
          : '',
        right: this.alignRight
          ? `${this.triggerWrapperPosition.right - this.triggerPosition.right + this.offsetX}px`
          : '',
        width: `${this.width * this.showMonths}px`,
        zIndex: '15',
      };
    },
    innerStyles() {
      return {
        'margin-left': `-${this.width}px`,
      };
    },
    headerWidthStyles() {
      return {
        width: `${this.width}px`,
      };
    },
    monthWidthStyles() {
      return {
        width: `${this.width - 10}px`,
      };
    },
    datesSelected() {
      return !!((this.selectedDate1 && this.selectedDate1 !== '') || (this.selectedDate2 && this.selectedDate2 !== ''));
    },
    allDatesSelected() {
      return !!(this.selectedDate1 && this.selectedDate1 !== '' && this.selectedDate2 && this.selectedDate2 !== '');
    },
    hasMinDate() {
      return !!(this.minDate && this.minDate !== '');
    },
    isRangeMode() {
      return this.mode === 'range';
    },
    isSingleMode() {
      return this.mode === 'single';
    },
    datepickerWidth() {
      return this.width * this.showMonths;
    },
    datePropsCompound() {
      return this.dateOne + this.dateTwo;
    },
    isDateTwoBeforeDateOne() {
      if (!this.dateTwo) return false;

      return isBefore(parseISO(this.dateTwo), parseISO(this.dateOne));
    },
    visibleMonths() {
      const firstMonthArray = this.months.filter((m, index) => index > 0);
      const numberOfMonthsArray = [];

      for (let i = 0; i < this.showMonths; i++) {
        numberOfMonthsArray.push(i);
      }

      return numberOfMonthsArray.map((_, index) => firstMonthArray[index].firstDateOfMonth);
    },
  },

  watch: {
    mode(value) {
      this.setStartDates();

      if (value === 'single') {
        this.monthsToShow = 1;

        if (this.showDatepicker) {
          this.closeDatepicker();
          this.openDatepicker();
        }

        this.selectedDate2 = '';
      } else {
        this.monthsToShow = 2;

        if (this.selectedDate1) {
          this.isSelectingDate1 = false;
        }

        if (this.showDatepicker) {
          this.closeDatepicker();
          this.openDatepicker();
        }
      }
    },
    singleMode(value) {
      value ? this.$emit('mode-changed', 'single') : this.$emit('mode-changed', this.dateTwo ? 'range' : 'single');
    },
    selectedDate1(value) {
      const newDate = !value || value === '' ? '' : formatDate(value, this.dateFormat);

      this.$emit('date-one-selected', newDate);
    },
    selectedDate2(value) {
      const newDate = !value || value === '' ? '' : formatDate(value, this.dateFormat);

      this.$emit('date-two-selected', newDate);
    },
    datePropsCompound() {
      if (this.dateOne !== this.selectedDate1) {
        this.startingDate = this.dateOne;

        this.setStartDates();
        this.generateMonths();
      }

      if (this.isDateTwoBeforeDateOne) {
        this.selectedDate2 = '';

        this.$emit('date-two-selected', '');
      }
    },
    trigger(value) {
      if (value) this.openDatepicker();
    },
  },

  created() {
    this._handleWindowResizeEvent = debounce(() => {
      this.positionDatepicker();
      this.setStartDates();
    }, 200);

    this._handleWindowClickEvent = (event) => {
      if (event.target.id === this.triggerElementId) {
        event.stopPropagation();
        event.preventDefault();

        this.toggleDatepicker();
      }
    };

    window.addEventListener('resize', this._handleWindowResizeEvent);
    window.addEventListener('click', this._handleWindowClickEvent);
  },

  mounted() {
    this.triggerElement = document.getElementById(this.triggerElementId);

    this.setStartDates();
    this.generateMonths();

    if (this.mode === 'single' || this.singleMode) {
      this.monthsToShow = 1;

      this.selectedDate2 = '';
    } else {
      this.monthsToShow = 2;
    }

    if (this.startOpen) this.openDatepicker();

    this.triggerElement.addEventListener('keyup', this.handleTriggerInput);

    if (this.singleMode) {
      this.$emit('mode-changed', 'single');
    }
  },

  destroyed() {
    window.removeEventListener('resize', this._handleWindowResizeEvent);
    window.removeEventListener('click', this._handleWindowClickEvent);

    this.triggerElement.removeEventListener('keyup', this.handleTriggerInput);
  },

  methods: {
    getLocaleMsg(key) {
      return this.$getLocaleMsg(`components.${this.$options.name}.${key}`);
    },
    changeMode(mode) {
      this.$emit('mode-changed', mode);
    },
    getModeSwitchHtml(type) {
      const text = `<span class="datepicker-button__text">${this.modeSwitches[type]}</span>`;
      const iconKey = type === 'range' ? 'arrow-directions' : 'arrow-direction';
      // const icon = this.svgIcons.get(iconKey, { className: 'datepicker-button__icon' })

      return `${text}`;
    },
    getDayStyles() {
      const cellSize = `48px`;

      const styles = {
        width: cellSize,
        height: cellSize,
      };

      return styles;
    },
    handleClickOutside(event) {
      if (event.target.id === this.triggerElementId || !this.showDatepicker) {
        return;
      }
      this.closeDatepicker();
    },
    handleTriggerInput(event) {
      const keys = {
        arrowDown: 40,
        arrowUp: 38,
        arrowRight: 39,
        arrowLeft: 37,
      };

      if (event.keyCode === keys.arrowDown && !event.shiftKey && !this.showDatepicker) {
        this.openDatepicker();
      } else if (event.keyCode === keys.arrowUp && !event.shiftKey && this.showDatepicker) {
        this.closeDatepicker();
      } else if (event.keyCode === keys.arrowRight && !event.shiftKey && this.showDatepicker) {
        this.nextMonth();
      } else if (event.keyCode === keys.arrowLeft && !event.shiftKey && this.showDatepicker) {
        this.previousMonth();
      } else {
        if (this.mode === 'single') {
          this.setDateFromText(event.target.value);
        }
      }
    },
    setDateFromText(value) {
      if (value.length < 10) return;

      const isFormatYearFirst = value.match(/^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[0-1])$/);
      const isFormatDayFirst = value.match(/^(0[1-9]|1[0-9]|2[0-9]|3[0-1])[.](0[1-9]|1[0-2])[.](\d{4})$/);

      if (!isFormatYearFirst && !isFormatDayFirst) return;

      if (isFormatDayFirst) {
        value = `${value.substring(6, 10)}-${value.substring(3, 5)}-${value.substring(0, 2)}`;
      }

      const valueAsDateObject = new Date(value);

      if (!isValid(valueAsDateObject)) return;

      const formattedDate = formatDate(valueAsDateObject, this.dateFormat);

      if (
        this.isDateDisabled(formattedDate) ||
        this.isBeforeMinDate(formattedDate) ||
        this.isAfterEndDate(formattedDate)
      ) {
        return;
      }

      this.startingDate = subMonths(parseISO(formattedDate), 1);

      this.generateMonths();
      this.selectDate(formattedDate);
    },
    generateMonths() {
      this.months = [];

      for (let i = 0; i < this.showMonths + 2; i++) {
        this.months.push(this.getMonth(this.startingDate));

        this.startingDate = this.addMonths(this.startingDate);
      }
    },
    setStartDates() {
      const startDate = this.dateOne || formatDate(new Date(), this.dateFormat);

      this.startingDate = this.subtractMonths(
        this.hasMinDate && isBefore(parseISO(startDate), parseISO(this.minDate)) ? this.minDate : startDate,
      );

      this.selectedDate1 = this.dateOne;
      this.selectedDate2 = this.dateTwo;
    },
    getMonth(date) {
      const year = formatDate(date, 'yyyy');
      const monthNameKeys = Object.keys(this.monthNames);
      const firstDateOfMonth = formatDate(date, 'yyyy-MM-01');
      const monthNumber = parseInt(formatDate(date, 'M'));
      const monthNameKey = monthNameKeys[monthNumber - 1];

      return {
        year,
        firstDateOfMonth,
        monthNameKey,
        monthNumber,
        weeks: this.getWeeks(firstDateOfMonth),
      };
    },
    getWeeks(date) {
      const weekDayNotInMonth = { dayNumber: 0 };
      const daysInMonth = getDaysInMonth(parseISO(date));
      const year = formatDate(date, 'yyyy');
      const month = formatDate(date, 'MM');

      let weeks = [];
      let week = [];

      for (let s = 1; s < this.getDayOfWeek(date); s++) {
        week.push(weekDayNotInMonth);
      }

      for (let d = 0; d < daysInMonth; d++) {
        let isLastDayInMonth = d >= daysInMonth - 1;
        let dayNumber = d + 1;
        let dayNumberFull = dayNumber < 10 ? '0' + dayNumber : dayNumber;

        week.push({
          dayNumber,
          dayNumberFull: dayNumberFull,
          fullDate: year + '-' + month + '-' + dayNumberFull,
        });

        if (week.length === 7) {
          weeks.push(week);
          week = [];
        } else if (isLastDayInMonth) {
          for (let i = 0; i < 7 - week.length; i++) {
            week.push(weekDayNotInMonth);
          }

          weeks.push(week);
          week = [];
        }
      }

      return weeks;
    },
    getDayOfWeek(date) {
      const [year, month, day] = date.split('-').map((item) => +item);
      const newDate = new Date(year, month - 1, day);
      const startDay = newDate.getDay();

      if (startDay === 0) {
        // 7, чтобы сделать счет дней недели с 1 до 7, вместо с 0 до 6
        return 7;
      }

      return startDay;
    },
    selectDate(date) {
      this.selectedDate2 = '';

      if (this.isBeforeMinDate(date) || this.isAfterEndDate(date) || this.isDateDisabled(date)) {
        return;
      }

      if (this.mode === 'single') {
        this.selectedDate1 = date;

        this.closeDatepicker();

        this.$emit('date-selected');

        return;
      }

      if (this.isSelectingDate1 || isBefore(parseISO(date), parseISO(this.selectedDate1))) {
        this.selectedDate1 = date;
        this.isSelectingDate1 = false;

        if (isBefore(parseISO(this.selectedDate2), parseISO(date))) {
          this.selectedDate2 = '';
        }
      } else {
        this.selectedDate2 = date;
        this.isSelectingDate1 = true;

        if (isAfter(parseISO(this.selectedDate1), parseISO(date))) {
          this.selectedDate1 = '';
        } else {
          this.$emit('date-selected');
          this.closeDatepicker();
        }
      }
    },
    setHoverDate(date) {
      this.hoverDate = date;
    },
    isSelected(date) {
      if (!date) return;

      return this.selectedDate1 === date || this.selectedDate2 === date;
    },
    isInRange(date) {
      if (this.isSingleMode) return false;

      return (
        (isAfter(parseISO(date), parseISO(this.selectedDate1)) &&
          isBefore(parseISO(date), parseISO(this.selectedDate2))) ||
        (isAfter(parseISO(date), parseISO(this.selectedDate1)) && isBefore(parseISO(date), parseISO(this.hoverDate)))
      );
    },
    isRangeStart(date) {
      return (
        this.isRangeMode &&
        this.selectedDate1 === date &&
        (this.selectedDate2 || !isBefore(parseISO(this.hoverDate), parseISO(this.selectedDate1)))
      );
    },
    isRangeEnd(date) {
      return (
        this.isRangeMode &&
        this.selectedDate1 &&
        (this.selectedDate2 === date ||
          (this.hoverDate === date && !isBefore(parseISO(this.hoverDate), parseISO(this.selectedDate1))))
      );
    },
    isBeforeMinDate(date) {
      if (!this.minDate) return false;

      return isBefore(parseISO(date), parseISO(this.minDate));
    },
    isAfterEndDate(date) {
      if (!this.endDate) return false;

      return isAfter(parseISO(date), parseISO(this.endDate));
    },
    isDateDisabled(date) {
      const isDisabled = this.disabledDates.indexOf(date) > -1;

      return isDisabled;
    },
    isDisabled(date) {
      return this.isDateDisabled(date) || this.isBeforeMinDate(date) || this.isAfterEndDate(date);
    },
    previousMonth() {
      this.startingDate = this.subtractMonths(this.months[0].firstDateOfMonth);

      this.months.unshift(this.getMonth(this.startingDate));
      this.months.splice(this.months.length - 1, 1);

      this.$emit('previous-month', this.visibleMonths);
    },
    nextMonth() {
      this.startingDate = this.addMonths(this.months[this.months.length - 1].firstDateOfMonth);

      this.months.push(this.getMonth(this.startingDate));

      setTimeout(() => {
        this.months.splice(0, 1);

        this.$emit('next-month', this.visibleMonths);
      }, 100);
    },
    subtractMonths(date) {
      return formatDate(subMonths(parseISO(date), 1), this.dateFormat);
    },
    addMonths(date) {
      return formatDate(addMonths(parseISO(date), 1), this.dateFormat);
    },
    toggleDatepicker() {
      this.showDatepicker ? this.closeDatepicker() : this.openDatepicker();
    },
    openDatepicker() {
      this.positionDatepicker();

      this.setStartDates();

      this.triggerElement.classList.add('datepicker-open');

      this.showDatepicker = true;

      this.initialDate1 = this.dateOne;
      this.initialDate2 = this.dateTwo;

      this.$emit('opened');
    },
    closeDatepicker() {
      this.showDatepicker = false;
      this.triggerElement.classList.remove('datepicker-open');

      this.$emit('closed');
    },
    positionDatepicker() {
      const triggerWrapperElement = findAncestor(this.triggerElement, '.datepicker-trigger');

      this.triggerPosition = this.triggerElement.getBoundingClientRect();

      if (triggerWrapperElement) {
        this.triggerWrapperPosition = triggerWrapperElement.getBoundingClientRect();
      } else {
        this.triggerWrapperPosition = { left: 0, right: 0 };
      }

      const viewportWidth = document.documentElement.clientWidth || window.innerWidth;

      this.viewportWidth = `${viewportWidth}px`;
      this.isMobile = viewportWidth < 1024;
      this.showMonths = this.isMobile ? 1 : this.monthsToShow;

      this.$nextTick(function () {
        const datepickerWrapper = document.getElementById(this.wrapperId);

        if (!this.triggerElement || !datepickerWrapper) return;

        const rightPosition =
          this.triggerElement.getBoundingClientRect().left + datepickerWrapper.getBoundingClientRect().width;

        this.alignRight = rightPosition > viewportWidth;
      });
    },
  },
};
</script>
