var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "form-field",
        "form-field_type_checkbox",
        `form-field_type_checkbox_theme_${_vm.layoutTheme}`,
        `form-field_theme_${_vm.theme}`,
        {
          "form-field_state_invalid": !_vm.isValid,
        },
      ],
    },
    [
      _c("label", { staticClass: "form-field__input-wrapper" }, [
        _c("input", {
          staticClass: "form-field__input",
          attrs: { type: "checkbox" },
          domProps: { checked: _vm.isChecked, value: _vm.value },
          on: { change: _vm.onChange },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "form-field__box" }),
        _vm._v(" "),
        _c("div", {
          staticClass: "form-field__label",
          domProps: { innerHTML: _vm._s(_vm.label) },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }