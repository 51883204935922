export default {
  state: {
    sitePreloader: {
      status: null,
      options: {},
    },
  },

  getters: {
    sitePreloader: (state) => state.sitePreloader,
  },

  actions: {
    setSitePreloaderData: ({ commit }, data) => {
      const timer = ['hide', 'close'].includes(data.status) ? 100 : 0;

      setTimeout(() => commit('setSitePreloaderData', data), timer);
    },
  },

  mutations: {
    setSitePreloaderData: (state, data) => (state.sitePreloader = Object.assign({}, state.sitePreloader, data)),
  },
};
