var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "form-field",
        "form-field_type_range-date !w-full",
        {
          "form-field_label-position_top": _vm.labelPositionIsTop,
          "form-field_state_focus": _vm.fieldIsActive,
          "form-field_state_invalid": _vm.fieldIsInvalid,
          "form-field_force-mode_single": _vm.singleMode,
        },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "form-field__inner datepicker-trigger" },
        [
          _c("div", { staticClass: "form-field__input-wrapper" }, [
            _c("input", {
              ref: "fieldInput",
              staticClass:
                "form-field__input pad-calculate !pl-2 placeholder:!text-[#A5ABB0] max-md:!pl-5",
              attrs: {
                id: _vm.fieldId,
                type: "text",
                placeholder: _vm.label,
                readonly: "",
              },
              domProps: { value: _vm.formatDates(_vm.dates) },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "form-field__message form-field__message_type_error",
              },
              [_vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n      ")]
            ),
          ]),
          _vm._v(" "),
          _c("range-date-picker", {
            staticClass:
              "form-field__datepicker datepicker_theme_form-date-field mt-2",
            attrs: {
              mode: _vm.mode,
              "single-mode": _vm.singleMode,
              "trigger-element-id": _vm.fieldId,
              "date-one": _vm.dates.one,
              "date-two": _vm.dates.two,
              "min-date": _vm.minDate,
              "end-date": _vm.maxDate,
            },
            on: {
              "mode-changed": _vm.onChangeMode,
              "date-one-selected": _vm.selectDateOne,
              "date-two-selected": _vm.selectDateTwo,
              "date-selected": _vm.onSelecteDate,
              opened: _vm.onOpenDatePicker,
              closed: _vm.onCloseDatePicker,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }