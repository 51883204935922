import CurrencyUtil from '@/utils/currency';
import { formatCurrencyPrice } from '@/utils';

export default {
  install: (Vue) => {
    Vue.filter('convertCurrency', (value, currencyFrom = 'UZS', currencyTo = null) => {
      currencyTo = currencyTo || CurrencyUtil.getCurrent();
      let converted = CurrencyUtil.convertFromTo(currencyFrom, currencyTo, value);

      return formatCurrencyPrice(converted, currencyTo);
    });
  },
};
