<template>
  <div
    v-if="false"
    v-click-outside="hideDropdown"
    :class="[
      'currency-select',
      {
        'currency-select_dropdown-visibility_visible': dropdownIsVisible,
      },
    ]"
  >
    <button
      :class="['currency-select__button inline-flex items-center', `currency-select__button_theme_2`]"
      type="button"
      @click="toggleDropdown"
    >
      <span>{{ currentCurrency }}</span>
      <span class="ml-[11px]" v-html="svgIcons.get('dropdown_arrow')" />
    </button>
    <div class="currency-select__dropdown">
      <ul v-if="currenciesList && currenciesList.length" :class="['currency-select__list']">
        <li
          v-for="(currency, currencyKey) in currenciesList"
          :key="currencyKey"
          :class="[
            'list-item',
            'list-item_theme_currency-select',
            {
              'list-item_state_active': currency.code === currentCurrency,
            },
          ]"
          @click="onSelect(currency.code)"
        >
          <span class="list-item__code">{{ currency.code }}</span>
          <span class="list-item__title">{{ currency.title }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CurrencySelect',

  data: () => ({
    dropdownIsVisible: false,
  }),

  computed: {
    ...mapGetters(['currentCurrency', 'currenciesList', 'layoutTheme']),
  },

  methods: {
    ...mapActions(['setCurrentCurrency']),
    hideDropdown() {
      this.dropdownIsVisible = false;
    },
    toggleDropdown() {
      this.dropdownIsVisible = !this.dropdownIsVisible;
    },
    onSelect(value) {
      this.setCurrentCurrency(value);

      this.hideDropdown();
    },
  },
};
</script>
