var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { class: ["layout", `layout_theme_${_vm.layoutTheme}`] },
      [
        _c("SiteHeader"),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.stylePages },
          [
            _c(
              "keep-alive",
              [_c("router-view", { staticClass: "layout__main-content" })],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("SiteFooter", {
          class: _vm.$route.name === "notFound" ? "hidden" : "",
        }),
        _vm._v(" "),
        _c("SitePreloaders"),
        _vm._v(" "),
        _c("SitePopups"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }