import CurrencyUtil from '@/utils/currency';
import ApiUtil from '@/utils/api';

export default {
  state: {
    currenciesList: [
      {
        code: 'USD',
        title: 'Доллары США',
      },
      {
        code: 'RUB',
        title: 'Российские рубли',
      },
      {
        code: 'UZS',
        title: 'Узбекские сумы',
      },
    ],
    currentCurrency: CurrencyUtil.getCurrent(),
  },

  getters: {
    currenciesList: (state) => state.currenciesList,
    currentCurrency: (state) => state.currentCurrency,
  },

  actions: {
    initCurrencyRates: () => {
      ApiUtil.get('/currencies').then((response) => {
        let rates = {};

        response.data.currencies.forEach((currency) => {
          rates[currency.currency] = currency.rate;
        });

        CurrencyUtil.setRates(rates);
      });
    },
    setCurrentCurrency: ({ commit }, value) => {
      CurrencyUtil.setCurrent(value);
      ApiUtil.setHeaderClientCurrency();

      commit('setCurrentCurrency', value);
    },
  },

  mutations: {
    setCurrentCurrency: (state, value) => (state.currentCurrency = value),
  },
};
