<template>
  <div class="mobile__alltrip-container">
    <div class="mobile__alltrip-block">
      <div class="mobile__alltrip-block-left">
        <h2 class="mobile__alltrip-block-left-title">
          {{ getLocaleMsg('mobile.title') }}
        </h2>
        <p class="mobile__alltrip-block-left-text">
          {{ getLocaleMsg('mobile.text') }}
        </p>
        <div class="mobile__alltrip-block-left-app">
          <a href="https://apps.apple.com/us/app/alltrip/id6451256196" target="_blank">
            <img v-lazy="appStoreImage" alt="alltrip mobile app-ios" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=uz.tt25.all_trip_b2c" target="_blank">
            <img v-lazy="googleStoreImage" alt="alltrip mobile app-android" />
          </a>
        </div>
      </div>
      <div class="mobile__alltrip-block-right">
        <img v-lazy="mobileAppImage" alt="Alltrip Mobile App" class="mobile__alltrip-block-right-img" />
      </div>
    </div>
  </div>
</template>
<script>
import appStoreImage from '@/assets/images/appstore.png';
import googleStoreImage from '@/assets/images/googleplay.png';
import mobileAppImage from '@/assets/images/mobile-app-alltrip.png';
export default {
  name: 'AlltripMobile',
  data: () => ({
    appStoreImage: appStoreImage,
    googleStoreImage: googleStoreImage,
    mobileAppImage: mobileAppImage,
  }),
  methods: {
    getLocaleMsg(key) {
      return this.$getLocaleMsg(`pages.Home.${key}`);
    },
  },
};
</script>
<style></style>
