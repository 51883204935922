var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "tickets-search-widget",
        _vm.layoutTheme === 2 &&
          " rounded-b-lg rounded-r-lg py-4 md:px-4 lg:px-[24px] lg:py-[48px] xl:px-[31px]",
        `tickets-search-widget_theme_${_vm.theme}`,
      ],
    },
    [
      _c("TicketsSearchForm", {
        attrs: { theme: _vm.theme },
        on: { cancel: _vm.cancelWidget },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }