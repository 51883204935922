import Vue from 'vue';
import i18n from '@/i18n';
import CacheUtil from '@/utils/cache';
import PassengersUtil from '@/utils/passengers';

export default {
  getParams(buyId) {
    const ordersParams = CacheUtil.getDataByKey('ordersParams') || {};

    return ordersParams[buyId] !== undefined ? ordersParams[buyId] : null;
  },
  saveParams(buyId, orderParams) {
    const ordersParams = CacheUtil.getDataByKey('ordersParams') || {};

    if (ordersParams[buyId] === undefined) {
      ordersParams[buyId] = orderParams;

      CacheUtil.setDataByKey('ordersParams', ordersParams);
    }
  },
  deleteParams(buyId) {
    if (!buyId) return;

    const ordersParams = CacheUtil.getDataByKey('ordersParams') || {};

    if (ordersParams[buyId] !== undefined) {
      delete ordersParams[buyId];

      CacheUtil.setDataByKey('ordersParams', ordersParams);
    }
  },
  getStatusText(key) {
    return i18n.t('common.order.status')[key];
  },
  getPaymentStatusText(key) {
    return i18n.t('common.order.payment_status')[key];
  },
  formatBookingData(data) {
    const result = Vue.prototype.$cloneDeep(data, true);

    result.buy_id = localStorage.getItem('buy_id');

    result.phone = {
      code: 'UZ',
      number: result.phone,
    };

    result.passengers.forEach((passenger, passengerIndex) => {
      result.passengers[passengerIndex]['document'] = {
        type: passenger.document_type,
        number: passenger.document_number,
        expire: passenger.document_expire,
        issue: '2012-02-28',
      };
      result.passengers[passengerIndex]['phone_code'] = 'UZ';
      result.passengers[passengerIndex]['phone_number'] = '991234567';
      result.passengers[passengerIndex]['middle_name'] = 'IVANOVICH';

      delete result.passengers[passengerIndex]['document.type'];
      delete result.passengers[passengerIndex]['document.number'];
      delete result.passengers[passengerIndex]['document.expire'];

      delete result.passengers[passengerIndex]['document_type'];
      delete result.passengers[passengerIndex]['document_number'];
      delete result.passengers[passengerIndex]['document_expire'];
      delete result.passengers[passengerIndex]['id'];
      delete result.passengers[passengerIndex]['user_id'];
    });
    delete result.marker;

    return result;
  },
  getPassengersDataBySearchParams(searchParams) {
    const passengers = {};

    PassengersUtil.getTypes().forEach(
      (passengerType) => (passengers[passengerType.key] = searchParams[passengerType.key]),
    );

    return passengers;
  },
  saveConversionId(id) {
    const currentDate = new Date();

    currentDate.setTime(currentDate.getTime() + 30 * 60 * 1000);

    localStorage.setItem('at_order_conversion_id', id);
    localStorage.setItem('at_order_conversion_end', currentDate.toUTCString());
  },
  getConversionId() {
    let endDate = localStorage.getItem('at_order_conversion_end');

    if (endDate === null) {
      return null;
    }

    endDate = new Date(endDate);
    const currentDate = new Date();

    if (currentDate.getTime() >= endDate.getTime()) {
      localStorage.removeItem('at_order_conversion_id');
      localStorage.removeItem('at_order_conversion_end');

      return null;
    }

    return localStorage.getItem('at_order_conversion_id');
  },
};
