import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import page from '@/store/modules/page';
import layout from '@/store/modules/layout';
import preloader from '@/store/modules/preloader';
import language from '@/store/modules/language';
import currency from '@/store/modules/currency';
import user from '@/store/modules/user';
import airports from '@/store/modules/airports';
import countries from '@/store/modules/countries';
import popularDirections from '@/store/modules/popular-directions';
import tickets from '@/store/modules/tickets';
import ticket from '@/store/modules/ticket';
import notebook from '@/store/modules/notebook';
import orders from '@/store/modules/orders';
import order from '@/store/modules/order';
import blog from '@/store/modules/blog';
import paymentCards from '@/store/modules/payment-cards';
import vacancies from '@/store/modules/vacancies';
import payments from '@/store/modules/payments';
import insurance from '@/store/modules/insurance';
import insuranceDictionaries from '@/store/modules/insurance/dictionaries';
import insuranceOrders from '@/store/modules/insurance/orders';

const store = new Vuex.Store({
  state: {
    backendURL: process.env.VUE_APP_BACKEND_BASE_URL,
    erpBackendURL: process.env.VUE_APP_ERP_BACKEND_BASE_URL,
  },

  getters: {
    backendURL: (state) => state.backendURL,
    erpBackendURL: (state) => state.erpBackendURL,
  },

  modules: {
    page,
    layout,
    preloader,
    language,
    currency,
    user,
    tickets,
    ticket,
    airports,
    countries,
    popularDirections,
    notebook,
    orders,
    order,
    blog,
    vacancies,
    paymentCards,
    payments,
    insurance,
    insuranceDictionaries,
    insuranceOrders,
  },
});

export default store;
