import LanguageUtil from '@/utils/language';
import i18n from '@/i18n';
import americanFlag from '../../assets/icons/americanFlag.svg';
import russianFlag from '../../assets/icons/russianFlag.svg';
import uzbekianFlag from '../../assets/icons/uzbekianFlag.svg';

export default {
  state: {
    languages: [
      {
        code: 'UZ',
        shortCode: 'uz',
        title: 'O&rsquo;zbekcha',
        imgUrl: uzbekianFlag,
      },
      {
        code: 'RU',
        shortCode: 'ru',
        title: 'Русский',
        imgUrl: russianFlag,
      },
      {
        code: 'EN',
        shortCode: 'en',
        title: 'English',
        imgUrl: americanFlag,
      },
    ],
    currentLanguage: LanguageUtil.getCurrent(),
  },

  getters: {
    languages: (state) => state.languages,
    currentLanguage: (state) => state.currentLanguage,
  },

  actions: {
    setCurrentLanguage: ({ commit }, payload) => {
      i18n.locale = payload;

      commit('setCurrentLanguage', payload);

      LanguageUtil.setCurrent(payload);
    },
  },

  mutations: {
    setCurrentLanguage: (state, payload) => (state.currentLanguage = payload),
  },
};
