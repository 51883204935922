import { differenceInYears, parseISO } from 'date-fns';
import { formatDate } from '@/utils';
import i18n from '@/i18n';

export default {
  getGenderTypes() {
    const types = {
      M: i18n.t('common.gender.M'),
      F: i18n.t('common.gender.F'),
    };

    return types;
  },
  getGenderTypeText(type) {
    const types = this.getGenderTypes();

    return types[type];
  },
  getTypes() {
    const types = [
      {
        id: 'ADT',
        key: 'count_adult',
        text: i18n.t('common.passenger.ADT'),
        isType: (age) => age > 12,
      },
      {
        id: 'CHD',
        key: 'count_child',
        text: i18n.t('common.passenger.CHD'),
        isType: (age) => age > 2 && age <= 12,
      },
      {
        id: 'INF',
        key: 'count_infant',
        text: i18n.t('common.passenger.INF'),
        isType: (age) => age <= 2,
      },
    ];

    return types;
  },
  getTypeText(type) {
    const types = this.getTypes();
    const typeData = types.find((typeData) => typeData.id === type);

    return typeData ? typeData.text : null;
  },
  getAge(date) {
    return differenceInYears(new Date(), parseISO(formatDate(date)));
  },
};
