import Vue from 'vue';
import VueSanitize from 'vue-sanitize';

const defaultOptions = {
  allowedTags: [
    'a',
    'b',
    'strong',
    'em',
    'img',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'div',
    'ul',
    'ol',
    'li',
    'small',
    'br',
  ],
  allowedAttributes: {
    img: ['src', 'width', 'height', 'alt'],
  },
};

Vue.use(VueSanitize, defaultOptions);
