import Vue from 'vue';

const Toast = {
  configs: {
    success: {
      theme: 'alltrip-success',
      position: 'topCenter',
      timeout: 3500,
    },
    error: {
      theme: 'alltrip-error',
      position: 'topCenter',
      timeout: 3500,
    },
  },
  getConfig(key) {
    return this.configs[key] || {};
  },
  show(data) {
    data.message = data.message !== undefined ? data.message : '';
    data.title = data.title !== undefined ? data.title : '';

    if (data.message === '' && data.title === '') return;

    Vue.prototype.toast.show(data.message, data.title, this.getConfig(data.type));
  },
};

export default Toast;
