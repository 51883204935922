import TokenUtil from '@/utils/token';
import ApiUtil from '@/utils/api';

export default {
  state: {
    userData: null,
    userAccessToken: TokenUtil.getToken(),
    userAuthPopup: { status: null, hideOnClickOutside: false },
    userPasswordRecoveryPopup: { status: null },
    userPasswordResetPopup: { status: null },
    userPasswordResetToken: null,
  },

  getters: {
    userData: (state) => state.userData,
    userEmail: (state) => (state.userData !== null ? state.userData.email : null),
    userIsAuthenticated: (state) => !!state.userAccessToken,
    userAuthPopup: (state) => state.userAuthPopup,
    userPasswordRecoveryPopup: (state) => state.userPasswordRecoveryPopup,
    userPasswordResetPopup: (state) => state.userPasswordResetPopup,
    userPasswordResetToken: (state) => state.userPasswordResetToken,
  },

  mutations: {
    setUserAuthPopupData: (state, data) => (state.userAuthPopup = Object.assign({}, state.userAuthPopup, data)),
    setUserPasswordRecoveryPopupData: (state, data) =>
      (state.userPasswordRecoveryPopup = Object.assign({}, state.userPasswordRecoveryPopup, data)),
    setUserPasswordResetPopupData: (state, data) =>
      (state.userPasswordResetPopup = Object.assign({}, state.userPasswordResetPopup, data)),
    saveUserPasswordResetToken: (state, token) => (state.userPasswordResetToken = token),
    saveUserAccessToken: (state, token) => (state.userAccessToken = token),
    removeUserAccessToken: (state) => (state.userAccessToken = null),
    setUserData: (state, data) => (state.userData = data),
  },

  actions: {
    setUserAuthPopupData: ({ commit }, data) => commit('setUserAuthPopupData', data),
    setUserPasswordRecoveryPopupData: ({ commit }, data) => commit('setUserPasswordRecoveryPopupData', data),
    setUserPasswordResetPopupData: ({ commit }, data) => commit('setUserPasswordResetPopupData', data),
    saveUserPasswordResetToken: ({ commit }, token) => commit('saveUserPasswordResetToken', token),
    saveUserTokens: ({ commit }, token) => {
      TokenUtil.setToken(token);
      ApiUtil.setHeaderAuthToken(token);
      commit('saveUserAccessToken', token);
    },
    confirmUser: ({ dispatch }, { uuid, password }) => {
      return new Promise((resolve, reject) => {
        ApiUtil.put('/registration/' + uuid + '/confirm?password=' + password)
          .then((response) => {
            dispatch('setUserPasswordResetPopupData', { status: null });

            dispatch('loginUser', {
              username: localStorage.getItem('username'),
              password: password,
            });

            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    verifyUser: ({ dispatch }, code) => {
      const uuid = localStorage.getItem('uuid');
      const password = localStorage.getItem('password');
      return new Promise((resolve, reject) => {
        ApiUtil.put('/registration/' + uuid + '/verify?code=' + code)
          .then(() => {
            dispatch('setUserPasswordResetPopupData', { status: null });
            dispatch('confirmUser', { uuid, password });
          })
          .catch((error) => reject(error));
      });
    },
    registerUser: ({ dispatch, commit }, data) => {
      localStorage.setItem('password', data.password);
      localStorage.setItem('username', data.phone);
      return new Promise((resolve, reject) => {
        ApiUtil.post('/registration/init', data)
          .then((response) => {
            const uuid = response.data.data.uuid;
            localStorage.setItem('uuid', uuid);

            dispatch('setUserAuthPopupData', { status: 'close' });
            dispatch('setUserPasswordResetPopupData', { status: 'open' });

            commit('setUserData', { email: data.phone });

            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    loginUser: ({ dispatch, commit }, data) => {
      commit('setUserData', { email: data.username });
      return new Promise((resolve, reject) => {
        ApiUtil.post('/login', data)
          .then((response) => {
            dispatch('saveUserTokens', response.data.data.token);
            dispatch('getNotebookItems', null, { root: true });
            dispatch('setUserAuthPopupData', { status: 'close' });

            dispatch('getUserData').then((response) => {
              dispatch('setUserAuthPopupData', { status: 'close' });

              resolve(response);
            });
          })
          .catch((error) => reject(error));
      });
    },
    logoutUser: ({ commit }) => {
      TokenUtil.removeToken();
      ApiUtil.removeHeaderAuthToken();
      commit('removeUserAccessToken');
    },
    initUserData: ({ dispatch, state }) => {
      if (!!state.userAccessToken && state.userData === null) {
        dispatch('getUserData');
      }
    },
    getUserData: ({ commit }) => {
      return new Promise((resolve, reject) => {
        ApiUtil.get('/users/profile')
          .then((response) => {
            if (response && response.status === 200) {
              commit('setUserData', response.data);
            }

            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    setUserData: ({ commit }, data) => {
      return new Promise((resolve, reject) => {
        ApiUtil.put('/users/profile', data)
          .then((response) => {
            if (response.status === 204) {
              commit('setUserData', data);
            }

            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
