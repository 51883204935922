import Cookies from 'js-cookie';

const TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';

const TokenUtil = {
  getToken() {
    return Cookies.get(TOKEN_KEY);
  },
  setToken(token, options) {
    Cookies.set(TOKEN_KEY, token, { expires: 1, ...options });
  },
  removeToken() {
    Cookies.remove(TOKEN_KEY);
  },

  getRefreshToken() {
    return Cookies.get(REFRESH_TOKEN_KEY);
  },
  setRefreshToken(token, options) {
    Cookies.set(REFRESH_TOKEN_KEY, token, { expires: 1, ...options });
  },
  removeRefreshToken() {
    Cookies.remove(REFRESH_TOKEN_KEY);
  },
};

export default TokenUtil;
