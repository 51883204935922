import ApiUtil from '@/utils/api';

export default {
  getTicketCancelTokenSource: null,

  async getTickets(data) {
    return new Promise((resolve, reject) => {
      ApiUtil.get(`/air/search/results/${data}`)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
};
