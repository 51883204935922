<template>
  <div>
    <SitePreloader v-if="sitePreloader.status" :options="sitePreloader.options" />
    <TicketsSearchPreloader v-else-if="ticketsSearchStatus" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SitePreloaders',

  components: {
    SitePreloader: () => import('@/components/SitePreloader'),
    TicketsSearchPreloader: () => import('@/components/Tickets/SearchWidget/Preloader'),
  },

  computed: {
    ...mapGetters(['sitePreloader', 'ticketsSearchStatus']),
  },
};
</script>
