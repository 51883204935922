var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "fixed bottom-0 z-50 flex h-screen w-full flex-col justify-end",
      class: _vm.isModal ? "bg-[#011043] bg-opacity-50" : "",
    },
    [
      _c("div", { staticClass: "h-2/3 w-full" }, [
        _c(
          "div",
          { staticClass: "fixed bottom-0 w-full rounded-t-2xl bg-white" },
          [
            _c(
              "div",
              {
                staticClass: "relative flex min-w-full flex-col space-y-6 pt-8",
              },
              [
                _c("div", { staticClass: "flex justify-center" }, [
                  _c(
                    "h3",
                    {
                      staticClass:
                        "w-[219px] text-center text-xl font-bold text-[#1D6BC0]",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.getLocaleMsg("title")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex justify-center" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "max-w-[297px] text-center text-sm font-medium text-[#1B2226]",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.getLocaleMsg("text")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex justify-center" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "max-w-[253px] text-center text-sm font-medium text-[#3395FF]",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.getLocaleMsg("link")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "absolute right-2 top-0 cursor-pointer",
                    on: { click: _vm.closeModal },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/svg/close.svg"),
                        alt: "close button",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "relative flex w-full justify-center" },
                  [
                    _c("img", {
                      staticClass: "object-cover",
                      attrs: {
                        src: require("@/assets/images/mobile-modal.png"),
                        alt: "mobile",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "absolute bottom-4 left-1/2 w-full max-w-[343px] -translate-x-1/2 transform",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "w-full rounded-2xl bg-[#EFA543] px-11 py-5 text-center text-base font-semibold text-white",
                            on: { click: _vm.navigatePage },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.getLocaleMsg("button")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }