import CacheUtil from '@/utils/cache';

export default {
  langs: ['ru', 'uz', 'en'],

  getCurrent() {
    const currentLanguage = CacheUtil.getDataByKey('currentLanguage');

    return currentLanguage ? currentLanguage : 'ru';
  },
  setCurrent(value) {
    CacheUtil.setDataByKey('currentLanguage', value);
  },
  isAllowed(lang) {
    return this.langs.includes(lang);
  },
};
