<template>
  <form
    :class="[
      'tickets-search-widget__form',
      'tickets-search-form',
      `tickets-search-form_theme_${theme}`,
      {
        'tickets-search-form_route_simple': !formData.flightIsExtended,
        'tickets-search-form_route_extended': formData.flightIsExtended,
      },
    ]"
  >
    <div class="tickets-search-form__segments">
      <div
        v-for="(fields, fieldsId) in formData.fields"
        :key="fieldsId"
        :ref="`segment-${fieldsId}`"
        :class="[
          'tickets-search-form__segment',
          {
            'tickets-search-form__segment_state_active': fieldsId === formFieldsActiveRowId,
          },
        ]"
      >
        <!--                 <div class="flex flex_items_center">-->
        <!--                    <div :class="[-->
        <!--                        'tickets-search-form__segment-select_passengers last:mr-0 mr-2 sm:mr-4 lg:mr-[25px] lg:mb-[24.5px] mb-4',-->
        <!--                        {-->
        <!--                            'hide': fieldsId > 0-->
        <!--                        }-->
        <!--                    ]">-->
        <!--                        <PassengersField-->
        <!--                            v-model="formData.passengers"-->
        <!--                            :class="[-->
        <!--                            `form-field_theme_${theme}`,-->
        <!--                            'form-field_name_passengers',-->
        <!--                            {-->
        <!--                                'form-field_route_simple': !formData.flightIsExtended,-->
        <!--                                'form-field_route_extended': formData.flightIsExtended-->
        <!--                            }-->
        <!--                        ]"-->
        <!--                            :name="`passengers-${fieldsId}`"-->
        <!--                            :autofocus="getNextFocusFieldName(`passengers-${fieldsId}`)"-->
        <!--                            :label="getLocaleMsg('PassengersField.label')"-->
        <!--                            @focused="setNextFocusFieldName(null)"-->
        <!--                        />-->
        <!--                    </div>-->
        <!--                    <div :class="[-->
        <!--                        'tickets-search-form__segment-select_passengers last:mr-0 mr-[25px] lg:mb-[24.5px] mb-4',-->
        <!--                        {-->
        <!--                            'hide': fieldsId > 0-->
        <!--                        }-->
        <!--                    ]">-->
        <!--                        <ClassesField-->
        <!--                            v-model="formData.passengers"-->
        <!--                            :class="[-->
        <!--                            `form-field_theme_${theme}`,-->
        <!--                            'form-field_name_passengers',-->
        <!--                            {-->
        <!--                                'form-field_route_simple': !formData.flightIsExtended,-->
        <!--                                'form-field_route_extended': formData.flightIsExtended-->
        <!--                            }-->
        <!--                        ]"-->
        <!--                            :name="`passengers-${fieldsId}`"-->
        <!--                            :autofocus="getNextFocusFieldName(`passengers-${fieldsId}`)"-->
        <!--                            @focused="setNextFocusFieldName(null)"-->
        <!--                        />-->
        <!--                    </div>-->
        <!--                </div>-->

        <div class="tickets-gap-mobile relative grid grid-cols-1 max-md:!gap-0 md:grid-cols-12 md:gap-y-0">
          <!--                    <div v-if="formData.flightIsExtended"-->
          <!--                         class="tickets-search-form__segment-col tickets-search-form__segment-col_count"-->
          <!--                    >-->
          <!--                        {{ fieldsId + 1 }}-->
          <!--                    </div>-->
          <div
            :class="[
              'tickets-search-form__segment-col tickets-search-form__segment-col_name_airport-departure flex_items_center flex rounded-l-2xl border-t-4 border-[#1D6BC0] bg-white max-md:border-t-2',
              {
                'md:col-span-2': !formData.flightIsExtended,
                'md:col-span-4': formData.flightIsExtended,
              },
              focusStyle(`departure-${fieldsId}`),
              'max-md:min-h-[56px] max-md:rounded-t-2xl max-md:rounded-bl-none',
            ]"
          >
            <AirportsField
              v-model="fields.departure"
              :class="[
                `form-field_theme_${theme}`,
                'form-field_name_departure self-center',
                {
                  'form-field_route_simple': !formData.flightIsExtended,
                  'form-field_route_extended': formData.flightIsExtended,
                },
              ]"
              type="departure"
              :name="`departure-${fieldsId}`"
              :validation="validateField(fields.departure.code)"
              :label="getLocaleMsg('AirportsField.departure.label')"
              :error-message="getLocaleMsg('AirportsField.departure.errorMessage')"
              @onFocus="focusOn(`departure-${fieldsId}`)"
              @outFocus="focusOut(`departure-${fieldsId}`)"
              @selected="setNextFocusFieldName(`arrival-${fieldsId}`)"
              @focused="setNextFocusFieldName(null)"
            />
            <button
              class="tickets-search-form__swap-airports"
              type="button"
              @click="swapAirports(fieldsId)"
              v-html="svgIcons.get('arrow-directions')"
            />
          </div>

          <div
            class="tickets-search-form__segment-col tickets-search-form__segment-col_name_airport-arrival flex border-t-4 border-[#1D6BC0] bg-white max-md:border-t-2 md:col-span-2"
            :class="[
              focusStyle(`arrival-${fieldsId}`),
              'max-md:min-h-[56px]',
              {
                'md:col-span-2': !formData.flightIsExtended,
                'md:col-span-4': formData.flightIsExtended,
              },
            ]"
          >
            <AirportsField
              v-model="fields.arrival"
              :class="[
                `form-field_theme_${theme}`,
                'form-field_name_arrival self-center',
                {
                  'form-field_route_simple': !formData.flightIsExtended,
                  'form-field_route_extended': formData.flightIsExtended,
                },
              ]"
              type="arrival"
              :name="`arrival-${fieldsId}`"
              :autofocus="getNextFocusFieldName(`arrival-${fieldsId}`)"
              :validation="validateField(fields.arrival.code)"
              :label="getLocaleMsg('AirportsField.arrival.label')"
              :error-message="getLocaleMsg('AirportsField.arrival.errorMessage')"
              @onFocus="focusOn(`arrival-${fieldsId}`)"
              @outFocus="focusOut(`arrival-${fieldsId}`)"
              @selected="setNextFocusFieldName(`date-${fieldsId}`)"
              @focused="setNextFocusFieldName(null)"
            />
          </div>

          <div
            :class="[
              'tickets-search-form__segment-col',
              'tickets-search-form__segment-col_name_date flex border-t-4 border-[#1D6BC0] bg-white max-md:border-t-2',
              'max-md:min-h-[56px] md:col-span-2',
              {
                'md:col-span-2': !formData.flightIsExtended,
                'max-md:rounded-b-lg max-md:rounded-t-none md:col-span-4': formData.flightIsExtended,
              },
              formData.flightIsExtended ? ' rounded-r-2xl' : '',
              focusStyle(`date-${fieldsId}`),
            ]"
          >
            <DateField
              v-model="fields.dates"
              :class="[
                `form-field_theme_${theme}`,
                'form-field_name_date self-center',
                {
                  'form-field_route_simple': !formData.flightIsExtended,
                  'form-field_route_extended': formData.flightIsExtended,
                },
              ]"
              :name="`date-${fieldsId}`"
              :min-date="getDatepickerMinDate(fieldsId)"
              :max-date="getDatepickerMaxDate(fieldsId)"
              :mode="getDatepickerMode()"
              :single-mode="formData.flightIsExtended"
              :autofocus="getNextFocusFieldName(`date-${fieldsId}`)"
              :validation="validateField(fields.dates, 'dates')"
              :value-default="fields.dates"
              :value-external="fields.dates"
              :opts="dateFieldOpts"
              :label="getLocaleMsg('DateField.label')"
              :error-message="getLocaleMsg('DateField.errorMessage')"
              @onFocus="focusOn(`date-${fieldsId}`)"
              @outFocus="focusOut(`date-${fieldsId}`)"
              @date-selected="setNextFocusFieldName(`additial-${fieldsId}`)"
              @mode-changed="onChangeDatepickerMode"
              @opened="setFormFieldsActiveRowId(fieldsId)"
              @closed="setFormFieldsActiveRowId(null)"
              @focused="setNextFocusFieldName(null)"
            />
          </div>

          <div
            v-if="!formData.flightIsExtended"
            :class="[
              'tickets-search-form__segment-col',
              'tickets-search-form__segment-col_name_date box-border flex border-t-4 border-[#1D6BC0] bg-white max-md:border-t-2',
              'max-md:min-h-[56px] md:col-span-2',
            ]"
          >
            <input
              v-model="secondDate"
              class="flex max-w-[100px] self-center py-5 pl-5 placeholder:text-[#A5ABB0] max-xl:text-sm"
              readonly
              :placeholder="getLocaleMsg('DateField.label1')"
              :class="[
                `form-field_theme_${theme}`,
                'form-field_name_date',
                {
                  'form-field_route_simple': !formData.flightIsExtended,
                  'form-field_route_extended': formData.flightIsExtended,
                },
              ]"
            />
          </div>

          <!--                    <div class="tickets-search-form__segment-col tickets-search-form__segment-col_name_date"-->
          <!--                         v-if="!formData.flightIsExtended && getDatepickerMode() === 'range'"-->
          <!--                    >-->
          <!--                        <DateField-->
          <!--                            v-model="fields.dates"-->
          <!--                            :class="[-->
          <!--                                        `form-field_theme_${theme}`,-->
          <!--                                        'form-field_name_date',-->
          <!--                                        {-->
          <!--                                       'form-field_route_simple': !formData.flightIsExtended,-->
          <!--                                       'form-field_route_extended': formData.flightIsExtended-->
          <!--                                       }-->
          <!--                                   ]"-->
          <!--                            :name="`date-${fieldsId}`"-->
          <!--                            :min-date="getDatepickerMinDate(fieldsId)"-->
          <!--                            :max-date="getDatepickerMaxDate(fieldsId)"-->
          <!--                            :mode="getDatepickerMode()"-->
          <!--                            :single-mode="formData.flightIsExtended"-->
          <!--                            :autofocus="getNextFocusFieldName(`date-${fieldsId}`)"-->
          <!--                            :validation="validateField(fields.dates, 'dates')"-->
          <!--                            :value-default="fields.dates"-->
          <!--                            :value-external="fields.dates"-->
          <!--                            :opts="dateFieldOpts"-->
          <!--                            :label="getLocaleMsg('DateField.label')"-->
          <!--                            :error-message="getLocaleMsg('DateField.errorMessage')"-->
          <!--                            @date-selected="setNextFocusFieldName(`additial-${fieldsId}`)"-->
          <!--                            @mode-changed="onChangeDatepickerMode"-->
          <!--                            @opened="setFormFieldsActiveRowId(fieldsId)"-->
          <!--                            @closed="setFormFieldsActiveRowId(null)"-->
          <!--                            @focused="setNextFocusFieldName(null)"-->
          <!--                        />-->
          <!--                    </div>-->
          <div
            v-if="!formData.flightIsExtended"
            :class="[
              'tickets-search-form__segment-col',
              'tickets-search-form__segment-col_name_date flex rounded-r-2xl border-t-4 border-[#1D6BC0] bg-white max-md:border-t-2',
              'max-md:min-h-[56px] max-md:rounded-b-2xl max-md:rounded-tr-none md:col-span-2',
              focusStyle(`passengers-${fieldsId}`),
            ]"
          >
            <div
              class="tickets-search-form__segment-col tickets-search-form__segment-col_name_passengers h-full self-center"
            >
              <PassengersField
                v-model="formData.passengers"
                :class="[
                  `form-field_theme_${theme}`,
                  'form-field_name_passengers h-full',
                  {
                    'form-field_route_simple': !formData.flightIsExtended,
                    'form-field_route_extended': formData.flightIsExtended,
                  },
                ]"
                :name="`passengers-${fieldsId}`"
                :autofocus="getNextFocusFieldName(`passengers-${fieldsId}`)"
                @onFocus="focusOn(`passengers-${fieldsId}`)"
                @outFocus="focusOut(`passengers-${fieldsId}`)"
                :label="getLocaleMsg('PassengersField.label')"
                @focused="setNextFocusFieldName(null)"
              />
            </div>
          </div>
          <div
            v-if="!formData.flightIsExtended"
            class="tickets-search-form__segment-col ml-6 flex max-md:ml-0 max-md:mt-3"
          >
            <button
              class="tickets-search-form__submit w-full self-end text-base font-semibold max-lg:w-1/2 max-md:w-full"
              type="button"
              @click="submitForm"
            >
              {{ getLocaleMsg('submit') }}
            </button>
          </div>
        </div>

        <button
          v-if="fieldsId !== 0 && fieldsId === formData.fields.length - 1"
          class="tickets-search-form__remove-segment"
          type="button"
          @click="removeFormField(fieldsId)"
          v-html="svgIcons.get('times')"
        />
      </div>
    </div>

    <div
      v-if="formData.flightIsExtended"
      class="tickets-gap-mobile relative mb-4 grid grid-cols-1 max-md:!block md:grid-cols-12 md:gap-y-0"
    >
      <!--                                <PassengersField-->
      <!--                                    v-model="formData.passengers"-->
      <!--                                    :class="[-->
      <!--                                        `form-field_theme_${theme}`,-->
      <!--                                        'form-field_name_passengers',-->
      <!--                                        {-->
      <!--                                            'form-field_route_simple': !formData.flightIsExtended,-->
      <!--                                            'form-field_route_extended': formData.flightIsExtended-->
      <!--                                        }-->
      <!--                                    ]"-->
      <!--                                    :label="getLocaleMsg('PassengersField.label')"-->
      <!--                                />-->
      <div
        :class="[
          'tickets-search-form__segment-col',
          'tickets-search-form__segment-col_name_date flex rounded-l-2xl border-t-4 border-[#1D6BC0] bg-white max-md:border-t-2',
          'max-md:!mb-3 max-md:min-h-[56px] max-md:!rounded-2xl max-md:rounded-b-2xl  max-md:rounded-tr-none md:col-span-4',
          focusStyle(`passengers-${fieldsId}`),
        ]"
      >
        <div
          class="tickets-search-form__segment-col tickets-search-form__segment-col_name_passengers h-full self-center"
        >
          <PassengersField
            v-model="formData.passengers"
            :class="[
              `form-field_theme_${theme}`,
              'form-field_name_passengers h-full',
              {
                'form-field_route_simple': !formData.flightIsExtended,
                'form-field_route_extended': formData.flightIsExtended,
              },
            ]"
            :name="`passengers-${fieldsId}`"
            :autofocus="getNextFocusFieldName(`passengers-${fieldsId}`)"
            @onFocus="focusOn(`passengers-${fieldsId}`)"
            @outFocus="focusOut(`passengers-${fieldsId}`)"
            :label="getLocaleMsg('PassengersField.label')"
            @focused="setNextFocusFieldName(null)"
          />
        </div>
      </div>
      <div
        class="tickets-search-form__add-segment-col !max-h-[60px] max-md:!mb-3"
        :class="addFormFieldIsDisabled() ? 'col-start-5 col-end-8 mx-4' : 'col-start-6 col-end-8'"
        style="border-radius: 0 16px 16px 0; border: 1px solid white"
      >
        <button
          :disabled="addFormFieldIsDisabled()"
          class="tickets-search-form__add-segment-button button !max-h-[58px]"
          type="button"
          @click="addFormField"
        >
          <span
            v-if="!addFormFieldIsDisabled()"
            class="tickets-search-form__add-segment-button_icon"
            v-html="svgIcons.get('circle_add')"
          />
          {{ getLocaleMsg(`addFlight.${addFormFieldIsDisabled() ? 'disabled' : 'default'}`) }}
        </button>
      </div>
      <div
        class="tickets-search-form__add-segment-col tickets-search-form__add-segment-col_name_field col-span-4 col-start-9 mt-3 flex max-md:!mb-3 md:mr-[5px] md:mt-0 md:justify-end"
      >
        <button class="tickets-search-form__submit !w-full sm:w-auto" type="button" @click="submitForm">
          <!--                    <span class="tickets-search-form__submit-icon" v-html="svgIcons.get('search')"></span>-->
          {{ getLocaleMsg('submit') }}
        </button>
      </div>
    </div>

    <div class="tickets-search-form__additional-options">
      <div
        v-if="theme === 'classic' || theme === 'alternative' || (theme === 'compact' && $mq.minW1024)"
        :class="theme === 'compact' && $mq.minW1024 ? 'order-last' : ''"
        class="tickets-search-form__additional-options-col"
      >
        <div
          class="tickets-search-form__additional-options-group tickets-search-form__additional-options-group_name_azaso"
        >
          <div
            v-if="theme === 'alternative'"
            class="tickets-search-form__additional-options-item tickets-search-form__additional-options-item_name_azaso"
          >
            <router-link :to="{ name: 'home', params: { lang: $i18n.locale } }" class="link link_theme_2">
              <span class="link__icon" v-html="svgIcons.get('arrow-forward')" /><span class="link__text">{{
                getLocaleMsg('backToHome')
              }}</span>
            </router-link>
          </div>
          <div
            v-if="theme === 'compact' && $mq.minW1024"
            class="tickets-search-form__additional-options-item tickets-search-form__additional-options-item_name_azaso rounded-xl bg-[#E8EEF2] py-1.5"
          >
            <button class="button button_tickets-search-form_cancel" type="button" @click="cancelForm">
              <!--                            <span class="button__icon" v-html="svgIcons.get('times')"/><span class="button__text text-[#1B2226] text-base font-medium"-->
              <!--                        >{{ getLocaleMsg('cancel') }}</span>-->
              <span /><span class="button__text text-base font-medium text-[#1B2226]">{{
                getLocaleMsg('cancel')
              }}</span>
            </button>
          </div>
          <div
            v-if="theme === 'classic' || theme === 'alternative'"
            class="tickets-search-form__additional-options-item tickets-search-form__additional-options-item_name_azaso"
          >
            <button
              :class="[
                'button',
                'button_tickets-search-form_extend-route',
                `button_tickets-search-form_extend-route_theme_${layoutTheme}`,
                {
                  button_state_active: formData.flightIsExtended,
                },
              ]"
              type="button"
              @click="extendForm"
            >
              <span class="button__icon" />
              <span
                class="button__text text-white"
                v-html="getLocaleMsg(`extendRoute.${formData.flightIsExtended ? 'extended' : 'default'}`)"
              />
            </button>
          </div>
        </div>
      </div>
      <div class="tickets-search-form__additional-options-col" :class="formData.flightIsExtended ? '' : ''">
        <div
          class="tickets-search-form__additional-options-group tickets-search-form__additional-options-group_name_crodon"
        >
          <div
            class="tickets-search-form__additional-options-item tickets-search-form__additional-options-item_name_crodon"
          >
            <div
              class="tickets-search-form__additional-options-group tickets-search-form__additional-options-group_name_slane"
            >
              <div
                class="tickets-search-form__additional-options-item tickets-search-form__additional-options-item_name_slane"
              >
                <CheckboxField
                  v-model="formData.flightDatesFlexible"
                  :value="true"
                  :label="getLocaleMsg('datesFlexible')"
                  class="form-field_theme_default"
                />
              </div>
              <div
                class="tickets-search-form__additional-options-item tickets-search-form__additional-options-item_name_slane"
              >
                <CheckboxField
                  v-model="formData.flightDirectOnly"
                  :value="true"
                  :label="getLocaleMsg('directOnly')"
                  class="form-field_theme_default"
                />
              </div>
              <div
                v-if="theme === 'compact'"
                class="tickets-search-form__additional-options-item tickets-search-form__additional-options-item_name_slane order-first"
              >
                <button
                  :class="[
                    'button',
                    'button_tickets-search-form_extend-route',
                    {
                      button_state_active: formData.flightIsExtended,
                    },
                  ]"
                  type="button"
                  @click="extendForm"
                >
                  <span class="button__icon" />
                  <span
                    class="button__text"
                    v-html="getLocaleMsg(`extendRoute.${formData.flightIsExtended ? 'extended' : 'default'}`)"
                  />
                </button>
              </div>
            </div>
          </div>
          <template v-if="theme === 'compact'">
            <div
              class="tickets-search-form__additional-options-item tickets-search-form__additional-options-item_name_crodon"
            >
              <div
                class="tickets-search-form__additional-options-group tickets-search-form__additional-options-group_name_zabert"
              >
                <div
                  v-if="$mq.maxW1023"
                  class="tickets-search-form__additional-options-item rounded-xl bg-[#E8EEF2] py-1.5"
                >
                  <button class="button button_tickets-search-form_cancel" type="button" @click="cancelForm">
                    <!--                                    <span class="button__icon" v-html="svgIcons.get('times')"/>-->
                    <span class="button__text text-base font-medium text-[#1B2226]">{{ getLocaleMsg('cancel') }}</span>
                  </button>
                </div>
                <!--                                <div class="tickets-search-form__additional-options-item">-->
                <!--                                    <button class="tickets-search-form__submit" type="button" @click="submitForm">-->
                <!--                                        <span class="tickets-search-form__submit-icon" v-html="svgIcons.get('search')"-->
                <!--                                        ></span>-->
                <!--                                        {{ getLocaleMsg('submit') }}-->
                <!--                                    </button>-->
                <!--                                </div>-->
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!--        <div v-if="theme === 'classic' || theme === 'alternative'" class="tickets-search-form__footer">-->
    <!--            <button class="tickets-search-form__submit" type="button" @click="submitForm">-->
    <!--                {{ getLocaleMsg('submit') }}-->
    <!--            </button>-->
    <!--        </div>-->
  </form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { randomString, formatDate } from '@/utils';
import AirportsField from './AirportsField';
import DateField from './DateField';
import PassengersField from './PassengersField';
import CheckboxField from '@/components/Form/Fields/Checkbox';
import ClassesField from '@/components/Tickets/SearchWidget/ClassesField';
import ApiUtil from '@/utils/api';

export default {
  name: 'TicketsSearchForm',

  components: {
    ClassesField,
    AirportsField,
    DateField,
    PassengersField,
    CheckboxField,
  },

  props: {
    theme: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    currentFocus: '',
    searchParams: null,
    formStatus: null,
    formData: {
      fields: [
        {
          departure: {
            name: null,
            code: null,
          },
          arrival: {
            name: null,
            code: null,
          },
          dates: {
            one: '',
            two: '',
          },
        },
      ],
      passengers: {
        tripClass: 'E',
        adultsQty: 1,
        childrensQty: 0,
        infantsQty: 0,
      },
      flightOneWay: false,
      flightIsExtended: false,
      flightDatesFlexible: false,
      flightDirectOnly: false,
    },
    formDataStore: null,
    formFieldsActiveRowId: null,
    formNextFocusFieldName: null,
    dateFieldOpts: {
      format: 'dd.MM.yy',
      singleMode: true,
    },
  }),

  computed: {
    ...mapGetters({
      formDefaultData: 'ticketsSearchFormData',
    }),
    ...mapGetters(['layoutTheme']),
    focusStyle() {
      return (name) => {
        return this.currentFocus === name ? 'transition duration-700 border-[#EFA543]' : '';
      };
    },
    secondDate() {
      if (this.formData.fields[0].dates.two) {
        const inputDate = this.formData.fields[0].dates.two;
        const [year, month, day] = inputDate.split('-');
        return day + '.' + month + '.' + year;
      }
      return '';
    },
  },

  created() {
    this.initFormData();
  },

  methods: {
    ...mapActions(['getTickets']),
    ...mapMutations(['setTicketsSearchStatus']),
    focusOn(name) {
      setTimeout(() => {
        this.currentFocus = name;
      }, 200);
    },
    focusOut(name) {
      this.currentFocus = '';
    },
    getLocaleMsg(key) {
      return this.$getLocaleMsg(`components.${this.$options.name}.${key}`);
    },
    swapAirports(id) {
      const field = this.formData.fields[id];

      const dapertureNew = field.arrival;

      field.arrival = field.departure;
      field.departure = dapertureNew;
    },
    getDatepickerMode() {
      return this.formData.flightOneWay ? 'single' : 'range';
    },
    onChangeDatepickerMode(mode) {
      this.formData.flightOneWay = mode === 'single';
    },
    getDatepickerMinDate(fieldsId) {
      let minDate = formatDate(new Date());

      if (this.formData.fields.length < 2) return minDate;

      let prevFieldsId = fieldsId - 1;

      for (prevFieldsId; prevFieldsId >= 0; prevFieldsId--) {
        let prevDates = this.formData.fields[prevFieldsId]['dates'];

        if (prevDates.one !== '') {
          minDate = prevDates.one;

          break;
        }
      }

      return minDate;
    },
    getDatepickerMaxDate(fieldsId) {
      let maxDate = '';

      const fieldsNumber = this.formData.fields.length;

      if (fieldsNumber < 2) return maxDate;

      let nextFieldsId = fieldsId + 1;

      for (nextFieldsId; nextFieldsId < fieldsNumber; nextFieldsId++) {
        const nextDates = this.formData.fields[nextFieldsId]['dates'];

        if (nextDates.one !== '') {
          maxDate = nextDates.one;

          break;
        }
      }

      return maxDate;
    },
    validateField(value, name) {
      name = name === undefined ? null : name;

      const formIsInvalid = this.formStatus === 'invalid';

      if (name === 'dates') {
        if (!this.formData.flightIsExtended && !this.formData.flightOneWay) {
          return formIsInvalid && (value.one === '' || value.two === '');
        } else {
          return formIsInvalid && value.one === '';
        }
      } else {
        return formIsInvalid && value === null;
      }
    },
    getNextFocusFieldName(name) {
      return name === this.formNextFocusFieldName ? randomString(5) : false;
    },
    setNextFocusFieldName(name) {
      this.formNextFocusFieldName = name;
    },
    setFormFieldsActiveRowId(id) {
      this.formFieldsActiveRowId = id;
    },
    getFormDefaultField() {
      return {
        departure: {
          code: null,
          name: null,
        },
        arrival: {
          code: null,
          name: null,
        },
        dates: {
          one: '',
          two: '',
        },
      };
    },
    addFormFieldIsDisabled() {
      return this.formData.fields.length > 4;
    },
    addFormField() {
      this.$set(this.formData.fields, this.formData.fields.length, this.getFormDefaultField());
    },
    resetFormField(id) {
      this.$set(this.formData.fields, id, this.getFormDefaultField());
    },
    removeFormField(id) {
      this.formData.fields.splice(id, 1);
    },
    initFormData() {
      if (this.formDefaultData === null) return;

      for (let dataName in this.formDefaultData) {
        this.formData[dataName] =
          dataName === 'fields' ? [...this.formDefaultData[dataName]] : this.formDefaultData[dataName];
      }
    },
    validateFormData() {
      let isInvalid = false;

      if (this.formData.flightIsExtended) {
        this.formData.fields.forEach((field) => {
          if (field.departure.code === null || field.arrival.code === null || field.dates.one === '') {
            isInvalid = true;

            return;
          }
        });
      } else {
        this.formData.fields.forEach((field) => {
          if (
            field.departure.code === null ||
            field.arrival.code === null ||
            field.dates.one === '' ||
            (!this.formData.flightOneWay && field.dates.two === '')
          ) {
            isInvalid = true;

            return;
          }
        });
      }

      this.formStatus = isInvalid ? 'invalid' : 'valid';
    },
    getFormFormattedData() {
      const formattedData = {
        directions: [],
      };

      if (this.formData.flightIsExtended) {
        this.formData.fields.forEach((field) => {
          const direction = {
            departure_code: field.departure.code,
            arrival_code: field.arrival.code,
            date: field.dates.one,
          };

          formattedData.directions.push(direction);
        });
      } else {
        const field = this.formData.fields[0];

        for (let fieldName in field.dates) {
          if (
            fieldName === 'one' ||
            (!formattedData.flightOneWay && fieldName === 'two' && field.dates[fieldName] !== '')
          ) {
            const direction = {
              departure_code: fieldName === 'one' ? field.departure.code : field.arrival.code,
              arrival_code: fieldName === 'one' ? field.arrival.code : field.departure.code,
              date: field.dates[fieldName],
            };

            formattedData.directions.push(direction);
          }
        }
      }

      formattedData.direct = !!this.formData.flightDirectOnly;
      formattedData.flexible = !!this.formData.flightDatesFlexible;
      formattedData.count_adult = this.formData.passengers.adultsQty;
      formattedData.count_child = this.formData.passengers.childrensQty;
      formattedData.count_infant = this.formData.passengers.infantsQty;

      formattedData.class_type = this.formData.passengers.tripClass;

      return formattedData;
    },
    cancelForm() {
      this.$emit('cancel');
    },
    extendForm() {
      const formDataForStore = this.$cloneDeep(this.formData, true);

      if (this.formDataStore) {
        this.formData = this.$cloneDeep(this.formDataStore, true);
      } else {
        const fieldsNumber = this.formData.fields.length;

        if (!this.formData.flightIsExtended) {
          if (fieldsNumber) this.removeFormField(0);

          for (let i = 0; i < 2; i++) {
            this.addFormField();
          }
        } else {
          for (let i = fieldsNumber; i >= 0; i--) {
            this.resetFormField(i);

            if (i !== 0) this.removeFormField(i);
          }

          setTimeout(() => (this.formData.flightOneWay = false), 0);
        }

        this.formData.flightIsExtended = !this.formData.flightIsExtended;
      }

      this.formDataStore = formDataForStore;

      this.formStatus = null;
    },
    async submitForm() {
      this.validateFormData();

      if (this.formStatus === 'invalid') return;

      this.formStatus = null;

      await this.setTicketsSearchStatus('start');
      await ApiUtil.post('/air/search/init', this.getFormFormattedData()).then((response) => {
        localStorage.setItem('request_guid', response.data.request_guid);
      });

      this.validateFormData();

      if (this.formStatus === 'invalid') return;

      this.formStatus = null;

      if (this.theme === 'compact') setTimeout(() => this.cancelForm(), 300);

      this.getTickets({
        searchParams: this.getFormFormattedData(),
        formData: this.formData,
      });
    },
  },
};
</script>
