import ApiUtil from '@/utils/api';
import store from '@/store';

const UserUtil = {
  logout() {
    return new Promise((resolve, reject) => {
      ApiUtil.get('/auth/logout')
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  showPasswordRecoveryPopup(token) {
    store.dispatch('saveUserPasswordResetToken', token);
    store.dispatch('setUserPasswordResetPopupData', { status: 'open' });
  },
  changePassword(data) {
    return new Promise((resolve, reject) => {
      ApiUtil.put('/users/profile/password', data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};

export default UserUtil;
