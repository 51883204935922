var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mobile__alltrip-container" }, [
    _c("div", { staticClass: "mobile__alltrip-block" }, [
      _c("div", { staticClass: "mobile__alltrip-block-left" }, [
        _c("h2", { staticClass: "mobile__alltrip-block-left-title" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.getLocaleMsg("mobile.title")) + "\n      "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "mobile__alltrip-block-left-text" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.getLocaleMsg("mobile.text")) + "\n      "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mobile__alltrip-block-left-app" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://apps.apple.com/us/app/alltrip/id6451256196",
                target: "_blank",
              },
            },
            [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: _vm.appStoreImage,
                    expression: "appStoreImage",
                  },
                ],
                attrs: { alt: "alltrip mobile app-ios" },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://play.google.com/store/apps/details?id=uz.tt25.all_trip_b2c",
                target: "_blank",
              },
            },
            [
              _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: _vm.googleStoreImage,
                    expression: "googleStoreImage",
                  },
                ],
                attrs: { alt: "alltrip mobile app-android" },
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mobile__alltrip-block-right" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value: _vm.mobileAppImage,
              expression: "mobileAppImage",
            },
          ],
          staticClass: "mobile__alltrip-block-right-img",
          attrs: { alt: "Alltrip Mobile App" },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }