var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropdown,
          expression: "closeDropdown",
        },
      ],
      class: [
        "form-field",
        "form-field_type_passengers",
        {
          "form-field_dropdown-visibility_visible": _vm.fieldDropdownIsVisible,
          "form-field_label-position_top": _vm.labelPositionIsTop,
        },
      ],
    },
    [
      _c(
        "div",
        {
          staticClass:
            "form-field__inner flex h-full w-full cursor-pointer justify-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex h-full w-full items-center justify-between self-center px-5 max-lg:px-2 max-md:px-5",
              on: { click: _vm.toggleDropdown },
            },
            [
              _c("input", {
                staticClass: "form-field__input",
                attrs: { type: "text", readonly: "", hidden: "" },
                domProps: { value: _vm.value },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-col" }, [
                _c(
                  "span",
                  { staticClass: "text-sm font-[500] text-[#1B2226]" },
                  [_vm._v(_vm._s(_vm.value.count))]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "text-sm font-medium text-[#A5ABB0]" },
                  [_vm._v(_vm._s(_vm.value.class))]
                ),
              ]),
              _vm._v(" "),
              _c("span", {
                staticClass: "ml-4 text-[#36454F]",
                domProps: {
                  innerHTML: _vm._s(_vm.svgIcons.get("select_arrow")),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-field__dropdown top-[61px] max-sm:!w-full" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "form-field__section form-field__section_type_passengers border border-[#C5D4DF]",
                },
                [
                  _c("div", { staticClass: "mb-4 flex justify-between" }, [
                    _c("div", { staticClass: "flex flex-col" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "form-field__counter-value text-sm font-semibold text-[#1B2226]",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getLocaleMsg("adultsCounter.type", {
                                choice: _vm.adultsValue,
                              })
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "text-xs font-medium text-[#A5ABB0]" },
                        [
                          _vm._v(
                            _vm._s(_vm.getLocaleMsg("adultsCounter.label"))
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "form-field__counter-button form-field__counter-button_type_decrease",
                          class:
                            _vm.fieldValue["adultsQty"] === 1 ||
                            _vm.fieldValue["adultsQty"] ===
                              _vm.fieldValue["infantsQty"]
                              ? "!bg-[#A5ABB0]"
                              : "",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.decreaseCounter("adultsQty", 1)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "form-field__counter-button-icon form-field__counter-button-icon_type_decrease",
                            },
                            [_vm._v("-")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mx-2" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.getLocaleMsg("adultsCounter.newValue", {
                                choice: _vm.adultsValue,
                                values: { qty: _vm.adultsValue },
                              })
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "form-field__counter-button form-field__counter-button_type_increase",
                          class: _vm.passengersQty === 9 ? "!bg-[#A5ABB0]" : "",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.increaseCounter("adultsQty")
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "form-field__counter-button-icon form-field__counter-button-icon_type_increase",
                            },
                            [_vm._v("+")]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-4 flex justify-between" }, [
                    _c("div", { staticClass: "flex flex-col" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "form-field__counter-value text-sm font-semibold text-[#1B2226]",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.getLocaleMsg("childrensCounter.type", {
                                  choice: _vm.childrensValue,
                                })
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "text-xs font-medium text-[#A5ABB0]" },
                        [
                          _vm._v(
                            _vm._s(_vm.getLocaleMsg("childrensCounter.label"))
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "form-field__counter-button form-field__counter-button_type_decrease",
                          class:
                            _vm.fieldValue["childrensQty"] === 0
                              ? "!bg-[#A5ABB0]"
                              : "",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.decreaseCounter("childrensQty")
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "form-field__counter-button-icon form-field__counter-button-icon_type_decrease",
                            },
                            [_vm._v("-")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mx-2" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.getLocaleMsg("childrensCounter.newValue", {
                                choice: _vm.childrensValue,
                                values: { qty: _vm.childrensValue },
                              })
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "form-field__counter-button form-field__counter-button_type_increase",
                          class: _vm.passengersQty === 9 ? "!bg-[#A5ABB0]" : "",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.increaseCounter("childrensQty")
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "form-field__counter-button-icon form-field__counter-button-icon_type_increase",
                            },
                            [_vm._v("+")]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-4 flex justify-between" }, [
                    _c("div", { staticClass: "flex flex-col" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "form-field__counter-value text-sm font-semibold text-[#1B2226]",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.getLocaleMsg("infantsCounter.type", {
                                  choice: _vm.infantsValue,
                                  values: { qty: _vm.infantsValue },
                                })
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "text-xs font-medium text-[#A5ABB0]" },
                        [
                          _vm._v(
                            _vm._s(_vm.getLocaleMsg("infantsCounter.label"))
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "form-field__counter-button form-field__counter-button_type_decrease",
                          class:
                            _vm.fieldValue["infantsQty"] === 0
                              ? "!bg-[#A5ABB0]"
                              : "",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.decreaseCounter("infantsQty")
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "form-field__counter-button-icon form-field__counter-button-icon_type_decrease",
                            },
                            [_vm._v("-")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mx-2" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.getLocaleMsg("infantsCounter.newValue", {
                                choice: _vm.infantsValue,
                                values: { qty: _vm.infantsValue },
                              })
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "form-field__counter-button form-field__counter-button_type_increase",
                          class:
                            _vm.passengersQty === 9 ||
                            _vm.fieldValue["adultsQty"] ===
                              _vm.fieldValue["infantsQty"]
                              ? "!bg-[#A5ABB0]"
                              : "",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.increaseCounter("infantsQty")
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "form-field__counter-button-icon form-field__counter-button-icon_type_increase",
                            },
                            [_vm._v("+")]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "my-4 h-[1px] w-full bg-[#E2E9EF]",
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-field__section form-field__section_type_trip-class",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-field__select flex-col" },
                        _vm._l(_vm.tripClasses, function (tripClassCode) {
                          return _c(
                            "button",
                            {
                              key: tripClassCode,
                              class: [
                                "mb-4",
                                {
                                  "":
                                    _vm.fieldValue.tripClass === tripClassCode,
                                },
                              ],
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeTripClass(tripClassCode)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "flex w-full" }, [
                                _c("input", {
                                  staticClass:
                                    "h-6 w-6 cursor-pointer border-2 border-red-400",
                                  attrs: {
                                    id: `radio-${tripClassCode}`,
                                    type: "radio",
                                    name: "class",
                                  },
                                  domProps: {
                                    checked:
                                      _vm.fieldValue?.tripClass ===
                                      tripClassCode,
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "mx-4 grow cursor-pointer text-start text-sm font-[500] text-[#1B2226]",
                                    attrs: { for: `radio-${tripClassCode}` },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("common.tripClasses")[
                                          tripClassCode
                                        ]
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }