import CacheUtil from '@/utils/cache';

const _BASE_CURRENCY = 'UZS';
let _RATES = {
  UZS: 1,
  RUB: 130,
  USD: 11320,
};

function _getCurrencyRate(currency) {
  return _RATES[currency];
}

function _mathRound(amount) {
  return Math.round(amount * 100) / 100;
}

function _convertToBase(currency, amount) {
  if (currency === _BASE_CURRENCY) {
    return amount;
  }

  return _mathRound(amount * _getCurrencyRate(currency));
}

export default {
  getCurrent() {
    const currentCurrency = CacheUtil.getDataByKey('currentCurrency');

    return currentCurrency ? currentCurrency : _BASE_CURRENCY;
  },
  setCurrent(value) {
    CacheUtil.setDataByKey('currentCurrency', value);
  },
  convertFromTo(currencyFrom, currencyTo, amount) {
    if (currencyFrom === currencyTo) return amount;

    let rateFrom = _convertToBase(currencyFrom, amount);
    let rateTo = _convertToBase(currencyTo, 1);

    return _mathRound(rateFrom / rateTo);
  },
  setRates(rates) {
    for (let currency in rates) {
      _RATES[currency] = rates[currency];
    }
  },
};
