import '@/assets/css/form.css';
import '@/assets/css/tailwind.css';
import '@/assets/scss/main.scss';

import Vue from 'vue';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';
import VueLazyload from 'vue-lazyload';

import { sync } from 'vuex-router-sync';
sync(store, router);

// Directives
import '@/directives';

// Filters
import '@/filters';

// Plugins
import '@/plugins';
import '@/plugins/sentry';
import mq from '@/plugins/vue-match-media';

// Utils
import '@/utils/i18n';
import '@/utils/event-hub';
import '@/utils/icons/svg';
import ApiUtil from '@/utils/api';
import VueMeta from 'vue-meta';

const url = process.env.VUE_APP_BACKEND_BASE_URL;
ApiUtil.init(url);

// Config
Vue.config.silent = process.env.MODE === 'production';

Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
  observer: true,
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1
  }
}, VueMeta);

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  mq,
  render: (h) => h(App),
});
