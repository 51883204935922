<template>
  <div
    v-click-outside="closeDropdown"
    :class="[
      'form-field',
      'form-field_type_passengers',
      {
        'form-field_dropdown-visibility_visible': fieldDropdownIsVisible,
        'form-field_label-position_top': labelPositionIsTop,
      },
    ]"
  >
    <div class="form-field__inner">
      <div class="form-field__input-wrapper inline-flex items-center" @click="toggleDropdown">
        <input :value="value" class="form-field__input" type="text" readonly hidden />
        <span class="text-sm font-medium text-[#A5ABB0]">{{ value }}</span>
        <!--                <span class="text-[#36454F] ml-4" v-html="svgIcons.get('select_arrow')"/>-->
      </div>
      <div class="form-field__dropdown">
        <div class="form-field__section form-field__section_type_trip-class">
          <div class="form-field__select">
            <button
              v-for="tripClassCode of tripClasses"
              :key="tripClassCode"
              :class="[
                'form-field__select-option',
                {
                  'form-field__select-option_state_selected': fieldValue.tripClass === tripClassCode,
                },
              ]"
              type="button"
              @click="changeTripClass(tripClassCode)"
            >
              <span class="form-field__select-option-text">{{ $t('common.tripClasses')[tripClassCode] }}</span>
              <span
                :class="[
                  'form-field__select-option-icon',
                  {
                    'form-field__select-option-icon_state_selected': fieldValue.tripClass === tripClassCode,
                  },
                ]"
                v-html="getTripClassIcon(tripClassCode)"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClassesField',

  model: {
    prop: 'fieldValue',
    event: 'input',
  },

  props: {
    label: {
      type: String,
      default: 'Пассажиры и класс',
    },
    fieldValue: {
      type: Object,
      default: () => ({}),
    },
    autofocus: {
      type: [Boolean, String],
      default: false,
    },
  },

  data() {
    return {
      fieldDropdownIsVisible: false,
      tripClasses: ['E', 'B'],
      passengersQtyMax: 9,
    };
  },

  computed: {
    labelPositionIsTop() {
      return this.fieldDropdownIsVisible || !this.fieldValueIsEmpty;
    },
    fieldValueIsEmpty() {
      let isEmpty = true;

      let value = this.fieldValue;

      for (let key in value) {
        let valueData = value[key];

        if (typeof valueData === 'string' && valueData !== '') {
          isEmpty = false;
        } else if (typeof valueData === 'number' && valueData !== 0) {
          isEmpty = false;
        }
      }

      return isEmpty;
    },
    adultsValue() {
      return this.fieldValue.adultsQty;
    },
    childrensValue() {
      return this.fieldValue.childrensQty;
    },
    infantsValue() {
      return this.fieldValue.infantsQty;
    },
    passengersQty() {
      return this.fieldValue.adultsQty + this.fieldValue.childrensQty + this.fieldValue.infantsQty;
    },
    value() {
      const tripClass = this.$t('common.tripClasses')[this.fieldValue.tripClass];

      return this.getLocaleMsg('value', {
        values: {
          tripClass: tripClass,
        },
      });
    },
  },

  watch: {
    autofocus(value) {
      if (value) {
        this.openDropdown();

        this.$emit('focused');
      }
    },
  },

  methods: {
    getLocaleMsg(key, data) {
      return this.$getLocaleMsg(`components.${this.$options.name}.${key}`, data);
    },
    openDropdown() {
      this.fieldDropdownIsVisible = true;

      this.$emit('open');
    },
    closeDropdown() {
      this.fieldDropdownIsVisible = false;

      this.$emit('close');
    },
    toggleDropdown() {
      this.fieldDropdownIsVisible = !this.fieldDropdownIsVisible;

      if (this.fieldDropdownIsVisible) {
        setTimeout(() => this.$emit('open'), 0);
      } else {
        this.$emit('close');
      }
    },
    changeTripClass(tripClass) {
      this.fieldValue.tripClass = tripClass;
    },
    getTripClassIcon(tripClassCode) {
      return this.svgIcons.get(tripClassCode === 'E' ? 'sofa' : 'armchair');
    },
    increaseCounter(type) {
      if (this.passengersQty === this.passengersQtyMax) return;

      if (type === 'infantsQty' && this.fieldValue.infantsQty === this.fieldValue.adultsQty) return;

      this.fieldValue[type] += 1;
    },
    decreaseCounter(type, min) {
      min = min !== undefined ? min : 0;

      if (min === this.fieldValue[type]) return;

      if (type === 'adultsQty' && this.fieldValue.adultsQty === this.fieldValue.infantsQty) return;

      this.fieldValue[type] -= 1;
    },
  },
};
</script>
