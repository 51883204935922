import ApiUtil from '@/utils/api';

export default {
  state: {
    insuranceLoading: false,
    paymentLoading: false,
  },

  getters: {
    insuranceLoading: (state) => state.insuranceLoading,
    paymentLoading: (state) => state.paymentLoading,
  },

  mutations: {
    SET_INSURANCE_LOADING(state, payload) {
      state.insuranceLoading = payload;
    },
    SET_PAYMENT_LOADING(state, payload) {
      state.paymentLoading = payload;
    },
  },

  actions: {
    async calculateAgreement({ commit }, payload) {
      commit('SET_INSURANCE_LOADING', true);
      try {
        const res = await ApiUtil.post('/insurance/inson/agreement/calculate', payload);

        if (res.status === 200 && res.data.data) {
          commit('SET_INSURANCE_LOADING', false);
          return res.data.data;
        }
      } catch (err) {
        console.error(err);
      } finally {
        commit('SET_INSURANCE_LOADING', false);
      }
    },

    async createAgreement({ commit }, { payload, onSuccess }) {
      commit('SET_INSURANCE_LOADING', true);
      try {
        const res = await ApiUtil.post('/insurance/inson/agreement/create', payload);
        if (res.status === 200 && typeof onSuccess === 'function') {
          onSuccess(res.data.data.id);
        }
      } catch (err) {
        console.error(err);
      } finally {
        commit('SET_INSURANCE_LOADING', false);
      }
    },

    async fetchAgreementDetails({ commit }, id) {
      commit('SET_INSURANCE_LOADING', true);
      try {
        const res = await ApiUtil.get(`/insurance/inson/agreement/${id}/details`);
        if (res.status === 200 && res.data) {
          return res.data.data;
        }
      } catch (err) {
        console.error(err);
      } finally {
        commit('SET_INSURANCE_LOADING', false);
      }
    },

    async paymentAgreement({ commit }, { params, onSuccess }) {
      commit('SET_PAYMENT_LOADING', true);
      try {
        const res = await ApiUtil.get(`/insurance/inson/agreement/${params.id}/payment/redirect-form`, { params });
        if (res.status === 200 && typeof onSuccess === 'function') {
          onSuccess(res.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        commit('SET_PAYMENT_LOADING', false);
      }
    },
  },
};
