import ApiUtil from '@/utils/api';

export default {
  checkAvailability: (ticketId) => {
    return new Promise((resolve, reject) => {
      ApiUtil.get(`/air/search/check/${ticketId}`)
        .then((response) => {
          if (response.status === 200 && response.data.is_available) {
            resolve({ name: 'available', data: { buyId: response.data.buy_id } });
          } else {
            reject({ name: 'not-available' });
          }
        })
        .catch(() => reject({ name: 'not-available' }));
    });
  },
  hasBaggage: (baggage) => !['NO', '0kg', '0PC'].includes(baggage),
};
