var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mb-8 bg-white md:mb-0" }, [
    _c(
      "div",
      {
        staticClass:
          "relative mx-auto flex min-h-[300px] w-full overflow-hidden bg-[#1d6bc0] text-white lg:min-h-[424px]",
      },
      [
        _c("div", { staticClass: "absolute right-0 top-0 z-[1] h-full" }, [
          _c("span", {
            staticClass:
              "absolute inset-0 z-[2] w-full bg-[linear-gradient(90deg,_rgba(29,107,192,0.9)_0%,_rgba(29,107,192,0.01)_100%)] lg:w-[195px]",
          }),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: _vm.insuranceBGImage,
                expression: "insuranceBGImage",
              },
            ],
            staticClass: "h-full w-full object-cover",
            attrs: { alt: "Insurance Background Image" },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "relative z-[2] flex w-full max-w-[550px] flex-col items-start justify-center p-8 lg:max-w-[705px] lg:p-[105px]",
          },
          [
            _c(
              "h1",
              { staticClass: "mb-3 mt-0 text-3xl font-bold lg:text-4xl" },
              [_vm._v("Insurance")]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "mb-6" }, [
              _vm._v(
                "\n        Travel insurance provides peace of mind and financial protection in case of unforeseen circumstances during\n        trip.\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass:
                  "w-full max-w-[350px] rounded-xl bg-[#efa543] px-10 py-5 text-center font-medium transition-colors hover:opacity-90",
                attrs: { to: { name: "insurance" } },
              },
              [_vm._v("\n        Получить\n      ")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }