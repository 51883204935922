var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "layout__header",
        `header_theme_${_vm.layoutTheme}`,
        _vm.layoutTheme !== 2 && "bg-[#1D6BC0]",
      ],
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.layoutTheme === 2
              ? "grid-container"
              : _vm.layoutTheme === 7 || _vm.layoutTheme === 6
              ? _vm.routeName === "Business"
                ? "section__container grid-container mx-auto px-[15px]"
                : "section__container grid-container mx-auto px-[15px] md:px-[50px] lg:px-[100px]"
              : "section__container grid-container mx-auto px-[15px]",
          ],
        },
        [
          _c(
            "div",
            {
              class: [
                "header__inner",
                _vm.layoutTheme === 2 &&
                  "border-b-[2px] border-dashed border-[#fff]",
              ],
            },
            [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "header__logo" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "home",
                            params: { lang: _vm.$i18n.locale },
                          },
                        },
                      },
                      [
                        _vm.layoutTheme === 2
                          ? _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: _vm.logoWhite,
                                  expression: "logoWhite",
                                },
                              ],
                              staticClass: "header__logo-img",
                              attrs: { width: "130", alt: "AllTrip" },
                            })
                          : _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: _vm.logoWhite,
                                  expression: "logoWhite",
                                },
                              ],
                              staticClass: "header__logo-img",
                              attrs: { width: "130", alt: "AllTrip" },
                            }),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    staticClass:
                      "ml-4 hidden items-center text-white lg:ml-[64px] lg:flex lg:space-x-[24px]",
                  },
                  [
                    _c("li", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "nav__link flex items-center space-x-[10px]",
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "tourism",
                                  params: { lang: _vm.$i18n.locale },
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.getLocaleMsg("tourism"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "nav__link",
                          attrs: {
                            href: "https://realavia.alltrip.uz",
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getLocaleMsg("businessAviation")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "nav__link flex items-center space-x-[10px]",
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "umrah",
                                  params: { lang: _vm.$i18n.locale },
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.getLocaleMsg("umrah"))),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false",
                                },
                              ],
                              attrs: {
                                to: {
                                  name: "Business",
                                  params: { lang: _vm.$i18n.locale },
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  class:
                                    _vm.routeName === "Business"
                                      ? "nav__business"
                                      : "nav__business",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.getLocaleMsg("special")) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "header__controls" }, [
                true
                  ? _c(
                      "div",
                      { staticClass: "header__controls-item" },
                      [_c("LanguageSelect")],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "header__controls-item" },
                  [_c("CurrencySelect")],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "header__controls-item" },
                  [_c("UserAccountMenu")],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "header__controls-item lg:hidden" },
                  [_c("Menubar")],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }