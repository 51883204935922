import Vue from 'vue';
import RangeDatePicker from '@/components/Form/Fields/RangeDatePicker.vue';

const RangeDatePickerPlugin = {
  install(Vue, options) {
    Vue.component(RangeDatePicker.name, {
      ...options,
      ...RangeDatePicker,
    });
  },
};

if (typeof window !== 'undefined' && window.Vue) {
  window.RangeDatePicker = RangeDatePickerPlugin;
}

Vue.use(RangeDatePickerPlugin);
