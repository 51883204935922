export default {
  getData() {
    return localStorage.getItem('at_cached_data') === null ? {} : JSON.parse(localStorage.getItem('at_cached_data'));
  },
  saveData(value) {
    return localStorage.setItem('at_cached_data', JSON.stringify(value));
  },
  getDataByKey(key) {
    const data = this.getData();

    return data[key] !== undefined ? data[key] : null;
  },
  setDataByKey(key, value) {
    const data = this.getData();

    data[key] = value;

    this.saveData(data);
  },
  deleteDataByKey(key) {
    const data = this.getData();

    if (data[key] !== undefined) {
      delete data[key];
    }

    this.saveData(data);
  },
};
