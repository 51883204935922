var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.userAuthPopup.status ? _c("UserAuthPopup") : _vm._e(),
      _vm._v(" "),
      _vm.userPasswordRecoveryPopup.status
        ? _c("UserPasswordRecoveryPopup")
        : _vm._e(),
      _vm._v(" "),
      _vm.userPasswordResetPopup.status
        ? _c("UserPasswordResetPopup")
        : _vm._e(),
      _vm._v(" "),
      _vm.ticketDetailsPopup.status ? _c("TicketDetailsPopup") : _vm._e(),
      _vm._v(" "),
      _vm.ticketRulesPopup.status ? _c("TicketRulesPopup") : _vm._e(),
      _vm._v(" "),
      _vm.ticketTariffsPopup.status ? _c("TicketTariffsPopup") : _vm._e(),
      _vm._v(" "),
      _vm.ticketNotAvailablePopup.status
        ? _c("TicketNotAvailablePopup")
        : _vm._e(),
      _vm._v(" "),
      _vm.orderNotAvailablePopup.status
        ? _c("OrderNotAvailablePopup")
        : _vm._e(),
      _vm._v(" "),
      _vm.orderStepsPopup.status ? _c("OrderStepsPopup") : _vm._e(),
      _vm._v(" "),
      _vm.orderPaymentPopup.status ? _c("OrderPaymentPopup") : _vm._e(),
      _vm._v(" "),
      _vm.orderPaymentConfirmPopup.status
        ? _c("OrderPaymentConfirmPopup")
        : _vm._e(),
      _vm._v(" "),
      _vm.orderVoidPopup.status ? _c("OrderVoidPopup") : _vm._e(),
      _vm._v(" "),
      _vm.orderOfertaPopup.status ? _c("OrderOfertaPopup") : _vm._e(),
      _vm._v(" "),
      _vm.orderPersonalDataPopup.status
        ? _c("OrderPersonalDataPopup")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }