var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutsideField,
          expression: "onClickOutsideField",
        },
      ],
      class: [
        "form-field",
        "form-field_type_airports",
        {
          "form-field_state_focus": _vm.fieldIsFocus,
          "form-field_state_invalid": _vm.fieldIsInvalid,
          "form-field_label-position_top": _vm.labelPositionIsTop,
          "form-field_dropdown-visibility_visible": _vm.airportsListIsVisible,
        },
      ],
    },
    [
      _c("div", { staticClass: "form-field__input-wrapper" }, [
        _c("input", {
          ref: "fieldInput",
          staticClass: "form-field__input placeholder:!text-[#A5ABB0]",
          staticStyle: { "padding-left": "20px !important" },
          attrs: {
            id: _vm.name,
            placeholder: _vm.label,
            title: _vm.fieldValue.name,
            type: "text",
            autocomplete: "off",
          },
          domProps: { value: _vm.fieldValue.name },
          on: {
            input: function ($event) {
              return _vm.onInputField($event.target.value)
            },
            focus: function ($event) {
              return _vm.onFocusField()
            },
            blur: function ($event) {
              return _vm.onBlurField()
            },
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.onKeydownEnter()
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp",
                  ])
                )
                  return null
                return _vm.onKeydownUp()
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                )
                  return null
                return _vm.onKeydownDown()
              },
            ],
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "form-field__code" }, [
          _vm._v(_vm._s(_vm.fieldValue.code)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-field__message form-field__message_type_error" },
          [_vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    ")]
        ),
      ]),
      _vm._v(" "),
      _vm.airports && _vm.airports.length
        ? _c("div", { staticClass: "form-field__dropdown mt-0.5 w-[304px]" }, [
            _c(
              "ul",
              { staticClass: "form-field__list overflow-hidden" },
              _vm._l(_vm.airports, function (airport, airportKey) {
                return _c(
                  "li",
                  {
                    key: airportKey,
                    class: [
                      "form-field__list-item",
                      "list-item",
                      "list-item_theme_form-airports-field",
                      `list-item_type_${airport.type}`,
                      {
                        "list-item_state_hover":
                          _vm.airportsListItemId === airportKey,
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.selectAirport(airportKey)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "list-item__inner" }, [
                      _c(
                        "span",
                        { staticClass: "list-item__title-container" },
                        [
                          airport.type === "avia"
                            ? _c("span", {
                                staticClass: "list-item__icon",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.svgIcons.get("airplane")
                                  ),
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "list-item__title",
                            domProps: {
                              innerHTML: _vm._s(
                                airport.type === "city"
                                  ? `${airport.name}, ${airport.country_name}`
                                  : airport.name
                              ),
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "list-item__code" }, [
                        _vm._v(_vm._s(airport.code)),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }