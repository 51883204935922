import ApiUtil from '@/utils/api';
import PopularDirectionsUtil from '@/utils/popular-directions';

export default {
  state: {
    currentDepartureCity: PopularDirectionsUtil.getCurrentDepartureCity(),
    departureCities: [],
    directions: [],
    directionsPerPage: 5,
  },

  getters: {
    popularDirections: (state) => state.directions,
    popularDirectionsDepartureCities: (state) =>
      state.departureCities.filter((city) => city.departure_code && city.departure_label),
    popularDirectionsCurrentDepartureCity: (state) => state.currentDepartureCity,
  },

  actions: {
    getPopularDirections: ({ commit, state }) => {
      const requestConfig = {
        params: {
          departure_code: state.currentDepartureCity,
          limit: state.directionsPerPage,
        },
      };

      ApiUtil.get('/directions', requestConfig)
        .then((response) => {
          if (response.status === 200 && response.data !== undefined && response.data.directions !== undefined) {
            commit('savePopularDirectionsDepartureCities', response.data.cities);
            commit('savePopularDirections', response.data.directions);
          }
        })
        .catch(() => {});
    },
    savePopuplarDirectionsCurrentDepartureCity: ({ commit }, city) => {
      PopularDirectionsUtil.saveCurrentDepartureCity(city);

      commit('savePopularDirectionsCurrentDepartureCity', city);
    },
  },

  mutations: {
    savePopularDirectionsDepartureCities: (state, cities) => (state.departureCities = cities),
    savePopularDirectionsCurrentDepartureCity: (state, city) => (state.currentDepartureCity = city),
    savePopularDirections: (state, directions) => (state.directions = directions),
  },
};
