<template>
  <div
    v-click-outside="closeDropdown"
    :class="[
      'form-field',
      'form-field_type_passengers',
      {
        'form-field_dropdown-visibility_visible': fieldDropdownIsVisible,
        'form-field_label-position_top': labelPositionIsTop,
      },
    ]"
  >
    <div class="form-field__inner flex h-full w-full cursor-pointer justify-center">
      <div
        class="flex h-full w-full items-center justify-between self-center px-5 max-lg:px-2 max-md:px-5"
        @click="toggleDropdown"
      >
        <input :value="value" class="form-field__input" type="text" readonly hidden />
        <div class="flex flex-col">
          <span class="text-sm font-[500] text-[#1B2226]">{{ value.count }}</span>
          <span class="text-sm font-medium text-[#A5ABB0]">{{ value.class }}</span>
        </div>
        <span class="ml-4 text-[#36454F]" v-html="svgIcons.get('select_arrow')" />
      </div>
      <div class="form-field__dropdown top-[61px] max-sm:!w-full">
        <div class="form-field__section form-field__section_type_passengers border border-[#C5D4DF]">
          <div class="mb-4 flex justify-between">
            <div class="flex flex-col">
              <span class="form-field__counter-value text-sm font-semibold text-[#1B2226]">{{
                getLocaleMsg('adultsCounter.type', { choice: adultsValue })
              }}</span>
              <p class="text-xs font-medium text-[#A5ABB0]">{{ getLocaleMsg('adultsCounter.label') }}</p>
            </div>
            <div class="flex">
              <button
                type="button"
                class="form-field__counter-button form-field__counter-button_type_decrease"
                :class="
                  fieldValue['adultsQty'] === 1 || fieldValue['adultsQty'] === fieldValue['infantsQty']
                    ? '!bg-[#A5ABB0]'
                    : ''
                "
                @click="decreaseCounter('adultsQty', 1)"
              >
                <span class="form-field__counter-button-icon form-field__counter-button-icon_type_decrease">-</span>
              </button>
              <div class="mx-2">
                {{ getLocaleMsg('adultsCounter.newValue', { choice: adultsValue, values: { qty: adultsValue } }) }}
              </div>
              <button
                type="button"
                class="form-field__counter-button form-field__counter-button_type_increase"
                :class="passengersQty === 9 ? '!bg-[#A5ABB0]' : ''"
                @click="increaseCounter('adultsQty')"
              >
                <span class="form-field__counter-button-icon form-field__counter-button-icon_type_increase">+</span>
              </button>
            </div>
          </div>
          <div class="mb-4 flex justify-between">
            <div class="flex flex-col">
              <p class="form-field__counter-value text-sm font-semibold text-[#1B2226]">
                {{ getLocaleMsg('childrensCounter.type', { choice: childrensValue }) }}
              </p>
              <p class="text-xs font-medium text-[#A5ABB0]">{{ getLocaleMsg('childrensCounter.label') }}</p>
            </div>
            <div class="flex">
              <button
                type="button"
                class="form-field__counter-button form-field__counter-button_type_decrease"
                :class="fieldValue['childrensQty'] === 0 ? '!bg-[#A5ABB0]' : ''"
                @click="decreaseCounter('childrensQty')"
              >
                <span class="form-field__counter-button-icon form-field__counter-button-icon_type_decrease">-</span>
              </button>
              <div class="mx-2">
                {{
                  getLocaleMsg('childrensCounter.newValue', { choice: childrensValue, values: { qty: childrensValue } })
                }}
              </div>
              <button
                type="button"
                class="form-field__counter-button form-field__counter-button_type_increase"
                :class="passengersQty === 9 ? '!bg-[#A5ABB0]' : ''"
                @click="increaseCounter('childrensQty')"
              >
                <span class="form-field__counter-button-icon form-field__counter-button-icon_type_increase">+</span>
              </button>
            </div>
          </div>
          <div class="mb-4 flex justify-between">
            <div class="flex flex-col">
              <p class="form-field__counter-value text-sm font-semibold text-[#1B2226]">
                {{ getLocaleMsg('infantsCounter.type', { choice: infantsValue, values: { qty: infantsValue } }) }}
              </p>
              <p class="text-xs font-medium text-[#A5ABB0]">{{ getLocaleMsg('infantsCounter.label') }}</p>
            </div>
            <div class="flex">
              <button
                type="button"
                class="form-field__counter-button form-field__counter-button_type_decrease"
                :class="fieldValue['infantsQty'] === 0 ? '!bg-[#A5ABB0]' : ''"
                @click="decreaseCounter('infantsQty')"
              >
                <span class="form-field__counter-button-icon form-field__counter-button-icon_type_decrease">-</span>
              </button>
              <div class="mx-2">
                {{ getLocaleMsg('infantsCounter.newValue', { choice: infantsValue, values: { qty: infantsValue } }) }}
              </div>
              <button
                type="button"
                class="form-field__counter-button form-field__counter-button_type_increase"
                :class="
                  passengersQty === 9 || fieldValue['adultsQty'] === fieldValue['infantsQty'] ? '!bg-[#A5ABB0]' : ''
                "
                @click="increaseCounter('infantsQty')"
              >
                <span class="form-field__counter-button-icon form-field__counter-button-icon_type_increase">+</span>
              </button>
            </div>
          </div>
          <div class="my-4 h-[1px] w-full bg-[#E2E9EF]"></div>
          <div class="form-field__section form-field__section_type_trip-class">
            <div class="form-field__select flex-col">
              <button
                v-for="tripClassCode of tripClasses"
                :key="tripClassCode"
                :class="[
                  'mb-4',
                  {
                    '': fieldValue.tripClass === tripClassCode,
                  },
                ]"
                type="button"
                @click="changeTripClass(tripClassCode)"
              >
                <span class="flex w-full">
                  <input
                    :id="`radio-${tripClassCode}`"
                    type="radio"
                    :checked="fieldValue?.tripClass === tripClassCode"
                    name="class"
                    class="h-6 w-6 cursor-pointer border-2 border-red-400"
                  />
                  <label
                    :for="`radio-${tripClassCode}`"
                    class="mx-4 grow cursor-pointer text-start text-sm font-[500] text-[#1B2226]"
                    >{{ $t('common.tripClasses')[tripClassCode] }}</label
                  >
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PassengersField',

  model: {
    prop: 'fieldValue',
    event: 'input',
  },

  emits: ['onFocus', 'outFocus', 'focused'],

  props: {
    label: {
      type: String,
      default: 'Пассажиры и класс',
    },
    fieldValue: {
      type: Object,
      default: () => ({}),
    },
    autofocus: {
      type: [Boolean, String],
      default: false,
    },
  },

  data() {
    return {
      fieldDropdownIsVisible: false,
      tripClasses: ['E', 'B'],
      passengersQtyMax: 9,
    };
  },

  computed: {
    labelPositionIsTop() {
      return this.fieldDropdownIsVisible || !this.fieldValueIsEmpty;
    },
    fieldValueIsEmpty() {
      let isEmpty = true;

      let value = this.fieldValue;

      for (let key in value) {
        let valueData = value[key];

        if (typeof valueData === 'string' && valueData !== '') {
          isEmpty = false;
        } else if (typeof valueData === 'number' && valueData !== 0) {
          isEmpty = false;
        }
      }

      return isEmpty;
    },
    adultsValue() {
      return this.fieldValue.adultsQty;
    },
    childrensValue() {
      return this.fieldValue.childrensQty;
    },
    infantsValue() {
      return this.fieldValue.infantsQty;
    },
    passengersQty() {
      return this.fieldValue.adultsQty + this.fieldValue.childrensQty + this.fieldValue.infantsQty;
    },
    value() {
      const first = this.getLocaleMsg('value', {
        values: {
          passengersQty: this.passengersQty,
        },
      });
      const tripClass = this.$t('common.tripClasses')[this.fieldValue.tripClass];
      const second = this.getClassLocaleMsg('value', {
        values: {
          tripClass: tripClass,
        },
      });

      return {
        count: first,
        class: second,
      };
    },
  },

  watch: {
    autofocus(value) {
      if (value) {
        this.openDropdown();

        this.$emit('focused');
      }
    },
    fieldDropdownIsVisible(newValue) {
      if (newValue) {
        this.$emit('onFocus', this.name);
      } else {
        this.$emit('outFocus', this.name);
      }
    },
  },

  methods: {
    getLocaleMsg(key, data) {
      return this.$getLocaleMsg(`components.${this.$options.name}.${key}`, data);
    },
    getClassLocaleMsg(key, data) {
      return this.$getLocaleMsg(`components.ClassesField.${key}`, data);
    },
    openDropdown() {
      this.fieldDropdownIsVisible = true;

      this.$emit('open');
    },
    closeDropdown() {
      this.fieldDropdownIsVisible = false;

      this.$emit('close');
    },
    toggleDropdown() {
      this.fieldDropdownIsVisible = !this.fieldDropdownIsVisible;

      if (this.fieldDropdownIsVisible) {
        setTimeout(() => this.$emit('open'), 0);
      } else {
        this.$emit('close');
      }
    },
    changeTripClass(tripClass) {
      this.fieldValue.tripClass = tripClass;
    },
    getTripClassIcon(tripClassCode) {
      return this.svgIcons.get(tripClassCode === 'E' ? 'sofa' : 'armchair');
    },
    increaseCounter(type) {
      if (this.passengersQty === this.passengersQtyMax) return;

      if (type === 'infantsQty' && this.fieldValue.infantsQty === this.fieldValue.adultsQty) return;

      this.fieldValue[type] += 1;
    },
    decreaseCounter(type, min) {
      min = min !== undefined ? min : 0;

      if (min === this.fieldValue[type]) return;

      if (type === 'adultsQty' && this.fieldValue.adultsQty === this.fieldValue.infantsQty) return;

      this.fieldValue[type] -= 1;
    },
  },
};
</script>
<style scoped>
input[type='radio'] {
  box-sizing: border-box;
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  outline: none;
  border: 3px solid #a5abb0;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type='radio']:checked:before {
  background: #0570fc;
}

input[type='radio']:checked {
  border-color: #0570fc;
}
</style>
