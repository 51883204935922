import Vue from 'vue';
import { extend, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';

setInteractionMode('eager');

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

extend('email', {
  ...email,
  message: 'Неверный формат email',
});

extend('required', {
  ...required,
  message: 'Поле обязательно для заполнения',
});
