import ApiUtil from '@/utils/api';

export default {
  state: {
    insuranceOrders: [],
    insuranceOrdersLoading: false,
    insuranceOrderLoading: false,
  },

  getters: {
    insuranceOrdersLoading: (state) => state.insuranceOrdersLoading,
    insuranceOrderLoading: (state) => state.insuranceOrderLoading,
  },

  mutations: {
    SET_INSURANCE_ORDERS_LOADING(state, payload) {
      state.insuranceOrdersLoading = payload;
    },
    SET_INSURANCE_ORDER_LOADING(state, payload) {
      state.insuranceOrderLoading = payload;
    },
  },

  actions: {
    async fetchInsuranceOrders({ commit }, { page, perPage }) {
      commit('SET_INSURANCE_ORDERS_LOADING', true);
      try {
        const res = await ApiUtil.get('/insurance/inson/agreement', {
          params: { page, per_page: perPage },
        });

        if (res.status === 200 && res.data) {
          return res.data.data;
        }
      } catch (err) {
        console.error(err);
      } finally {
        commit('SET_INSURANCE_ORDERS_LOADING', false);
      }
    },

    async fetchInsuranceOrder({ commit }, id) {
      commit('SET_INSURANCE_ORDER_LOADING', true);
      try {
        const res = await ApiUtil.get(`/insurance/inson/agreement/${id}/details`);
        if (res.status === 200 && res.data?.data) {
          return res.data.data;
        }
      } catch (err) {
        console.error(err);
      } finally {
        commit('SET_INSURANCE_ORDER_LOADING', false);
      }
    },
  },
};
