import CacheUtil from '@/utils/cache';

export default {
  getData() {
    const data = CacheUtil.getDataByKey('popularDirections');

    return data ? data : {};
  },
  setData(data) {
    CacheUtil.setDataByKey('popularDirections', data);
  },
  saveCurrentDepartureCity(city) {
    const data = this.getData();

    data.currentCity = city;

    this.setData(data);
  },
  getCurrentDepartureCity() {
    const data = this.getData();

    return data.сurrentDepartureCity !== undefined ? data.сurrentDepartureCity : 'TAS';
  },
};
