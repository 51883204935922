import PaymentsRequests from '@/utils/api/requests/payments';
import ApiUtil from '@/utils/api';

const PAYMENTS_METHODS = {
  paymo: 'PaymentMethodPaymo',
  click: 'PaymentMethodClick',
  octo: 'PaymentMethodOcto',
};

export default {
  state: {
    paymentsProviders: ['paymo', 'click', 'octo'],
  },

  getters: {
    paymentsMethodsComponents(state) {
      return Object.entries(PAYMENTS_METHODS)
        .filter((method) => state.paymentsProviders.includes(method[0]))
        .map((method) => method[1]);
    },
  },

  actions: {
    async getPaymentsProviders({ commit }) {
      const providers = await PaymentsRequests.getProviders();

      commit('setPaymentsProviders', providers);
    },
    redirectForm(_, data) {
      return new Promise((resolve, reject) => {
        ApiUtil.get(
          '/air/order/payment/' +
            data.params.order_id +
            '/redirect-form?card_type=' +
            data.params.card_type +
            '&callback=' +
            data.params.callback +
            '&description=' +
            data.params.description,
        )
          .then((response) => {
            const order = response.data.data;

            localStorage.setItem('action', order.action);
            localStorage.setItem('method', order.method);
            localStorage.setItem('amount', order.form.amount);
            localStorage.setItem('callback', order.form.callback);
            localStorage.setItem('currency', order.form.currency);
            localStorage.setItem('description', order.form.description);
            localStorage.setItem('merchantId', order.form.merchantId);
            localStorage.setItem('order_id', order.form.order_id);

            resolve({
              name: 'payment',
              data: {
                status: response.status,
              },
            });
          })
          .catch((error) => reject({ data: error }));
      });
    },
  },

  mutations: {
    setPaymentsProviders: (state, providers) => (state.paymentsProviders = providers),
  },
};
