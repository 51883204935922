import Vue from 'vue';

import ApiUtil from '@/utils/api';
import TicketUtil from '@/utils/tickets/ticket';
import OrderUtil from '@/utils/order';

export default {
  state: {
    id: null,
    remoteId: null,
    source: null,
    status: null,
    error: {},
    ticketData: null,
    passengersData: null,
    currentStep: null,
    completedSteps: [],
    stepsPopup: { status: null },
    bookingData: {
      buy_id: null,
      email: null,
      passengers: [],
      phone: {},
    },
    bookingErrors: {},
    paymentId: null,
    paymentPopup: { status: null },
    paymentConfirmCallback: null,
    paymentConfirmPopup: { status: null },
    notAvailablePopup: { status: null },
    voidPopup: { status: null },
    ofertaPopup: { status: null },
    personalDataPopup: { status: null },
    conversionId: OrderUtil.getConversionId(),
    orderPopup: { status: null },
    redirectFormData: {},
  },

  getters: {
    orderId: (state) => state.id,
    orderRemoteId: (state) => state.remoteId,
    orderSource: (state) => state.source,
    orderStatus: (state) => state.status,
    orderError: (state) => state.error,
    orderPassengersData: (state) => state.passengersData,
    orderTicketData: (state) => state.ticketData,
    orderCurrentStep: (state) => state.currentStep,
    orderCompletedSteps: (state) => state.completedSteps,
    orderStepsPopup: (state) => state.stepsPopup,
    orderBookingData: (state) => state.bookingData,
    orderBookingErrors: (state) => state.bookingErrors,
    orderPaymentId: (state) => state.paymentId,
    orderPaymentPopup: (state) => state.paymentPopup,
    orderPaymentConfirmPopup: (state) => state.paymentConfirmPopup,
    orderPaymentConfirmCallback: (state) => state.paymentConfirmCallback,
    orderNotAvailablePopup: (state) => state.notAvailablePopup,
    orderVoidPopup: (state) => state.voidPopup,
    orderOfertaPopup: (state) => state.ofertaPopup,
    orderPersonalDataPopup: (state) => state.personalDataPopup,
    orderPopup: (state) => state.orderPopup,
    redirectFormData: (state) => state.redirectFormData,
    Data: (state) => state.Data,
  },

  actions: {
    setOrderId: ({ commit }, id) => commit('setOrderId', id),
    setOrderRemoteId: ({ commit }, id) => commit('setOrderRemoteId', id),
    setOrderSource: ({ commit }, source) => commit('setOrderSource', source),
    setOrderStatus: ({ commit }, status) => commit('setOrderStatus', status),
    setOrderError: ({ commit }, error) => commit('setOrderError', error),

    setOrderPassengersData: ({ commit }, data) => commit('setOrderPassengersData', data),
    clearOrderPassengersData: ({ commit }) => commit('setOrderPassengersData', null),

    setOrderTicketData: ({ commit }, data) => commit('setOrderTicketData', data),
    clearOrderTicketData: ({ commit }) => commit('setOrderTicketData', null),

    setOrderCurrentStep: ({ commit }, step) => commit('setOrderCurrentStep', step),
    addOrderCompletedStep: ({ commit }, step) => commit('addOrderCompletedStep', step),
    clearOrderCompletedSteps: ({ commit }) => commit('clearOrderCompletedSteps'),
    setOrderStepsPopupData: ({ commit }, data) => commit('setOrderStepsPopupData', data),

    setOrderPopup: ({ commit }, data) => commit('setOrderPopup', data),
    setData: ({ commit }, data) => commit('setData', data),

    setOrderBookingData: ({ commit }, data) => commit('setOrderBookingData', data),
    setRedirectFormData: ({ commit }, data) => commit('setRedirectFormData', data),
    orderTicket: ({ state }) => {
      const bookingData = OrderUtil.formatBookingData(state.bookingData);
      return new Promise((resolve, reject) => {
        ApiUtil.post('/air/order', bookingData)
          .then((response) => {
            const order = response.data;
            resolve({
              name: 'booking',
              data: {
                orderId: order.id,
                orderRemoteId: order.remote_order_id,
              },
            });
          })
          .catch((error) => reject({ data: error.message }));
      });
    },
    clearOrderBookingData: ({ commit }) => {
      commit('setOrderBookingData', {
        buy_id: null,
        marker: null,
        phone: null,
        email: null,
        passengers: [],
      });
    },
    addOrderBookingError: ({ commit, state }, newError) => {
      const errorsNames = Object.keys(state.bookingErrors);

      if (errorsNames.length) {
        const errors = state.bookingErrors;

        errorsNames.forEach((errorName) => {
          if (errors[errorName] === newError[errorName]) {
            delete newError[errorName];
          }
        });
      }

      if (Object.keys(newError).length) {
        commit('addOrderBookingError', newError);
      }
    },
    removeOrderBookingError: ({ commit }, error) => commit('removeOrderBookingError', error),
    clearOrderBookingErrors: ({ commit }) => commit('clearOrderBookingErrors'),
    initOrderBooking: ({ commit, state, dispatch }) => {
      return new Promise((resolve, reject) => {
        if (!state.ticketData || !state.ticketData.buy_id) {
          return reject('booking-error');
        }

        TicketUtil.checkAvailability(state.ticketData.buy_id)
          .then(({ data }) => {
            commit('setOrderBookingData', { buy_id: data.buyId });

            const bookingData = OrderUtil.formatBookingData(state.bookingData);

            if (state.conversionId) {
              bookingData.conversion_id = state.conversionId;
            }

            return new Promise((resolve, reject) => {
              ApiUtil.post('/air/order', bookingData)
                .then((response) => {
                  const { order, auth } = response.data;

                  if (auth) {
                    dispatch('saveUserTokens', auth, { root: true });
                    dispatch('getUserData', null, { root: true });
                    dispatch('getNotebookItems', null, { root: true });
                  }

                  resolve({
                    name: 'booking',
                    data: {
                      orderId: order.id,
                      orderRemoteId: order.remote_order_id,
                    },
                  });
                })
                .catch(() => reject({ name: 'booking-error' }));
            });
          })
          .then((success) => resolve(success))
          .catch((error) => reject(error));
      });
    },
    getOrderData: ({ commit }, payload) => {
      const { orderId, data, marker, price, detailsId } = payload;

      return new Promise((resolve, reject) => {
        ApiUtil.get(`/offers/${detailsId}/details/${data}`)
          .then((response) => {
            const { offer, passengers } = response.data;

            offer.buy_id = orderId;
            offer.price = price || offer.price;

            commit('setOrderBookingData', { buy_id: offer.buy_id, marker });
            commit('setOrderPassengersData', passengers);
            commit('setOrderTicketData', offer);

            resolve('success');
          })
          .catch(() => reject('error'));
      });
    },
    clearOrderData: ({ commit, dispatch }) => {
      commit('setOrderSource', null);

      dispatch('clearOrderBookingData');
      dispatch('clearOrderBookingErrors');
      dispatch('clearOrderPassengersData');
      dispatch('clearOrderTicketData');
    },

    createOrderPayment: (_, data) => {
      return new Promise((resolve, reject) => {
        ApiUtil.post('/payments', data)
          .then((response) =>
            resolve({
              name: 'payment',
              data: {
                orderId: data.order_id,
                paymentId: response.data.payment_id,
              },
            }),
          )
          .catch((error) =>
            reject({
              name: 'payment-error',
              data: error.response && error.response.data ? error.response.data : null,
            }),
          );
      });
    },
    confirmOrderPayment: ({ commit }, { orderId, paymentId }) => {
      commit('setOrderPaymentId', paymentId);

      return new Promise((resolve, reject) => {
        commit('setOrderPaymentConfirmCallback', (response) => {
          commit('setOrderPaymentId', null);

          if (response.name === 'success') {
            resolve({ name: 'payment-confirm', data: { orderId, paymentId } });
          } else {
            reject({ name: 'payment-confirm-error', data: response.data });
          }
        });
      });
    },
    setOrderPaymentConfirmPopupData: ({ commit }, data) => commit('setOrderPaymentConfirmPopupData', data),
    initOrderPayment: (_, { orderId, paymentId }) => {
      return new Promise((resolve, reject) => {
        ApiUtil.post(`/orders/${orderId}/payment`)
          .then(() => resolve({ name: 'ticketing', data: { orderId } }))
          .catch(() => reject({ name: 'ticketing-error', data: { orderId, paymentId } }));
      });
    },
    reverseOrderPayment: (_, { orderId, paymentId }) => {
      return new Promise((resolve, reject) => {
        ApiUtil.post(`/payments/${paymentId}/reverse`, { order_id: orderId })
          .then(() => resolve('success'))
          .catch(() => reject('error'));
      });
    },
    setOrderPaymentPopupData: ({ commit }, data) => commit('setOrderPaymentPopupData', data),

    setOrderNotAvailablePopupData: ({ commit }, data) => commit('setOrderNotAvailablePopupData', data),
    setOrderVoidPopupData: ({ commit }, data) => commit('setOrderVoidPopupData', data),
    setOrderOfertaPopupData: ({ commit }, data) => commit('setOrderOfertaPopupData', data),
    setOrderPersonalDataPopupData: ({ commit }, data) => commit('setOrderPersonalDataPopupData', data),

    getOrderConversionId: ({ commit }, sourceUid) => {
      return new Promise((resolve, reject) => {
        ApiUtil.removeHeaderAuthToken();
        ApiUtil.removeHeaderClientCurrency();

        ApiUtil.customRequest({
          baseURL:
            process.env.MODE === 'production'
              ? `https://${process.env.AGENTS_API_HOST}/api/v1`
              : `http://${process.env.AGENTS_API_HOST}/api/v1`,
          url: '/conversions',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            source_uid: sourceUid,
          },
        })
          .then((response) => {
            if (response?.data?.id === undefined) {
              return;
            }

            const conversionId = response.data.id;

            commit('setOrderConversionId', conversionId);

            OrderUtil.saveConversionId(conversionId);

            resolve();
          })
          .catch((error) => reject(error));

        ApiUtil.setHeaderAuthToken();
        ApiUtil.setHeaderClientCurrency();
      });
    },
  },

  mutations: {
    setOrderId: (state, id) => (state.id = id),
    setOrderRemoteId: (state, id) => (state.remoteId = id),
    setOrderSource: (state, source) => (state.source = source),
    setOrderStatus: (state, status) => (state.status = status),
    setOrderError: (state, error) => (state.error = error),

    setOrderPassengersData: (state, data) => (state.passengersData = data),
    setOrderTicketData: (state, data) => (state.ticketData = data),

    setOrderCurrentStep: (state, step) => (state.currentStep = step),
    addOrderCompletedStep: (state, step) => state.completedSteps.push(step),
    clearOrderCompletedSteps: (state) => (state.completedSteps = []),
    setOrderStepsPopupData: (state, data) => (state.stepsPopup = Object.assign({}, state.stepsPopup, data)),

    setOrderBookingData: (state, data) => (state.bookingData = Object.assign({}, state.bookingData, data)),
    addOrderBookingError: (state, error) => (state.bookingErrors = Object.assign({}, state.bookingErrors, error)),
    removeOrderBookingError: (state, error) => Vue.delete(state.bookingErrors, error),
    clearOrderBookingErrors: (state) => (state.bookingErrors = {}),

    setOrderPaymentId: (state, id) => (state.paymentId = id),
    setOrderPaymentConfirmCallback: (state, callback) => (state.paymentConfirmCallback = callback),
    setOrderPaymentConfirmPopupData: (state, data) =>
      (state.paymentConfirmPopup = Object.assign({}, state.paymentConfirmPopup, data)),
    setOrderPaymentPopupData: (state, data) => (state.paymentPopup = Object.assign({}, state.paymentPopup, data)),

    setOrderNotAvailablePopupData: (state, data) =>
      (state.notAvailablePopup = Object.assign({}, state.notAvailablePopup, data)),
    setOrderVoidPopupData: (state, data) => (state.voidPopup = Object.assign({}, state.voidPopup, data)),
    setOrderOfertaPopupData: (state, data) => (state.ofertaPopup = Object.assign({}, state.ofertaPopup, data)),
    setOrderPersonalDataPopupData: (state, data) =>
      (state.personalDataPopup = Object.assign({}, state.personalDataPopup, data)),

    setOrderConversionId: (state, id) => (state.conversionId = id),

    setOrderPopup: (state, data) => (state.orderPopup = Object.assign({}, state.orderPopup, data)),
    setData: (state, data) => (state.Data = Object.assign({}, state.Data, data)),
    setRedirectFormData: (state, data) => (state.redirectFormData = Object.assign({}, state.redirectFormData, data)),
  },
};
