<template>
  <div id="hotels" class="hotels relative">
    <div class="hotels__title text-[#36454F]">
      <div class="hotels__title-text">{{ getLocaleMsg('hotel') }}</div>
      <div class="hotels__country-content hotels__country-content-active hotels__country-content-mobile">
        <p class="hotels__country-content-text hotels__country-content-text-active">
          {{ $t('pages.Home.hotels.city') }}
        </p>
        <img src="../../assets/icons/keyboard_arrow_right.svg" alt="" />
      </div>
      <!-- <div class="inline-block text-sm md:text-base">
                  {{ getLocaleMsg('bestFlightSubtitle') }}
              </div> -->
    </div>
    <div
      class="relative flex flex-col items-start space-y-4 md:flex-row md:items-center md:justify-between md:space-y-0"
    >
      <div class="hotels__country">
        <div class="hotels__country-content hotels__country-content-active">
          <p class="hotels__country-content-text hotels__country-content-text-active">
            {{ $t('components.Flights.places.country2') }}
          </p>
        </div>
        <div class="hotels__country-content">
          <p class="hotels__country-content-text">{{ $t('components.Flights.places.country3') }}</p>
        </div>
        <div class="hotels__country-content">
          <p class="hotels__country-content-text">{{ getLocaleMsg('city1') }}</p>
        </div>
        <div class="hotels__country-content">
          <p class="hotels__country-content-text">{{ getLocaleMsg('city2') }}</p>
        </div>
        <div class="hotels__country-content">
          <p class="hotels__country-content-text">{{ getLocaleMsg('city3') }}</p>
        </div>
      </div>
      <div class="relative z-[0] flex w-[108px] space-x-4">
        <button slot="button-prev" class="swiper-button-prev"></button>
        <button slot="button-next" class="swiper-button-next"></button>
      </div>
    </div>
    <!-- <div class="mt-[20px] md:mt-[40px]" v-if="flights.length > 0">
          <swiper
              :options="swiperOptions"
              class="flights-section"
          >

              <swiper-slide class="relative" v-for="(flight, index) in flights" :key="flight.id"
              >
                  <Card :directions="flight?.directions" :title="flight?.title" :price="flight?.price"
                        :index="index" :image="flight?.image"
                  />
              </swiper-slide>
              <div slot="pagination" class="swiper-pagination"></div>
          </swiper>
      </div>  Old Version-->
    <div class="mt-[20px] md:mt-[40px]">
      <swiper :options="swiperOptions" class="flights-section">
        <swiper-slide class="relative">
          <div class="relative cursor-pointer max-sm:flex max-sm:justify-center" @click="ComingSoon">
            <div class="relative max-sm:max-w-[400px]">
              <img class="w-full" v-lazy="newYorkImage" alt="New York Hotel Image" />
              <div class="hotels__card">
                <p class="hotels__card-heading">
                  {{ $t('components.Flights.places.country2') }}
                </p>
                <p class="hotels__card-title">Radisson Blu Hotel Istanbul Ottomare</p>
                <div class="hotels__card-contents">
                  <p
                    @click="ComingSoon"
                    class="rounded-lg bg-white py-1"
                    style="padding-left: 16px; padding-right: 16px"
                  >
                    {{ $t('components.Flights.detail') }}
                  </p>
                  <div class="flex">
                    <img v-lazy="starImage" alt="Star icon" />
                    <img v-lazy="starImage" alt="Star icon" />
                    <img v-lazy="starImage" alt="Star icon" />
                    <img v-lazy="starImage" alt="Star icon" />
                    <img v-lazy="starImage" alt="Star icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="relative cursor-pointer max-sm:flex max-sm:justify-center" @click="ComingSoon">
            <div class="relative max-sm:max-w-[400px]">
              <img class="w-full" v-lazy="washingtonImage" alt="Washington Hotel Image" />
              <div class="hotels__card">
                <p class="hotels__card-heading">
                  {{ $t('components.Flights.places.country3') }}
                </p>
                <p class="hotels__card-title">The Oberoi Udaivilas, Udaipur</p>
                <div class="hotels__card-contents">
                  <p
                    @click="ComingSoon"
                    class="rounded-lg bg-white py-1"
                    style="padding-left: 16px; padding-right: 16px"
                  >
                    {{ $t('components.Flights.detail') }}
                  </p>
                  <div class="flex">
                    <img v-lazy="starImage" alt="Star icon" />
                    <img v-lazy="starImage" alt="Star icon" />
                    <img v-lazy="starImage" alt="Star icon" />
                    <img v-lazy="starImage" alt="Star icon" />
                    <img v-lazy="starImage" alt="Star icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="relative cursor-pointer max-sm:flex max-sm:justify-center" @click="ComingSoon">
            <div class="relative max-sm:max-w-[400px]">
              <img class="w-full" v-lazy="sanfiranciscoImage" alt="Sanfirancisco Hotel Image" />
              <div class="hotels__card">
                <p class="hotels__card-heading">
                  {{ getLocaleMsg('city1') }}
                </p>
                <p class="hotels__card-title">
                  {{ getLocaleMsg('square') }}
                </p>
                <div class="hotels__card-contents">
                  <p
                    @click="ComingSoon"
                    class="rounded-lg bg-white py-1"
                    style="padding-left: 16px; padding-right: 16px"
                  >
                    {{ $t('components.Flights.detail') }}
                  </p>
                  <div class="flex">
                    <img v-lazy="starImage" alt="Star icon" />
                    <img v-lazy="starImage" alt="Star icon" />
                    <img v-lazy="starImage" alt="Star icon" />
                    <img v-lazy="starImage" alt="Star icon" />
                    <img v-lazy="starImage" alt="Star icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="relative cursor-pointer max-sm:flex max-sm:justify-center" @click="ComingSoon">
            <div class="relative max-sm:max-w-[400px]">
              <img v-lazy="newYorkImage" alt="New York Hotel Image" />
              <div class="hotels__card">
                <p class="text-xl font-medium text-white">
                  {{ getLocaleMsg('city2') }}
                </p>
                <p class="hotels__card-title mb-2 text-[32px] font-bold text-white">
                  {{ getLocaleMsg('square') }}
                </p>
                <div class="hotels__card-contents">
                  <p
                    @click="ComingSoon"
                    class="rounded-lg bg-white py-1"
                    style="padding-left: 16px; padding-right: 16px"
                  >
                    {{ $t('components.Flights.detail') }}
                  </p>
                  <div class="flex">
                    <img v-lazy="starImage" alt="Star icon" />
                    <img v-lazy="starImage" alt="Star icon" />
                    <img v-lazy="starImage" alt="Star icon" />
                    <img v-lazy="starImage" alt="Star icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <!--                <div slot="pagination" class="swiper-pagination"></div>-->
      </swiper>
      <div class="flex justify-center">
        <p
          @click="ComingSoon"
          class="mt-2 cursor-pointer rounded-lg bg-[#E8EEF2] px-24 py-4 text-sm font-semibold text-[#1B2226]"
        >
          {{ $t('pages.Home.button.info') }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import ToastUtil from '@/utils/toast';

import newYorkImage from '@/assets/images/hotel-newYork.png';
import starImage from '@/assets/svg/star.svg';
import washingtonImage from '@/assets/images/hotel-washington.png';
import sanfiranciscoImage from '@/assets/images/hotel-sanFransisko.png';
export default {
  name: 'HotelsSection',
  data: () => ({
    swiperOptions: {
      slidesPerView: 3,
      spaceBetween: 32,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.hotels .swiper-button-next',
        prevEl: '.hotels .swiper-button-prev',
      },
      preloadImages: true,
      // Enable lazy loading
      lazy: true,
      breakpoints: {
        1279: {
          spaceBetween: 32,
        },
        639: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        230: {
          spaceBetween: 15,
          slidesPerView: 1,
        },
      },
    },
    newYorkImage: newYorkImage,
    starImage: starImage,
    washingtonImage: washingtonImage,
    sanfiranciscoImage: sanfiranciscoImage,
  }),
  methods: {
    getLocaleMsg(key) {
      return this.$getLocaleMsg(`pages.Home.${key}`);
    },
    ComingSoon() {
      // alert('Coming Soon')
      ToastUtil.show({
        message: this.$t('common.Toast.soon'),
        type: 'success',
      });
    },
  },
};
</script>
<style></style>
