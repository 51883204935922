import ApiUtil from '@/utils/api';

export default {
  state: {
    vacanciesLoading: false,
    vacancies: [],
    vacanciesCount: 0,
    vacancy: null,
  },

  getters: {
    vacanciesLoading: (state) => state.vacanciesLoading,
    vacancies: (state) => state.vacancies,
    vacanciesCount: (state) => state.vacanciesCount,
    vacancy: (state) => state.vacancy,
  },

  mutations: {
    SET_VACANCIES_LOADING: (state, payload) => (state.vacanciesLoading = payload),
    SET_VACANCIES: (state, payload) => (state.vacancies = payload),
    SET_VACANCIES_COUNT: (state, payload) => (state.vacanciesCount = payload),
    SET_VACANCY: (state, payload) => (state.vacancy = payload),
  },

  actions: {
    getVacancies: ({ commit }, { page, per_page }) => {
      commit('SET_VACANCIES_LOADING', true);
      ApiUtil.get('/publications/vacancies', { params: { page, per_page } })
        .then((res) => {
          if (res.data && Array.isArray(res.data.list) && res.data.count) {
            commit('SET_VACANCIES', res.data.list);
            commit('SET_VACANCIES_COUNT', res.data.count);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => commit('SET_VACANCIES_LOADING', false));
    },

    getVacancy: ({ commit }, id) => {
      commit('SET_VACANCIES_LOADING', true);
      ApiUtil.get(`/publications/${id}/details`)
        .then((res) => {
          if (res.status === 200 && res.data) {
            commit('SET_VACANCY', res.data);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => commit('SET_VACANCIES_LOADING', false));
    },
  },
};
