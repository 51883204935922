import Vue from 'vue';
import i18n from '@/i18n';

export const i18nUtil = {
  init() {
    Vue.prototype.$getLocaleMsg = this.getLocaleMsg;
  },
  getLocaleMsg(path, data) {
    data = data === undefined ? {} : data;

    if (data.choice !== undefined) {
      return i18n.tc(path, data.choice, data.values);
    } else {
      return i18n.t(path, data.values);
    }
  },
};

i18nUtil.init();

export class $getLocaleMsg {}
