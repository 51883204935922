var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return false
    ? _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.hideDropdown,
              expression: "hideDropdown",
            },
          ],
          class: [
            "currency-select",
            {
              "currency-select_dropdown-visibility_visible":
                _vm.dropdownIsVisible,
            },
          ],
        },
        [
          _c(
            "button",
            {
              class: [
                "currency-select__button inline-flex items-center",
                `currency-select__button_theme_2`,
              ],
              attrs: { type: "button" },
              on: { click: _vm.toggleDropdown },
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.currentCurrency))]),
              _vm._v(" "),
              _c("span", {
                staticClass: "ml-[11px]",
                domProps: {
                  innerHTML: _vm._s(_vm.svgIcons.get("dropdown_arrow")),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "currency-select__dropdown" }, [
            _vm.currenciesList && _vm.currenciesList.length
              ? _c(
                  "ul",
                  { class: ["currency-select__list"] },
                  _vm._l(_vm.currenciesList, function (currency, currencyKey) {
                    return _c(
                      "li",
                      {
                        key: currencyKey,
                        class: [
                          "list-item",
                          "list-item_theme_currency-select",
                          {
                            "list-item_state_active":
                              currency.code === _vm.currentCurrency,
                          },
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.onSelect(currency.code)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "list-item__code" }, [
                          _vm._v(_vm._s(currency.code)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "list-item__title" }, [
                          _vm._v(_vm._s(currency.title)),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }