<template>
  <div class="mb-8 bg-white md:mb-0">
    <div class="relative mx-auto flex min-h-[300px] w-full overflow-hidden bg-[#1d6bc0] text-white lg:min-h-[424px]">
      <div class="absolute right-0 top-0 z-[1] h-full">
        <span
          class="absolute inset-0 z-[2] w-full bg-[linear-gradient(90deg,_rgba(29,107,192,0.9)_0%,_rgba(29,107,192,0.01)_100%)] lg:w-[195px]"
        />
        <img v-lazy="insuranceBGImage" alt="Insurance Background Image" class="h-full w-full object-cover" />
      </div>

      <div
        class="relative z-[2] flex w-full max-w-[550px] flex-col items-start justify-center p-8 lg:max-w-[705px] lg:p-[105px]"
      >
        <h1 class="mb-3 mt-0 text-3xl font-bold lg:text-4xl">Insurance</h1>
        <p class="mb-6">
          Travel insurance provides peace of mind and financial protection in case of unforeseen circumstances during
          trip.
        </p>
        <router-link
          :to="{ name: 'insurance' }"
          class="w-full max-w-[350px] rounded-xl bg-[#efa543] px-10 py-5 text-center font-medium transition-colors hover:opacity-90"
        >
          Получить
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import insuranceBGImage from '@/assets/images/home_insurance_bg.png';
export default {
  name: "InsuranceSection",
  data: () => ({
    insuranceBGImage: insuranceBGImage
  })
}
</script>

