import { formatDate } from '@/utils';

function splitString(string, size) {
  const regExp = new RegExp('.{1,' + size + '}', 'g');

  return string.match(regExp);
}

function getDirectionDate(date, dateFormat) {
  let splitedDate = splitString(date, 2);

  const year = splitedDate[0].toString();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear().toString();
  const currentYearOffset = currentYear.length - year.length;

  splitedDate[0] = currentYear.substr(0, currentYearOffset) + year;

  return formatDate(splitedDate.join('-'), dateFormat);
}

export const createTicketsSearchId = (params) => {
  if (params === null || params.directions.length === 0) return null;

  let id = '';

  params.directions.forEach((direction) => {
    id += direction.departure_code;
    id += direction.arrival_code;
    id += formatDate(new Date(direction.date), 'yyMMdd');
  });

  id += '-';
  id += params.count_adult;
  id += params.count_child;
  id += params.count_infant;
  id += params.class_type;
  id += '-';
  id += params.direct ? 'D' : 'ND';

  return id;
};

export const parseTicketsSearchId = (id) => {
  let params = {};

  const splittedId = id.split('-');

  if (splittedId.length < 3) {
    return null;
  }

  let directions = splittedId[0];

  let start = 0;

  params.directions = [];

  while (start < directions.length) {
    params.directions.push({
      departure_code: directions.substr(start, 3),
      arrival_code: directions.substr((start += 3), 3),
      date: getDirectionDate(directions.substr((start += 3), 6), 'yyyy-MM-dd'),
    });

    start += 6;
  }

  const passengers = splittedId[1];

  params.count_adult = passengers.substr(0, 1);
  params.count_child = passengers.substr(1, 1);
  params.count_infant = passengers.substr(2, 1);
  params.class_type = passengers.substr(3, 1);
  params.direct = splittedId[2] === 'D' ? true : false;

  return params;
};
