import ApiUtil from '@/utils/api';

export default {
  getAirportByCode(code) {
    return new Promise((resolve) => {
      ApiUtil.get('/airports/search', { params: { query: code } })
        .then((response) => {
          if (response.status === 200 && response.data !== undefined && response.data.length) {
            const airport = response.data.find((airport) => airport.code === code);

            resolve(airport);
          } else {
            resolve(null);
          }
        })
        .catch(() => resolve(null));
    });
  },
};
