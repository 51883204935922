var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hotels relative", attrs: { id: "hotels" } },
    [
      _c("div", { staticClass: "hotels__title text-[#36454F]" }, [
        _c("div", { staticClass: "hotels__title-text" }, [
          _vm._v(_vm._s(_vm.getLocaleMsg("hotel"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "hotels__country-content hotels__country-content-active hotels__country-content-mobile",
          },
          [
            _c(
              "p",
              {
                staticClass:
                  "hotels__country-content-text hotels__country-content-text-active",
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("pages.Home.hotels.city")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c("img", {
              attrs: {
                src: require("../../assets/icons/keyboard_arrow_right.svg"),
                alt: "",
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "relative flex flex-col items-start space-y-4 md:flex-row md:items-center md:justify-between md:space-y-0",
        },
        [
          _c("div", { staticClass: "hotels__country" }, [
            _c(
              "div",
              {
                staticClass:
                  "hotels__country-content hotels__country-content-active",
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "hotels__country-content-text hotels__country-content-text-active",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("components.Flights.places.country2")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "hotels__country-content" }, [
              _c("p", { staticClass: "hotels__country-content-text" }, [
                _vm._v(_vm._s(_vm.$t("components.Flights.places.country3"))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "hotels__country-content" }, [
              _c("p", { staticClass: "hotels__country-content-text" }, [
                _vm._v(_vm._s(_vm.getLocaleMsg("city1"))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "hotels__country-content" }, [
              _c("p", { staticClass: "hotels__country-content-text" }, [
                _vm._v(_vm._s(_vm.getLocaleMsg("city2"))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "hotels__country-content" }, [
              _c("p", { staticClass: "hotels__country-content-text" }, [
                _vm._v(_vm._s(_vm.getLocaleMsg("city3"))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "relative z-[0] flex w-[108px] space-x-4" },
            [
              _c("button", {
                staticClass: "swiper-button-prev",
                attrs: { slot: "button-prev" },
                slot: "button-prev",
              }),
              _vm._v(" "),
              _c("button", {
                staticClass: "swiper-button-next",
                attrs: { slot: "button-next" },
                slot: "button-next",
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-[20px] md:mt-[40px]" },
        [
          _c(
            "swiper",
            {
              staticClass: "flights-section",
              attrs: { options: _vm.swiperOptions },
            },
            [
              _c("swiper-slide", { staticClass: "relative" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "relative cursor-pointer max-sm:flex max-sm:justify-center",
                    on: { click: _vm.ComingSoon },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "relative max-sm:max-w-[400px]" },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: _vm.newYorkImage,
                              expression: "newYorkImage",
                            },
                          ],
                          staticClass: "w-full",
                          attrs: { alt: "New York Hotel Image" },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "hotels__card" }, [
                          _c("p", { staticClass: "hotels__card-heading" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t("components.Flights.places.country2")
                                ) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "hotels__card-title" }, [
                            _vm._v("Radisson Blu Hotel Istanbul Ottomare"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "hotels__card-contents" }, [
                            _c(
                              "p",
                              {
                                staticClass: "rounded-lg bg-white py-1",
                                staticStyle: {
                                  "padding-left": "16px",
                                  "padding-right": "16px",
                                },
                                on: { click: _vm.ComingSoon },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t("components.Flights.detail")
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex" }, [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("swiper-slide", [
                _c(
                  "div",
                  {
                    staticClass:
                      "relative cursor-pointer max-sm:flex max-sm:justify-center",
                    on: { click: _vm.ComingSoon },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "relative max-sm:max-w-[400px]" },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: _vm.washingtonImage,
                              expression: "washingtonImage",
                            },
                          ],
                          staticClass: "w-full",
                          attrs: { alt: "Washington Hotel Image" },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "hotels__card" }, [
                          _c("p", { staticClass: "hotels__card-heading" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t("components.Flights.places.country3")
                                ) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "hotels__card-title" }, [
                            _vm._v("The Oberoi Udaivilas, Udaipur"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "hotels__card-contents" }, [
                            _c(
                              "p",
                              {
                                staticClass: "rounded-lg bg-white py-1",
                                staticStyle: {
                                  "padding-left": "16px",
                                  "padding-right": "16px",
                                },
                                on: { click: _vm.ComingSoon },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t("components.Flights.detail")
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex" }, [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("swiper-slide", [
                _c(
                  "div",
                  {
                    staticClass:
                      "relative cursor-pointer max-sm:flex max-sm:justify-center",
                    on: { click: _vm.ComingSoon },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "relative max-sm:max-w-[400px]" },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: _vm.sanfiranciscoImage,
                              expression: "sanfiranciscoImage",
                            },
                          ],
                          staticClass: "w-full",
                          attrs: { alt: "Sanfirancisco Hotel Image" },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "hotels__card" }, [
                          _c("p", { staticClass: "hotels__card-heading" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.getLocaleMsg("city1")) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "hotels__card-title" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.getLocaleMsg("square")) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "hotels__card-contents" }, [
                            _c(
                              "p",
                              {
                                staticClass: "rounded-lg bg-white py-1",
                                staticStyle: {
                                  "padding-left": "16px",
                                  "padding-right": "16px",
                                },
                                on: { click: _vm.ComingSoon },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t("components.Flights.detail")
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex" }, [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("swiper-slide", [
                _c(
                  "div",
                  {
                    staticClass:
                      "relative cursor-pointer max-sm:flex max-sm:justify-center",
                    on: { click: _vm.ComingSoon },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "relative max-sm:max-w-[400px]" },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: _vm.newYorkImage,
                              expression: "newYorkImage",
                            },
                          ],
                          attrs: { alt: "New York Hotel Image" },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "hotels__card" }, [
                          _c(
                            "p",
                            { staticClass: "text-xl font-medium text-white" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.getLocaleMsg("city2")) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "hotels__card-title mb-2 text-[32px] font-bold text-white",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.getLocaleMsg("square")) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "hotels__card-contents" }, [
                            _c(
                              "p",
                              {
                                staticClass: "rounded-lg bg-white py-1",
                                staticStyle: {
                                  "padding-left": "16px",
                                  "padding-right": "16px",
                                },
                                on: { click: _vm.ComingSoon },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t("components.Flights.detail")
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex" }, [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.starImage,
                                    expression: "starImage",
                                  },
                                ],
                                attrs: { alt: "Star icon" },
                              }),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex justify-center" }, [
            _c(
              "p",
              {
                staticClass:
                  "mt-2 cursor-pointer rounded-lg bg-[#E8EEF2] px-24 py-4 text-sm font-semibold text-[#1B2226]",
                on: { click: _vm.ComingSoon },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("pages.Home.button.info")) +
                    "\n      "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }