<template>
  <div
    v-click-outside="hideDropdown"
    :class="[
      'language-select flex self-center -mr-8',
      {
        'language-select_dropdown-visibility_visible': dropdownIsVisible,
      },
    ]"
  >
    <a
      :class="['language-select__button inline-flex items-center self-center mr-4', `language-select__button_theme_${2}`]"
      href="tel:+998781404444"
    >
      <svg width="18" height="18" viewBox="0 0 14 14" fill="white" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.8338 9.87038V11.6204C12.8345 11.7828 12.8012 11.9436 12.7361 12.0925C12.671 12.2414 12.5756 12.375 12.4559 12.4848C12.3362 12.5946 12.1948 12.6782 12.0409 12.7303C11.887 12.7823 11.724 12.8017 11.5622 12.787C9.76714 12.592 8.04291 11.9786 6.52799 10.9962C5.11856 10.1006 3.9236 8.90565 3.02799 7.49622C2.04214 5.97442 1.42863 4.2418 1.23716 2.43872C1.22258 2.2774 1.24175 2.11483 1.29345 1.96133C1.34515 1.80783 1.42824 1.66678 1.53744 1.54716C1.64663 1.42754 1.77954 1.33196 1.9277 1.26652C2.07586 1.20108 2.23602 1.1672 2.39799 1.16705H4.14799C4.43109 1.16426 4.70554 1.26451 4.92018 1.44911C5.13483 1.63371 5.27504 1.89006 5.31466 2.17038C5.38852 2.73042 5.5255 3.28031 5.72299 3.80955C5.80147 4.01834 5.81846 4.24525 5.77194 4.4634C5.72541 4.68154 5.61733 4.88178 5.46049 5.04038L4.71966 5.78122C5.55006 7.24162 6.75926 8.45081 8.21966 9.28122L8.96049 8.54038C9.11909 8.38355 9.31933 8.27546 9.53748 8.22894C9.75562 8.18241 9.98253 8.1994 10.1913 8.27788C10.7206 8.47537 11.2705 8.61235 11.8305 8.68622C12.1139 8.72619 12.3726 8.86892 12.5576 9.08725C12.7426 9.30559 12.8409 9.5843 12.8338 9.87038Z" stroke="#484D5B" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span class="ml-1 underline">+998 78 140 44 44</span>
    </a>
    <button
      :class="['language-select__button inline-flex items-center self-center', `language-select__button_theme_${2}`]"
      type="button"
      @click="toggleDropdown"
    >
      <!-- <span class="text-base font-[500] max-sm:text-sm">{{ currentLanguageData.code }}</span> -->
      <img :src="currentLanguageData.imgUrl" alt="Photo" />
      <span class="ml-1" v-html="svgIcons.get('dropdown_arrow')" />
    </button>
    <div class="language-select__dropdown z-[100]">
      <ul v-if="languages && languages.length" class="language-select__list">
        <li
          v-for="(language, languageKey) in languages"
          :key="languageKey"
          :class="['language-select__list-item', 'list-item', 'list-item_theme_language-select']"
          @click="onSelect(language.shortCode)"
        >
          <div class="flex w-full justify-between">
            <div class="flex flex-1 pr-1">
              <img :src="language.imgUrl" alt="countries flags" class="list-item__img h-6 w-5 flex-shrink-0" />
              <span class="list-item__text flex-1" v-html="language.title" />
            </div>
            <img
              v-if="language.code === currentLanguageData.code"
              src="~@/assets/svg/checkbox-bg.svg"
              alt="select"
              class="h-6 w-6 flex-shrink-0"
            />
            <img v-else src="~@/assets/svg/checkbox-empty.svg" alt="select" class="h-6 w-6" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LanguageSelect',

  data: () => ({
    dropdownIsVisible: false,
  }),

  computed: {
    ...mapGetters(['currentLanguage', 'languages', 'layoutTheme']),
    currentLanguageData() {
      return this.languages.find((language) => this.currentLanguage === language.shortCode);
    },
  },

  methods: {
    ...mapActions(['setCurrentLanguage']),
    toggleDropdown() {
      this.dropdownIsVisible = !this.dropdownIsVisible;
    },
    hideDropdown() {
      this.dropdownIsVisible = false;
    },
    onSelect(value) {
      this.$router.push({ params: { lang: value } });

      this.setCurrentLanguage(value);

      this.hideDropdown();
    },
  },
};
</script>
