import ApiUtil from '@/utils/api';

export default {
  state: {
    airports: [],
  },

  getters: {
    airports: (state) => state.airports,
  },

  actions: {
    getAirports: ({ commit }, query) => {
      const requestConfig = {
        params: {
          query,
          limit: 7,
        },
      };

      // ApiUtil.get('/airports/search', requestConfig)
      ApiUtil.get('/dictionaries/search-airports', requestConfig).then((airports) => {
        if (airports.status === 200 && airports.data !== undefined) {
          commit('setAirports', airports.data);
        } else {
          commit('setAirports', []);
        }
      });
    },
    resetAirports: ({ commit }) => commit('resetAirports'),
  },
  mutations: {
    setAirports: (state, airports) => (state.airports = airports),
    resetAirports: (state) => (state.airports = []),
  },
};
