<template>
  <div
    :class="[
      'tickets-search-widget',
      layoutTheme === 2 && ' rounded-b-lg rounded-r-lg py-4 md:px-4 lg:px-[24px] lg:py-[48px] xl:px-[31px]',
      `tickets-search-widget_theme_${theme}`,
    ]"
  >
    <!-- <h3 v-if="theme === 'classic'" class="tickets-search-widget__title" v-html="getLocaleMsg('title')"/> -->
    <TicketsSearchForm :theme="theme" @cancel="cancelWidget" />
  </div>
</template>

<script>
import TicketsSearchForm from './Form';
import { mapGetters } from 'vuex';

export default {
  name: 'TicketsSearchWidget',

  components: {
    TicketsSearchForm,
  },

  props: {
    theme: {
      type: String,
      default: 'classic',
    },
  },

  computed: {
    ...mapGetters(['layoutTheme']),
  },

  methods: {
    cancelWidget() {
      this.$emit('cancel');
    },
    getLocaleMsg(key) {
      return this.$getLocaleMsg(`components.${this.$options.name}.${key}`);
    },
  },
};
</script>
