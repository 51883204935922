<template>
  <div
    v-click-outside="hideMenu"
    :class="[
      'header-menu',
      'header-menu_theme_user-account',
      {
        'header-menu_dropdown-visibility_visible': menuIsVisible,
      },
    ]"
  >
    <button
      v-if="userIsAuthenticated"
      class="header-menu__toggle-menu button button_theme_toggle-menu-popup"
      type="button"
      @click.prevent="toggleMenu"
    >
      <span class="button__text mr-2 text-white max-sm:!text-sm">{{ getLocaleMsg('toggleMenu') }}</span>
      <span class="h-12 w-12 rounded-full bg-[#1B2226] p-3 text-white max-sm:h-8 max-sm:w-8 max-sm:p-2">
        {{ firstLetter }}
      </span>
    </button>

    <button
      v-if="!userIsAuthenticated"
      :class="[
        'enter-btn hidden items-center rounded-2xl px-[26px] py-3 text-white sm:inline-flex',
        layoutTheme === '2' ? 'enter-btn bg-[#E8EEF2]' : 'bg-[#fff]',
      ]"
      type="button"
      @click.prevent="setUserAuthPopupData({ status: 'open', hideOnClickOutside: true })"
    >
      <span :class="['button__text', layoutTheme === '2' ? 'font-semibold text-[#1B2226]' : 'text-[#36454f]']">
        {{ getLocaleMsg('showAuthPopup') }}
      </span>
    </button>

    <div class="header-menu__dropdown">
      <ul v-if="userIsAuthenticated" class="header-menu__list overflow-hidden shadow-xl">
        <li class="header-menu__item">
          <router-link
            :to="{ name: 'cabinetProfile', params: { lang: $i18n.locale } }"
            class="header-menu__link"
            active-class="header-menu__link_state_active"
            @click.native="hideMenu"
          >
            {{ getLocaleMsg('profile') }}
          </router-link>
          <div class="h-px w-full bg-[#e2e9ef]" />
        </li>

        <li class="header-menu__item">
          <router-link
            :to="{ name: 'cabinetOrders', params: { lang: $i18n.locale } }"
            class="header-menu__link"
            active-class="header-menu__link_state_active"
            @click.native="hideMenu"
          >
            {{ getLocaleMsg('orders') }}
          </router-link>
          <div class="h-px w-full bg-[#e2e9ef]" />
        </li>

        <li class="header-menu__item">
          <router-link
            :to="{ name: 'cabinetInsuranceOrders', params: { lang: $i18n.locale } }"
            class="header-menu__link"
            active-class="header-menu__link_state_active"
            @click.native="hideMenu"
          >
            {{ $t('components.CabinetNavigation.cabinetInsuranceOrders') }}
          </router-link>
          <div class="h-px w-full bg-[#e2e9ef]" />
        </li>

        <li class="header-menu__item">
          <router-link
            :to="{ name: 'cabinetNotebook', params: { lang: $i18n.locale } }"
            class="header-menu__link"
            active-class="header-menu__link_state_active"
            @click.native="hideMenu"
          >
            {{ getLocaleMsg('notebook') }}
          </router-link>
          <div class="h-px w-full bg-[#e2e9ef]" />
        </li>

        <li class="header-menu__item">
          <button class="header-menu__link w-full text-left" @click="logout">{{ getLocaleMsg('logout') }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UserAccountMenu',

  data: () => ({
    menuIsVisible: false,
  }),

  computed: {
    ...mapGetters(['userIsAuthenticated', 'userEmail', 'layoutTheme']),
    firstLetter() {
      if (this.userEmail) {
        const first = this.userEmail.slice(0, 1).toUpperCase();
        const second = this.userEmail.slice(1, 2).toLowerCase();
        return first + second;
      }
      return 'Aa';
    },
  },

  methods: {
    ...mapActions(['setUserAuthPopupData', 'logoutUser']),
    getLocaleMsg(key) {
      return this.$getLocaleMsg(`components.${this.$options.name}.${key}`);
    },
    toggleMenu() {
      this.menuIsVisible = !this.menuIsVisible;
    },
    hideMenu() {
      this.menuIsVisible = false;
    },
    logout() {
      this.toggleMenu();
      this.logoutUser();
      if (this.$route.matched.some((record) => record.meta.requiresAuth)) {
        this.$router.push({ name: 'home' });
      }
    },
  },
};
</script>
