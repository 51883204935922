import { isBefore, differenceInDays } from 'date-fns';
import { formatDate } from '@/utils';
import CacheUtil from '@/utils/cache';

export default {
  _changeFormDatesValue(formData) {
    const minDate = new Date();

    minDate.setDate(minDate.getDate() - 1);

    formData.fields.forEach((field, fieldIndex) => {
      const dates = field.dates;

      if (dates.one !== '') {
        let dateOne = new Date(dates.one);

        if (isBefore(dateOne, minDate)) {
          dateOne = new Date();

          if (dates.two !== '') {
            let datesDifference = differenceInDays(new Date(dates.two), new Date(dates.one));

            let dateTwo = new Date();
            dateTwo.setDate(dateTwo.getDate() + datesDifference);

            formData.fields[fieldIndex].dates.one = formatDate(dateOne);
            formData.fields[fieldIndex].dates.two = formatDate(dateTwo);
          } else {
            formData.fields[fieldIndex].dates.one = formatDate(dateOne);
          }
        }
      }
    });

    return formData;
  },
  saveFormData(data) {
    CacheUtil.setDataByKey('searchWidgetFormData', data);
  },
  getFormData() {
    let cachedData = CacheUtil.getDataByKey('searchWidgetFormData');

    if (cachedData) {
      cachedData = this._changeFormDatesValue(cachedData);
    }

    return cachedData ? cachedData : null;
  },
  saveSearchParams(params) {
    CacheUtil.setDataByKey('searchWidgetSearchParams', params);
  },
  getSearchParams() {
    const cachedParams = CacheUtil.getDataByKey('searchWidgetSearchParams');

    return cachedParams ? cachedParams : null;
  },
};
