import ApiUtil from '@/utils/api';

export default {
  state: {
    cards: [],
  },

  getters: {
    paymentCards: (state) => state.cards,
  },

  actions: {
    getPaymentCards: ({ commit, rootState }) => {
      return new Promise((resolve) => {
        if (!rootState.user.userAccessToken) {
          return resolve('not-auth');
        }

        ApiUtil.get('/users/cards')
          .then((response) => {
            commit('setPaymentCards', response.data);

            resolve('success');
          })
          .catch(() => resolve('error'));
      });
    },
    deletePaymentCards: ({ commit }) => commit('setPaymentCards', []),
    updatePaymentCard: ({ commit }, data) => {
      return new Promise((resolve) => {
        ApiUtil.put(`/users/cards/${data.id}`, data)
          .then(() => {
            commit('updatePaymentCard', data);

            resolve('success');
          })
          .catch(() => resolve('error'));
      });
    },
    deletePaymentCard: ({ commit }, id) => {
      return new Promise((resolve) => {
        ApiUtil.delete(`/users/cards/${id}`)
          .then(() => {
            commit('deletePaymentCard', id);

            resolve('success');
          })
          .catch(() => resolve('error'));
      });
    },
  },

  mutations: {
    setPaymentCards: (state, cards) => (state.cards = cards),
    deletePaymentCard: (state, id) => (state.cards = state.cards.filter((card) => card.id !== id)),
    updatePaymentCard: (state, updatedCard) =>
      (state.cards = state.cards.map((card) =>
        card.id !== updatedCard.id ? card : Object.assign({}, card, updatedCard),
      )),
  },
};
