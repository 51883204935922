var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideMenu,
          expression: "hideMenu",
        },
      ],
      class: [
        "header-menu",
        "header-menu_theme_user-account",
        {
          "header-menu_dropdown-visibility_visible": _vm.menuIsVisible,
        },
      ],
    },
    [
      _vm.userIsAuthenticated
        ? _c(
            "button",
            {
              staticClass:
                "header-menu__toggle-menu button button_theme_toggle-menu-popup",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.toggleMenu.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "button__text mr-2 text-white max-sm:!text-sm" },
                [_vm._v(_vm._s(_vm.getLocaleMsg("toggleMenu")))]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "h-12 w-12 rounded-full bg-[#1B2226] p-3 text-white max-sm:h-8 max-sm:w-8 max-sm:p-2",
                },
                [_vm._v("\n      " + _vm._s(_vm.firstLetter) + "\n    ")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.userIsAuthenticated
        ? _c(
            "button",
            {
              class: [
                "enter-btn hidden items-center rounded-2xl px-[26px] py-3 text-white sm:inline-flex",
                _vm.layoutTheme === "2"
                  ? "enter-btn bg-[#E8EEF2]"
                  : "bg-[#fff]",
              ],
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.setUserAuthPopupData({
                    status: "open",
                    hideOnClickOutside: true,
                  })
                },
              },
            },
            [
              _c(
                "span",
                {
                  class: [
                    "button__text",
                    _vm.layoutTheme === "2"
                      ? "font-semibold text-[#1B2226]"
                      : "text-[#36454f]",
                  ],
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.getLocaleMsg("showAuthPopup")) +
                      "\n    "
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "header-menu__dropdown" }, [
        _vm.userIsAuthenticated
          ? _c(
              "ul",
              { staticClass: "header-menu__list overflow-hidden shadow-xl" },
              [
                _c(
                  "li",
                  { staticClass: "header-menu__item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "header-menu__link",
                        attrs: {
                          to: {
                            name: "cabinetProfile",
                            params: { lang: _vm.$i18n.locale },
                          },
                          "active-class": "header-menu__link_state_active",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.hideMenu.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getLocaleMsg("profile")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h-px w-full bg-[#e2e9ef]" }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "header-menu__item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "header-menu__link",
                        attrs: {
                          to: {
                            name: "cabinetOrders",
                            params: { lang: _vm.$i18n.locale },
                          },
                          "active-class": "header-menu__link_state_active",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.hideMenu.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getLocaleMsg("orders")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h-px w-full bg-[#e2e9ef]" }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "header-menu__item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "header-menu__link",
                        attrs: {
                          to: {
                            name: "cabinetInsuranceOrders",
                            params: { lang: _vm.$i18n.locale },
                          },
                          "active-class": "header-menu__link_state_active",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.hideMenu.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "components.CabinetNavigation.cabinetInsuranceOrders"
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h-px w-full bg-[#e2e9ef]" }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "header-menu__item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "header-menu__link",
                        attrs: {
                          to: {
                            name: "cabinetNotebook",
                            params: { lang: _vm.$i18n.locale },
                          },
                          "active-class": "header-menu__link_state_active",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.hideMenu.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getLocaleMsg("notebook")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h-px w-full bg-[#e2e9ef]" }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "header-menu__item" }, [
                  _c(
                    "button",
                    {
                      staticClass: "header-menu__link w-full text-left",
                      on: { click: _vm.logout },
                    },
                    [_vm._v(_vm._s(_vm.getLocaleMsg("logout")))]
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }