import Vue from 'vue';
import MQ from 'vue-match-media/src';

Vue.use(MQ);

const mediaQueries = {
  maxW479: '(max-width: 479px)',
  maxW639: '(max-width: 639px)',
  minW640: '(min-width: 640px)',
  maxW767: '(max-width: 767px)',
  maxW1023: '(max-width: 1023px)',
  minW1024: '(min-width: 1024px)',
  maxW1279: '(max-width: 1279px)',
};

export default mediaQueries;
